// eslint-disable-next-line

import React from 'react'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import CashVoucherContentDetailTab from './CashVoucherTabs/CashVoucherContentDetailTab'
import JournalEntriesDetailsTab from './CashVoucherTabs/JournalEntriesDetailTab'
import AttachmentDetailTab from './../CheckVoucher/CheckVoucherTabs/AttachmentDetailTab'
import {Redirect} from 'react-router'
import {Link} from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import styles from './CashVoucher.module.css'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'

import Grid from '@material-ui/core/Grid'
import {Button, Tooltip} from '@material-ui/core'
import Bills from '../CheckVoucher/Bills'
import PropTypes from 'prop-types'
import converter from "number-to-words";
import AttachmentForm from "./Attachments/AttachmentForm";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import AuditLog from '../../shared/AuditLog/AuditLog'
import ReactToPrint from "react-to-print";
import CompanyHeader from '../../shared/CompanyHeader/CompanyHeader'

function TabPanel(props) {
  const {children, value, index, ...other} = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class CashVoucherShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Cash Voucher',
      value: 0,
      cashVouchers: [],
      cashVoucherContents: [],
      journalEntries: [],
      attachments: [],
      openPopup: false,
      openAttachmentPopup: false,
      cancelCashVoucherPopup: false,
      activeCashVoucherPopup: false,
      activeJournalPopup: false,
      cancelJournalPopup: false,
      openDeletePopup: false,
      redirectToIndex: false,
      cashVoucher: {},
      initialCashVoucher: {id: '', name: ''},
      attachment: {title: '', file: '', description: ''},
      title: '',
      load: false,
      tableHead: ['Name', 'Actions'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      isOpen: false,
      forPrint: false,
      message: '',
      type: '',
      content: '',
      openReverseJournalPopUp: false,
      signatory: {},
      openPrintPopup: false,
      changeSignPopup: false,
      openPrintPDFPopup: false,
      
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.cancelCashVoucher = this.cancelCashVoucher.bind(this)
    this.cancelCashVoucherSubmit = this.cancelCashVoucherSubmit.bind(this)
    this.activeCashVoucherSubmit = this.activeCashVoucherSubmit.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.openAttachment = this.openAttachment.bind(this)
    this.handleAttacthmentSubmit = this.handleAttacthmentSubmit.bind(this)
    this.handleAttactmentInputChange = this.handleAttactmentInputChange.bind(this)
    this.activeJournal = this.activeJournal.bind(this)
    this.cancelJournal = this.cancelJournal.bind(this)
    this.activeJournalSubmit = this.activeJournalSubmit.bind(this)
    this.cancelJournalSubmit = this.cancelJournalSubmit.bind(this)
    this.handleReverseJournal = this.handleReverseJournal.bind(this)
    this.reverseJournal = this.reverseJournal.bind(this)
    this.closeReverseJournal = this.closeReverseJournal.bind(this)
    this.changePrintLayout = this.changePrintLayout.bind(this)
    this.openPrint = this.openPrint.bind(this)
    this.changeSign = this.changeSign.bind(this)
    this.submitChangeSign = this.submitChangeSign.bind(this)
    this.handleInputChangePDF = this.handleInputChangePDF.bind(this)
    this.openPrintPDF = this.openPrintPDF.bind(this)
  }

  componentDidMount() {
    var skip = false
    if (this.props.location.state !== undefined) {
      if(this.props.location.state.item){
        skip = true
      }
    }
    this.loadCashVoucher('/v1/cash_vouchers/' + this.props.match.params.id,skip)
  }

  loadCashVoucher(url,skip) {
    const id = this.props.match.params.id
    if(skip){
      this.setState({cashVoucher: this.props.location.state.item, load:true}) 
    }else{
      axios({
        method: 'get',
        url: url,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({cashVoucher: resp.data,load: true})
      })
    }
    
    axios({
      method: 'get',
      url: '/v1/cash_vouchers/' + id + '/attachments',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({attachments: resp.data})
    })
    axios({
      method: 'get',
      url: '/v1/cash_vouchers/' + id + '/journal_entries',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({journalEntries: resp.data})
    })
    axios({
      method: 'get',
      url: '/v1/cash_vouchers/' + id + '/contents',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({cashVoucherContents: resp.data})
    })
  }

  handleSubmit() {
    const item = this.state.cashVoucher
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/cash_vouchers/'
    } else {
      method = 'put'
      url = '/v1/cash_vouchers/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      const newData = resp.data
      this.setState({
        cashVouchers: this.state.cashVouchers.filter(cashVoucher => cashVoucher.id !== item.id)
      })
      this.setState({cashVouchers: [...this.state.cashVouchers, newData]})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({openPopup: false})
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({error_messages: 'Name ' + error.response.data.errors.name[0]})
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Occurred",
          type: 'error'
        })
        this.setState({openPopup: false})
      }
    })
  }

  handleDeleteItem() {
    const DeleteItemId = this.state.cashVoucher.id
    axios({
      method: 'delete',
      url: '/v1/cash_vouchers/' + DeleteItemId,
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        cashVouchers: this.state.cashVouchers.filter(item => item.id !== DeleteItemId)
      })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
      this.setState({openDeletePopup: false})
      this.setState({redirectToIndex: true})
    })
  }

  handleClose() {
    this.setState({openPopup: false})
    this.setState({openDeletePopup: false})
    this.setState({error_messages: ''})
    this.setState({cancelCashVoucherPopup: false})
    this.setState({activeCashVoucherPopup: false})
    this.setState({openAttachmentPopup: false})
    this.setState({activeJournalPopup: false})
    this.setState({cancelJournalPopup: false})
    this.setState({ openPrintPopup: false, changeSignPopup: false, signatory: {...this.state.signatory, creator: ''},openPrintPDFPopup: false })

  }

  handleCreateorUpdateItem(item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({openPopup: true})
    this.setState({cashVoucher: item})
    this.setState({title: textTitle})
  }

  handleInputChange(e) {
    this.setState({
      cashVoucher: {
        ...this.state.cashVoucher,
        [e.target.name]: e.target.value
      }
    })
  }

  handleChange(event, newValue) {
    this.setState({value: newValue})
  }

  handleOpenDeletePopup(model) {
    this.setState({model: model})
    this.setState({cashVoucher: this.state.cashVoucher})
    this.setState({openDeletePopup: true})
  }


  cancelCashVoucher() {
    this.setState({cancelCashVoucherPopup: true})
    this.setState({activeCashVoucherPopup: true})
  }

  openAttachment() {
    this.setState({openAttachmentPopup: true})
  }

  activeJournal() {
    this.setState({activeJournalPopup: true})
  }

  cancelJournal() {
    this.setState({cancelJournalPopup: true})
  }

  cancelCashVoucherSubmit() {
    const id = this.props.match.params.id
    axios({
      method: 'put',
      url: '/v1/cash_vouchers/' + id + '/cancel',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({cashVoucher: resp.data})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({cancelCashVoucherPopup: false})
    })

  }

  activeCashVoucherSubmit() {
    const id = this.props.match.params.id
    axios({
      method: 'put',
      url: '/v1/cash_vouchers/' + id + '/activate',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then((resp) => {
      this.setState({cashVoucher: resp.data})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({activeCashVoucherPopup: false})
    })
  }


  activeJournalSubmit() {
    const id = this.state.cashVoucher.journal_id
    axios({
      method: 'put',
      url: '/v1/journals/' + id + '/post',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      axios({
        method: 'get',
        url: '/v1/cash_vouchers/' + this.props.match.params.id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({cashVoucher: resp.data, isOpen: true, message: 'Submitted Successfully', type: 'success',activeJournalPopup: false})
      })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    })
  }

  cancelJournalSubmit() {
    const id = this.state.cashVoucher.journal_id
    axios({
      method: 'put',
      url: '/v1/journals/' + id + '/unpost',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      axios({
        method: 'get',
        url: '/v1/cash_vouchers/' + this.props.match.params.id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({cashVoucher: resp.data, isOpen: true, message: 'Submitted Successfully', type: 'success',cancelJournalPopup: false})
      })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    })
  }

  handleDeleteClose() {
    this.setState({openDeletePopup: false})
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      isOpen: false
    })
  }

  onFileChange(e) {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      attachment: {
        ...this.state.attachment,
        file: e.target.files[0],
        title: e.target.files[0].name
      }
    })
  }

  handleAttacthmentSubmit() {
    const id = this.props.match.params.id
    const item = this.state.attachment
    const file = item.file
    const title = item.file.name
    const description = item.description
    const formdata = new FormData()
    formdata.append('file', file)
    formdata.append('title', title)
    formdata.append('description', description)

    axios({
      method: 'post',
      url: '/v1/cash_vouchers/' + id + '/attachments',
      data: (formdata),
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      const newData = resp.data
      this.setState({
        attachments: this.state.attachments.filter(attach => attach.id !== item.id)
      })
      this.setState({attachments: [...this.state.attachments, newData]})
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({openAttachmentPopup: false})
    })
  }

  handleAttactmentInputChange(e) {
    this.setState({
      attachment: {
        ...this.state.attachment,
        [e.target.name]: e.target.value
      }
    })
  }

  handleReverseJournal(){
    this.setState({openReverseJournalPopUp: true})
  }

  reverseJournal() {
    const id = this.state.cashVoucher.journal_id
    axios({
      method: 'post',
      url: '/v1/journals/' + id + '/reverse_entries',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(() => {
      setTimeout(() => {
        axios({
          method: 'get',
          url: '/v1/cash_vouchers/' + this.props.match.params.id + '/journal_entries',
          headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
        }).then(resp => {
          this.setState({journalEntries: resp.data, isOpen: true, message: 'Submitted Successfully', type: 'success',openReverseJournalPopUp: false})
        })
      }, 1000)
    })
  }

  closeReverseJournal(){
    this.setState({openReverseJournalPopUp: false})
  }

  openPrint() {
    this.setState({ openPrintPopup: true })
  }
  
  openPrintPDF() {
    this.setState({ openPrintPDFPopup: true })
  }
  
  changeSign() {
    this.setState({ changeSignPopup: true })
  }

  changePrintLayout() {
    return new Promise((resolve) => {
      this.setState({ forPrint: !this.state.forPrint}, () => resolve());
    });
  } 

  handleInputChangePDF(e) {
    this.setState({ signatory: {  ...this.state.signatory, [e.target.name]: e.target.value  } })
  }

  submitChangeSign() {
    this.setState({cashVoucher: {...this.state.cashVoucher, creator: this.state.signatory.creator}, changeSignPopup: false, openPrintPopup: false})
  }

  render() {
    
    const {redirectToIndex} = this.state
    if (redirectToIndex)
      return (<Redirect to={'/cash_vouchers'}/>)

    const printPdf = <ReactToPrint
      trigger={() => {
        return <Button color="primary" variant='outlined'>No</Button>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />

    const ChangeSignPrintPdf = <ReactToPrint
      trigger={() => {
        return <Button color="primary" variant='outlined'>Proceed</Button>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />
    return (
      <>
        {
          this.state.load ? (
            <div  ref={el => (this.componentRef = el)} className={this.state.forPrint ? styles.tableBodyHeight : null}>
              <CompanyHeader forPrint={this.state.forPrint}/>
              <div className={styles.showTitleHeader}>
                <div><h2>Cash Voucher Information</h2></div>
                <div>
                  <div className={styles.groupButtonShow}>
                    <Tooltip title="Back to index"><IconButton>
                      <a href='/cash_vouchers'>
                        <ArrowBackIosOutlinedIcon/>
                      </a>
                    </IconButton></Tooltip>
                    {
                    this.state.cashVoucher.can_edit?
                    <Tooltip title="Edit"><IconButton
                      color="primary"
                      name="edit_cash_voucher"
                      id="edit_cash_voucher"
                    >
                      <Link to={{
                        pathname: `${this.state.cashVoucher.id}/edit`,
                        state: {
                          item: this.state.cashVoucher,
                        }
                      }}>
                        <EditIcon/>
                      </Link>
                    </IconButton></Tooltip>:null}
                    {this.state.cashVoucher.can_delete?<Tooltip title="Delete"><IconButton
                      color="secondary"
                      name="delete_cash_voucher"
                      id="delete_cash_voucher"
                      onClick={this.handleOpenDeletePopup}
                    >
                      <DeleteIcon/>
                    </IconButton></Tooltip>:null}
                  </div>
                </div>
              </div>
              <hr/>
              <div className={styles.detailsBody}>
                <Grid container spacing={1}>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Voucher Number </b></div>
                    <div>:&emsp; {this.state.cashVoucher.prefix?(this.state.cashVoucher.prefix):null}{this.state.cashVoucher.reference_no}</div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Branches </b></div>
                    <div>:&emsp;&emsp; {this.state.cashVoucher.branch?this.state.cashVoucher.branch.name:null}</div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Expense Type </b></div>
                    <div>:&emsp;&emsp; {this.state.cashVoucher.expense_type? this.state.cashVoucher.expense_type.name:null} </div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Payee </b></div>
                    <div>:&emsp;&emsp;
                      <a href={`/payees/${this.state.cashVoucher.payee?this.state.cashVoucher.payee.id:null}`} target="_blank"
                         rel="noopener noreferrer">
                        {this.state.cashVoucher.payee?this.state.cashVoucher.payee.name:null}
                      </a>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Prepared By </b></div>
                    <div>:&emsp; {this.state.cashVoucher.creator} </div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Status </b></div>
                    <div>:&emsp;&emsp; {this.state.cashVoucher.status === 'active' ? (this.state.cashVoucher.post_status) :
                      <span style={{color: 'red'}}>{this.state.cashVoucher.status}</span>}   </div>
                  </div>
                </Grid>
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                    <div><b> Amount </b></div>
                    <div>:&emsp; {parseFloat(this.state.cashVoucher.amount).toLocaleString(navigator.language, {minimumFractionDigits: 2})} </div>
                  </div>
                </Grid>
                
                 <Grid item xs={12} lg={12} className={styles.gridBody}>
                  <div className={styles.detailListCustom}>
                  <div><b> Amount in Words</b></div>
                  <div className={styles.valueDetails}>
                    :&emsp;&emsp;
                    <div><b>
                      <i>

                        {this.state.cashVoucher.amount > 0 ?
                          (
                            <div>
                              {converter.toWords(this.state.cashVoucher.amount.toString().split('.')[0])}
                              {/*+*/}
                              {this.state.cashVoucher.amount.toString().split('.').length === 2 && this.state.cashVoucher.amount.toString().split('.')[1].toString !== null ?
                                (
                                  <span>
                                        <span className={styles.wordAnd}> and </span>
                                    {this.state.cashVoucher.amount.toString().split('.')[1]}
                                    {(this.state.cashVoucher.amount.toString().split('.')[1].length === 1 ? ('0') : ('')).toString()}
                                    /100 Pesos Only
                                    </span>
                                ) : ''}
                            </div>

                          )
                          : null}
                      </i>
                    </b>
                    </div>
                  </div>
                </div>
                </Grid> 
                <Grid item xs={this.state.forPrint ? 6 :12} sm={6} md={6} lg={6} className={styles.gridBody}>
                  <div className={styles.detailList}>
                  <div><b> Particulars</b></div>
                  <div className={styles.valueDetails}>:&emsp;&emsp;
                    <div>{this.state.cashVoucher.remarks}</div>
                  </div>
                </div>
                </Grid>


                </Grid>
              </div>

              <hr/>
              <div>
                <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor={this.state.forPrint? "": "secondary"}
                    className={styles.tabBody} variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example">
                  <Tab className={this.state.openPrintPDFPopup? styles.noDisplay: styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}>CASH VOUCHER DETAILS</span></div>}{...a11yProps(0)} />
                  <Tab className={this.state.openPrintPopup? styles.noDisplay:styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}>JOURNAL ENTRIES</span></div>}{...a11yProps(1)} />
                  <Tab className={this.state.forPrint? styles.noDisplay : styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}>ATTACHMENTS</span></div>} {...a11yProps(2)} />
                    <Tab className={this.state.forPrint? styles.noDisplay : styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}>BILLS</span></div>} {...a11yProps(3)} />
                    <Tab className={this.state.forPrint? styles.noDisplay : styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}>AUDIT LOGS</span></div>} {...a11yProps(4)} />
                </Tabs>
                <TabPanel value={this.state.value} index={0} className={styles.tabListBody}>
                  <CashVoucherContentDetailTab
                    cashVoucherContents={this.state.cashVoucherContents}
                    cancelCashVoucher={this.cancelCashVoucher}
                    cashVoucher={this.state.cashVoucher}
                    forPrint={this.state.forPrint}
                    openPrint={this.openPrint}
                  />
                </TabPanel>
                <TabPanel value={this.state.value} index={1} className={styles.tabListBody}>
                  <JournalEntriesDetailsTab
                    cashVoucher={this.state.cashVoucher}
                    journalEntries={this.state.journalEntries}
                    activeJournal={this.activeJournal}
                    cancelJournal={this.cancelJournal}
                    handleReverseJournal={this.handleReverseJournal}
                    openPrint={this.openPrintPDF}
                    forPrint={this.state.forPrint}
                    />
                </TabPanel>
                <TabPanel value={this.state.value} index={2} className={styles.tabListBody}>
                  <AttachmentDetailTab
                    attachments={this.state.attachments}
                    openAttachment={this.openAttachment}
                  />
                </TabPanel>
                <TabPanel value={this.state.value} index={3} className={styles.tabListBody}>
                  <Bills
                    model='cash_vouchers'
                    itemId={this.state.cashVoucher.id}
                  />
                </TabPanel>
                <TabPanel value={this.state.value} index={4} className={styles.tabListBody}>
                  <AuditLog gid={this.state.cashVoucher.gid}/>
                </TabPanel>
              </div>

              <SimplePopUp
                openPopup={this.state.cashVoucher.status === 'active' ? this.state.activeCashVoucherPopup : this.state.cancelCashVoucherPopup}
                title="Cash Voucher"
                items={this.state.cashVouchers}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                {this.state.cashVoucher.status === 'active' ? (
                  <div>
                  <span>
                    <b>Would you like to cancel this Cash Voucher?</b>
                  </span>
                    <div align="right" className={styles.actionButton}>
                      <Button color="primary" variant="outlined"
                        onClick={this.cancelCashVoucherSubmit}
                      >save</Button></div>
                  </div>
                ) : <div>
                  <span>
                    <b>Would you like to activate this Cash Voucher?</b>
                  </span>
                  <div align="right" className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.activeCashVoucherSubmit}
                    >save</Button></div>
                </div>
                }
              </SimplePopUp>
              {
                this.state.cashVoucher.journal !==null?(
              <SimplePopUp
                openPopup={this.state.cashVoucher.journal.is_posted === false ? this.state.activeJournalPopup : this.state.cancelJournalPopup}
                title="Journal"
                items={this.state.cashVouchers}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                {this.state.cashVoucher.journal.is_posted === false ? (
                    <div>
                  <span>
                    <b>Would you like to post this Journal?</b>
                  </span>
                      <div
                        align="right"
                        className={styles.actionButton}>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={this.activeJournalSubmit}
                        >save</Button></div>
                    </div>
                  ) :
                  <div>
                  <span>
                    <b>Would you like to unpost this Journal?</b>
                  </span>
                    <div align="right" className={styles.actionButton}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.cancelJournalSubmit}
                      >save</Button>
                    </div>
                  </div>
                }
              </SimplePopUp>
              ):null
            }
              <SimplePopUp
                openPopup={this.state.openAttachmentPopup}
                title="Upload Attachments"
                items={this.state.attachments}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <AttachmentForm
                  attachment={this.state.attachment}
                  error={this.state.error_messages} item={this.state.cashVoucher}
                  onFileChange={this.onFileChange}
                  submit={this.handleAttacthmentSubmit} onchange={this.handleAttactmentInputChange}/>
              </SimplePopUp>
              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.cashVoucher}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model="Cash Voucher"
              />
               <SimplePopUp
                openPopup={this.state.openReverseJournalPopUp}
                title="Confirmation"
                handleClose={this.closeReverseJournal}
                maxWidth='sm' 
              >
                 <div>
                  <span>
                    <b>Would you like to reverse this Journal&apos;s entries?</b>
                  </span>
                    <div align="right" className={styles.actionButton}>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.reverseJournal}
                      >yes</Button>
                    </div>
                  </div>
              </SimplePopUp>
              
              <SimplePopUp
                openPopup={this.state.openPrintPopup? this.state.openPrintPopup : this.state.openPrintPDFPopup}
                title={this.state.openPrintPopup? "Print Cash Voucher" : "Print Journal Entries"}
                items={this.state.bill}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              > 
                <div>
                <span>
                  <b>Do you want to overwrite sinagtory person?</b>
                </span>
                <div align="right" className={styles.actionButton}>
                    <div>
                      {printPdf}
                    </div>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.changeSign}
                    >Yes</Button></div>
                </div>
              </SimplePopUp>

              <SimplePopUp
                openPopup={this.state.changeSignPopup}
                title="Change Signatory"
                items={this.state.bill}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              > 
                <form>
                <TextField
                  autoComplete="off"
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="creator"
                  fullWidth="true"
                  label="Name"
                  onChange={this.handleInputChangePDF}
                  value={this.state.signatory.creator}
                  id="bill-name"
                />
                </form>
                <div align="right" className={styles.actionButton}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.handleClose}
                    >Cancel</Button>
                    <div onClick={this.submitChangeSign}>{ChangeSignPrintPdf}</div> 
                </div>
              </SimplePopUp>

              <footer 
              className={this.state.forPrint?null:styles.noDisplay}
              >
                <div className={styles.signLine}></div>
                <h3 >{this.state.cashVoucher.creator}</h3></footer> 


              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
            
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default CashVoucherShow
CashVoucherShow.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}
