// eslint-disable-next-line
import React from 'react'
import axios from 'axios'
import styles from './GeneralLedgers.module.css'
import PropTypes from 'prop-types'
import ClearAllIcon from '@material-ui/icons/ClearAll'
import ProgressBar from "../../ProgressBar/ProgressBar";
import GeneralLedgersSearchForm from './GeneralLedgersSearchForm'
import GeneralLedgersTable from './GeneralLedgersTable';
import SimplePopUp from '../../SimplePopUp/SimplePopUp'
import { Button, Grid } from '@material-ui/core'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import AlertMessage from "../../Notify/AlertMessage";
import { withRouter } from 'react-router-dom'
import * as Excel from 'exceljs'
import { saveAs } from 'file-saver'

export const Component = withRouter(() => {

})

class GeneralLedgers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'General Ledger',
      generalLedgers: [],
      account: { status: 'true', accounts: [] },
      title: '',
      load: false,
      tableHead: ['code', 'name', 'account_balance'],
      branch: {},
      branches: [],
      accounts: [],
      openPopup: false,
      closeDate: {
        closing_at: ''
      },
      searchUrl: "/v1/accounts/general_ledger",
      searchParams: {},
      isOpen: false,
      message: '',
      type: '',
      withSort: true,

    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleAutoComplete = this.handleAutoComplete.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.openJournalsForClose = this.openJournalsForClose.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.closeJournals = this.closeJournals.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.toggleCheckbox = this.toggleCheckbox.bind(this)
    this.handleXlsxDownloadClick = this.handleXlsxDownloadClick.bind(this)
    this.transformDataToExcelFormat = this.transformDataToExcelFormat.bind(this)
    this.exportToXlsx = this.exportToXlsx.bind(this)
    this.numberFormatter = this.numberFormatter.bind(this)
    this.handleAutoCompleteBranch = this.handleAutoCompleteBranch.bind(this)
  }

  handleCloseAlert(event, reason) {

    if (reason === 'clickaway') {
      return this.setState({
        isOpen: false
      })
    }

  }

  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = { status: true }
    const query = new URLSearchParams(this.props.location.search)
    query.forEach(function (value, key) {
      if (key !== 'account_ids') {
        if (key === 'code') {
          searchParams[key] = value.replace(/\*/g, '')
        } else {
          searchParams[key] = value
        }
      }
    })
    if (query.get('branch_id') !== undefined && query.get('branch_id') !== null && query.get('branch_id') !== '') {
      axios({
        method: 'get',
        url: '/v1/branches/' + query.get('branch_id'),
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        searchParams["branch"] = resp.data
      })
    }
    if (query.get('account_ids') !== undefined && query.get('account_ids') !== null && query.get('account_ids') !== "") {
      var account_ids = query.get('account_ids').split(',')
      var searchparamsAccounts = []
      axios({
        method: 'get',
        url: '/v1/accounts',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        resp.data.map((account) => {
          account_ids.map((accId) => {
            if (account.id === parseInt(accId)) {
              searchparamsAccounts.push(account)
            }
          })
        })
      })
      searchParams["accounts"] = searchparamsAccounts
    }
    this.setState({ account: searchParams })
    this.loadGeneralLedgers(searchUrl, this.props.location.search ? true : false, true)
  }

  getSearchParams() {
    var searchParams = []
    Object.entries(this.state.account).map(([key, value]) => {
      if (value != '' && value != 'all' && key != 'accounts' && key != 'branch' && value != undefined && value != null) {
        searchParams.push([key, value].join("="))
      }
    })
    if (this.state.account.branch) {
      if (this.state.account.branch.id) {
        searchParams.push("branch_id=" + this.state.account.branch.id)
      }
    }
    if (this.state.account.accounts !== null && this.state.account.accounts !== undefined) {
      var accounts = ''
      if (this.state.account.accounts.length > 0) {
        this.state.account.accounts.map((account, idx) => {
          if (idx === this.state.account.accounts.length - 1) {
            accounts = accounts + account.id
          } else {
            accounts = accounts + account.id + ','
          }
        })
        searchParams.push("account_ids=" + accounts)
      }

    }
    return searchParams
  }

  loadGeneralLedgers(url, searchLedger, firstLoad) {
    if (searchLedger) {
      axios({
        method: 'get',
        url: url.includes('?') ? url + '&per_page=1000000&sort_by[code]=asc' : url + '?per_page=1000000&sort_by[code]=asc',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ generalLedgers: resp.data.accounts })
        this.setState({ load: true })
      }).catch(error => {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Occurred",
          type: 'error'
        })
      })
    } else {
      this.setState({ load: true })
    }
    if (firstLoad) {
      if (localStorage.getItem('branches')) {
        this.setState({ branches: JSON.parse(localStorage.getItem('branches')) })
      } else {
        axios({
          method: 'get',
          url: '/v1/branches',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ branches: resp.data })
        })
      }
      axios({
        method: 'get',
        url: '/v1/accounts',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ accounts: resp.data })

      })
    }

  }

  handleSubmit() {
    this.setState({ load: false })
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadGeneralLedgers([this.state.searchUrl, searchParams].join("?"), true, false)
  }

  handleInputChange(e) {
    if (e.target.name === "date_of_journal_start") {
      var dateStart = new Date(e.target.value).toLocaleDateString('fr-Ca')
      var lastDay = new Date(new Date(dateStart).getFullYear(), new Date(dateStart).getMonth() + 1, 0).toLocaleDateString('fr-Ca')
      this.setState({
        account: {
          ...this.state.account,
          [e.target.name]: e.target.value,
          date_of_journal_end: lastDay
        }
      })
    }
    else {
      this.setState({
        account: {
          ...this.state.account,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  handleKeyPress(e) {
    if (e.key.toLowerCase() === 'enter') {
      this.handleSubmit()
    }
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: '' })
    this.setState({ account: { date_of_journal_start: '', date_of_journal_end: '', status: '', branch: {}, accounts: [] } })
    this.setState({ generalLedgers: [] })
  }

  handleAutoCompleteBranch(event, values) {
    this.setState({
      account: {
        ...this.state.account,
        branch: values
      }
    })
  }

  handleAutoComplete(event, values) {
    this.setState({
      account: {
        ...this.state.account,
        accounts: values
      }
    })
  }

  handleClose() {
    this.setState({ openPopup: false })
  }

  openJournalsForClose() {
    this.setState({ closeDate: { closing_at: this.state.account.date_of_journal_end ? moment(this.state.account.date_of_journal_end).startOf('month').format('YYYY-MM-DD') : '' }, openPopup: true })
  }

  handleDateChange(e) {
    const startOfMonth = moment(e).startOf('month').format('YYYY-MM-DD')
    this.setState({ closeDate: { closing_at: startOfMonth } })
  }

  closeJournals() {
    axios.get('/v1/journals/close', {
      params: this.state.closeDate,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({ openPopup: false })
    })
  }

  toggleCheckbox(e) {
    this.setState({ withSort: e.target.value })
  }

  numberFormatter(value) {
    return value
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  exportToXlsx(generalLedgers) {
    const { account } = this.state
    const workbook = new Excel.Workbook()
    const worksheet = workbook.addWorksheet('General Ledger Reports')
    const border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
    }

    worksheet.mergeCells('A1:H1')
    worksheet.getCell('A1').value = "General Ledger Reports"
    worksheet.mergeCells('A2:H2')
    worksheet.getCell('A2').value = `Date Range: ${moment(account.date_of_journal_start).format("L")} - ${moment(account.date_of_journal_end).format("L")} `
    worksheet.mergeCells('A3:H3')
    worksheet.getCell('A3').value = `Branch: ${
      account.branch ?
        account.branch.name ?
          account.branch.name :
          'All' :
        'All'}`
    worksheet.mergeCells('A4:H4')
    worksheet.getCell('A4').value = `Status: ${
      account.status === true || account.status === 'true' ?
        'Posted' :
          account.status === 'false' ?
          'Not Posted' :
          'All'
    }`
    worksheet.getRows(1, 4).forEach((row) => {
      row.eachCell((cell) => {
        cell.font = {
          name: 'Arial',
          size: 15,
          bold: true
        }
        cell.alignment = { horizontal: 'center' }
      })
    })

    worksheet.columns = [
      {
        key: 'accountCode',
        width: 20
      },
      {
        key: 'accountTitle',
        width: 50
      },
      {
        key: 'description',
        width: 50
      },
      {
        key: 'dateOfJournal',
        width: 20
      },
      {
        key: 'referenceNo',
        width: 20
      },
      {
        key: 'debit',
        width: 20
      },
      {
        key: 'credit',
        width: 20
      },
      {
        key: 'balance',
        width: 20
      }
    ]

    worksheet.getRow(worksheet.rowCount + 1).values = [
      'Account Code',
      'Account Title',
      'Description',
      'Date of Journal',
      'Reference No.',
      'Debit',
      'Credit',
      'Balance'
    ]

    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.font = { size: 12, name: 'Arial' }
      cell.alignment = { horizontal: 'center', wrapText: true, vertical: 'middle' }
      cell.border = border
    })

    generalLedgers
    .sort((a, b) => {
      if (a.accountCode.toLowerCase() < b.accountCode.toLowerCase()) return -1
      if (a.accountCode.toLowerCase() > b.accountCode.toLowerCase()) return 1
      return 0
    })
    .forEach((data) => {
      const row = worksheet.addRow({
        ...data,
        accountCode: Number(data.accountCode),
        description: 'Beginning Balance',
        balance: Math.sign(data.balance) === -1 ? 
          `(${this.numberFormatter(Math.abs(data.balance))})` : 
          this.numberFormatter(data.balance)
      })
      let beginningBalance = data.balance, newBalance = 0, totalDebit = 0, totalCredit = 0

      row.eachCell((cell, colNumber) => {
        if (colNumber === 1 || colNumber === 4 || colNumber === 5) cell.alignment = { horizontal: 'center', wrapText: true, vertical: 'middle' }
        if (colNumber === 6 || colNumber === 7 || colNumber === 8) cell.alignment = { horizontal: 'right', wrapText: true, vertical: 'middle' }
        cell.border = border
        cell.font = { name: 'Arial', size: 12 }
      })
      worksheet.mergeCells(`C${worksheet.rowCount}:G${worksheet.rowCount}`)
      worksheet.getCell(`C${worksheet.rowCount}`).alignment = { horizontal: 'left', wrapText: true, vertical: 'middle' }

      if (data.journalEntries.length) {
        const sortedJournalEntries = this.state.withSort ?
          [
            ...data.journalEntries.filter((entry) => entry.amount_credit === '0.0'),
            ...data.journalEntries.filter((entry) => entry.amount_debit === '0.0')
          ] :
          data.journalEntries.sort(function(a,b){
            return new Date(a.date_of_journal) - new Date(b.date_of_journal);
          })
        
        sortedJournalEntries
        .forEach((journalEntry) => {
          const {
            description,
            date_of_journal,
            reference_no,
            amount_debit,
            amount_credit
          } = journalEntry

          newBalance = beginningBalance + Number(amount_debit) - Number(amount_credit)
          totalDebit += Number(amount_debit)
          totalCredit += Number(amount_credit)

          const row = worksheet.addRow({
            accountCode: '',
            accountTitle: '',
            description: description,
            dateOfJournal: moment(date_of_journal).format("MM-DD-YYYY"),
            referenceNo: reference_no,
            debit: this.numberFormatter(Number(amount_debit)),
            credit: this.numberFormatter(Number(amount_credit)),
            balance: Math.sign(newBalance) === -1 ? 
            `(${this.numberFormatter(Math.abs(newBalance))})` : 
            this.numberFormatter(newBalance)
          })
          beginningBalance = newBalance
          row.eachCell((cell, colNumber) => {
            cell.alignment = { horizontal: 'left', wrapText: true, vertical: 'middle' }
            if (colNumber === 4 || colNumber === 5) cell.alignment = { horizontal: 'center', wrapText: true, vertical: 'middle' }
            if (colNumber === 6 || colNumber === 7 || colNumber === 8) cell.alignment = { horizontal: 'right', wrapText: true, vertical: 'middle' }
            cell.border = border
            cell.font = { name: 'Arial', size: 12 }
          })
        })

        const totalRow = worksheet.addRow({
          referenceNo: 'Total',
          debit: Math.sign(totalDebit) === -1 ? 
            `(${this.numberFormatter(Math.abs(totalDebit))})` : 
            this.numberFormatter(totalDebit),
          credit: Math.sign(totalCredit) === -1 ? 
          `(${this.numberFormatter(Math.abs(totalCredit))})` : 
          this.numberFormatter(totalCredit),
          balance: Math.sign(beginningBalance) === -1 ? 
          `(${this.numberFormatter(Math.abs(beginningBalance))})` : 
          this.numberFormatter(beginningBalance)
        })

        totalRow.eachCell((cell, colNumber) => {
          cell.alignment = { horizontal: 'right', wrapText: true, vertical: 'middle' }
          cell.font = { name: 'Arial', size: 12, bold: true }
          cell.border = border
          if (colNumber === 5) cell.alignment = { horizontal: 'center', wrapText: true, vertical: 'middle' }
        })
      }
    })

    // save to excel file
    workbook.xlsx
    .writeBuffer()
    .then((res) => {
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const blob = new Blob([res], {
        type: fileType
    })
    saveAs(blob, `general_ledgers.xlsx`)
        this.setState({
            isOpen: true,
            message: 'File saved!',
            type: 'success'
        })
    })
    .catch(() => {
        this.setState({
            isOpen: true,
            message: 'Error occured while saving data..',
            type: 'error'
        })
    })
  }

  transformDataToExcelFormat(data) {
    const excelFormat = data.map((generalLedger) => {
      const { 
        code,
        name,
        journal_entries,
        account_balance
      } = generalLedger

      return {
        accountCode: code,
        accountTitle: name,
        journalEntries: journal_entries,
        balance: account_balance
      }
    })

    return excelFormat
  }

  handleXlsxDownloadClick() {
    const { generalLedgers } = this.state
    const dataToPrint = this.transformDataToExcelFormat(generalLedgers)
    this.exportToXlsx(dataToPrint)
  }

  render() {

    return (
      <>
        {
          this.state.load ? (
            <div>
              <GeneralLedgersSearchForm
                item={this.state.account}
                branches={this.state.branches}
                accounts={this.state.accounts}
                search={this.handleSubmit}
                clearSearch={this.clearSearch}
                handleAutoComplete={this.handleAutoComplete}
                handleChange={this.handleInputChange}
                handleKeyPress={this.handleKeyPress}
                handleAutoCompleteBranch={this.handleAutoCompleteBranch}
              />
              <GeneralLedgersTable
                items={this.state.generalLedgers}
                model={this.state.model}
                icon={<ClearAllIcon size="large" />}
                openJournalsForClose={this.openJournalsForClose}
                ref={el => (this.componentRef = el)}
                toggleCheckbox={this.toggleCheckbox}
                withSort={this.state.withSort}
                withXlsxDownload={true}
                handleXlsxDownloadClick={this.handleXlsxDownloadClick}
              />
              <SimplePopUp
                openPopup={this.state.openPopup}
                title="Close Journals"
                handleClose={this.handleClose}
                maxWidth='sm'
              >
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={5} ><div className={styles.textFields}><b>Close Journals Up to : </b></div></Grid>
                    <Grid item xs={7}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          variant="dialog"
                          openTo="month"
                          views={["month", "year"]}
                          label="Month and Year"
                          helperText="Closing Date"
                          format="MM/yyyy"
                          className={styles.textFields}
                          value={this.state.closeDate.closing_at}
                          onChange={this.handleDateChange}
                          size="small"
                          inputVariant="outlined"

                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <div className={styles.note}>Note: All journals up to this date can no longer be edited upon save. </div>
                  <div className={styles.actionButton}>
                    <Button variant="outlined" color="primary" onClick={this.closeJournals} >Save</Button>
                  </div>
                </>
              </SimplePopUp>
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>

          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default withRouter(GeneralLedgers)

GeneralLedgers.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
