// eslint-disable-next-line
import React from 'react'
import PropTypes from 'prop-types'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import axios from 'axios'
class Bills extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Bill',
      bills: [],
      load: false,
      tableHead: [ 'reference_no', 'remarks', 'amount']
    }
  }

  componentDidMount() {
    this.loadbills('/v1/'+this.props.model+'/'+this.props.itemId+'/bills')
  }

  loadbills(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ bills: resp.data })
      this.setState({load: true})
    })
  }
  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              {
                  this.state.bills.length>0?<SimpleTable
                  items={this.state.bills}
                  headers={this.state.tableHead}
                  noHeader={true}
                  model="Bill"
                />:'No bills linked to this voucher'
              }
            </div>
          ) : (
            null
          )}
      </>
    )
  }
}
export default Bills
Bills.propTypes = {
  model: PropTypes.string,
  itemId: PropTypes.number
}
