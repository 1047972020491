import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios"
import CashVoucherForm from './CashVoucherForm'
import { Redirect } from 'react-router'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import SelectBills from '../CheckVoucher/SelectBills'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import {Switch, Grid,Button,TextField,FormControlLabel} from '@material-ui/core'
import styles from './CashVoucher.module.css'
import moment from 'moment'
class CashVoucherNewAndUpdate extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      model: 'Cash Voucher',
      cashVouchers: [],
      banks: [],
      errorMessages: {},
      load: false,
      bank: {},
      expenseType: {},
      expenseTypes: [],
      payees: [],
      payee: {},
      branches: [],
      branch: {},
      inputTax: {},
      withHoldingTax: {},
      accounts: [],
      contentBank: {},
      forEditCashVoucherContent: [],
      fordeleteCashVoucherContent: [],
      cashVoucherContent: [],
      initialCashVoucherContent: { account_code: '', account: {}, amount_debit: 0, amount_credit: 0 },
      
      cashVoucher: {
        id: '',
        amount: '',
        remarks: '',
        reference_no: '',
        taxable: false,
        branch_id: '',
        prefix: localStorage.getItem('cashvoucher_prefix') ? (localStorage.getItem('cashvoucher_prefix')) : 'CV',
        postfix: '',
        payee_id: '',
        journal_id: '',
        post_status: '',
        filed_at: new Date().toLocaleDateString('fr-Ca'),
      },
      redirect: false,
      totalDigit: 8,
      withInputTax: true,
      withHoldingTaxCode: '20410',
      fromBills: false,
      billsInputTax : 0,
      selectedBills: [],
      selectedBillIds: [],
      openBillsPopUp: false,
      bills: [],
      billInputTax: false,
      billTaxable: true,
      due_at_from: moment().startOf('month').format('YYYY-MM-DD'),
      due_at_to: moment().endOf('month').format('YYYY-MM-DD'),
      accountsPayable: {},
      billContents: [],
      isBillWithInputTax: false,
      isBillTaxExpanded: false,
      pageNumber: 1,
      totalPages: 0,
      totalCount: 0,
      select_bill_reference_no: ''
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.handleAutocompleteSearch = this.handleAutocompleteSearch.bind(this)
    this.amountChange = this.amountChange.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.addCashVoucherContent = this.addCashVoucherContent.bind(this)
    this.handleCashVoucherContentChange = this.handleCashVoucherContentChange.bind(this)
    this.removeCashVoucherContent = this.removeCashVoucherContent.bind(this)
    this.populateContent = this.populateContent.bind(this)
    this.handleContentAutocomplete = this.handleContentAutocomplete.bind(this)
    this.handleCashVoucherContentAmount = this.handleCashVoucherContentAmount.bind(this)
    this.backToIndex = this.backToIndex.bind(this)
    this.handleInputTax = this.handleInputTax.bind(this)
    this.openSelectBills = this.openSelectBills.bind(this)
    this.closeBills = this.closeBills.bind(this)
    this.selectAllBills = this.selectAllBills.bind(this)
    this.handleCheckBills = this.handleCheckBills.bind(this)
    this.setAmt = this.setAmt.bind(this)
    this.handleBillInputTax = this.handleBillInputTax.bind(this)
    this.handleBillSwitch = this.handleBillSwitch.bind(this)
    this.filter = this.filter.bind(this)
    this.handleDueDate = this.handleDueDate.bind(this)
    this.padFix = this.padFix.bind(this)
    this.getReferenceNo = this.getReferenceNo.bind(this)
    this.removeBill = this.removeBill.bind(this)
    this.handlePageNumberChange = this.handlePageNumberChange.bind(this)
    this.handleSelectBillReferenceNo = this.handleSelectBillReferenceNo.bind(this)
  }


  componentDidMount() {
    if (this.props.match.params.id !== undefined) {
      if (this.props.location.pathname.includes("payees")) {
        // axios({
        //   method: 'get',
        //   url: '/v1/employees/profiles',
        //   headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        // }).then(resp => {
        //   if (resp.data.branch_id) {
        //     axios({
        //       method: 'get',
        //       url: '/v1/branches/' + resp.data.branch_id,
        //       headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        //     }).then(resp => {
        //       this.setState({ branch: resp.data })
        //       this.getReferenceNo()
        //     })
        //   }
          
        //   })
        
        axios({
          method: 'get',
          url: '/v1/payees/' + this.props.match.params.id,
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          this.setState({ payee: resp.data })
          this.loadCashVoucher('/v1/cash_vouchers/search?&sort_by[date_of_check]=desc')
        })
      }

      else {
        axios({
          method: 'get',
          url: '/v1/cash_vouchers/' + this.props.match.params.id,
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          var item = resp.data
          this.setState({ expenseType: resp.data.expense_type })
          this.setState({ bank: resp.data.bank })
          this.setState({ payee: resp.data.payee })
          this.setState({ branch: resp.data.branch, selectedBillIds: resp.data.bill_ids })
          this.setState({ withInputTax: resp.data.with_input_vat }) 
          axios({
            method: 'get',
            url: '/v1/cash_vouchers/' + this.props.match.params.id + '/bills',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(response => {
            this.setState({ selectedBills: response.data })
          })
          axios({
            method: 'get',
            url: '/v1/cash_vouchers/' + this.props.match.params.id + '/contents',
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            const newData = resp.data.map((item) => ({

              id: item.id,
              account_code: item.account_code, account: item.account, amount_debit: parseFloat(item.amount_debit).toFixed(2), amount_credit: parseFloat(item.amount_credit).toFixed(2)
            }))
            this.setState({ cashVoucherContent: newData })
            if (resp.data.filter(item => item.account_code === this.state.withHoldingTaxCode).length > 0) {
              item['taxable'] = true
            }
            this.setState({ cashVoucher: item })
            this.loadCashVoucher('/v1/cash_vouchers/search?&sort_by[date_of_check]=desc')
          })
        })
      }
    }
    else if (this.props.match.params.bill_id !== undefined) {
      this.setState({selectedBillIds: [this.props.match.params.bill_id]})
      this.setState({fromBills: true})
      // axios({
      //   method: 'get',
      //   url: '/v1/employees/profiles',
      //   headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      // }).then(resp => {
      //   if (resp.data.branch_id) {
      //     axios({
      //       method: 'get',
      //       url: '/v1/branches/' + resp.data.branch_id,
      //       headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      //     }).then(resp => {
      //       this.setState({ branch: resp.data })
      //       this.getReferenceNo()
      //     })
      //   }
       
      // })
      axios({
        method: 'get',
        url: '/v1/bills/' + this.props.match.params.bill_id,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        var item = resp.data
        item['id']=''
        item['remarks']= resp.data.remarks + "                     Bill # " + resp.data.reference_no
        item['taxable'] = resp.data.taxable
        item['amount']=parseFloat(resp.data.amount).toFixed(2) 
        this.setState({ expenseType: resp.data.expense_type })
        this.setState({withInputTax: resp.data.with_input_vat})
        this.setState({ payee: resp.data.payee })
        this.setState({ branch: resp.data.branch })
        this.setState({ 
          isBillWithInputTax: resp.data.with_input_vat,
          isBillTaxExpanded: resp.data.taxable
        })

        axios({
          method: 'get',
          url: '/v1/bills/' + this.props.match.params.bill_id + '/contents',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
          const newData = []
          resp.data.map((item) => {
            if (item.account.name.includes("Accounts Payable")) {
              var newItem = {
                id: '',
                account_code: item.account_code,
                account: item.account,
                amount_debit:  parseFloat(item.amount_credit).toFixed(2),
                amount_credit: parseFloat(item.amount_debit).toFixed(2)
              }
              newData.push(newItem)
            }
          })
          this.setState({ cashVoucherContent: newData })
          
          this.setState({ cashVoucher: item })
          this.loadCashVoucher('/v1/cash_vouchers/search?&sort_by[date_of_check]=desc')
        })
      })
    }

    else {
      // axios({
      //   method: 'get',
      //   url: '/v1/employees/profiles',
      //   headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      // }).then(resp => {
      //   if (resp.data.branch_id) {
      //     axios({
      //       method: 'get',
      //       url: '/v1/branches/' + resp.data.branch_id,
      //       headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      //     }).then(resp => {
      //       this.setState({ branch: resp.data })
      //      this.getReferenceNo()
      //     })
      //   }
      //   this.loadCashVoucher('/v1/cash_vouchers/search')
      // })
      this.loadCashVoucher('/v1/cash_vouchers/search')
    }
  }

  loadCashVoucher(url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ cashVouchers: resp.data.cash_vouchers })
    })
    axios({
      method: 'get',
      url: '/v1/banks/search?per_page=1000',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ banks: resp.data.banks })
    })
    axios({
      method: 'get',
      url: '/v1/payees/search',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payees: resp.data.payees })
    })
    axios({
      method: 'get',
      url: '/v1/branches',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ branches: resp.data })
      if(!this.props.match.params.id) this.setState({ branch: resp.data[0] })
      this.getReferenceNo()
    })
    axios({
      method: 'get',
      url: '/v1/check_voucher_expense_types',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ expenseTypes: resp.data })

    })
    axios({
      method: 'get',
      url: '/v1/accounts/search?per_page=1000',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ accounts: resp.data.accounts })

    })
    axios({
      method: 'get',
      url: '/v1/accounts/search?name=Input',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ inputTax: resp.data.accounts.length > 0 ? (resp.data.accounts[0]) : ({}) })

    })
    axios({
      method: 'get',
      url: '/v1/accounts/search?name=Withholding tax - expanded',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({
        withHoldingTax: {
          account_code: resp.data.accounts.length > 0 ? (resp.data.accounts[0].code) : '',
          account: resp.data.accounts.length > 0 ? resp.data.accounts[0] : '',
          amount_debit: 0,
          amount_credit: 0
        }
      })
    })
    axios({
      method: 'get',
      url: '/v1/accounts/search?name=Accounts Payable',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {

      this.setState({
        contentBank: {
          code: resp.data.accounts.length > 0 ? (resp.data.accounts[0].code) : '',
          account: resp.data.accounts.length > 0 ? { id: resp.data.accounts[0].id, type: resp.data.accounts[0].type, name: resp.data.accounts[0].name, code: resp.data.accounts[0].code, name_type: resp.data.accounts[0].name_type } : '',
          amount_debit: 0,
          amount_credit: 0
        }
      })
    })
    this.setState({ load: true })
  }

  handleInputChange(e) {
    if(e.target.name==="reference_no"){
      this.setState({
        cashVoucher: {
          ...this.state.cashVoucher,
          reference_no: this.padFix(e.target.value)[0]
        }
      })
    }else{
    this.setState({
      cashVoucher: {
        ...this.state.cashVoucher,
        [e.target.name]: e.target.value
      }
    })
  }
  }

  amountChange(value) {
    var index = this.state.cashVoucherContent.indexOf(this.state.cashVoucherContent.filter(item => item.account_code === this.state.contentBank.code)[0])
    this.setState({
      cashVoucher: {
        ...this.state.cashVoucher,
        amount: value,
        taxable: false,
      }
    })

    this.populateContent('amount', value, this.state.inputTax ? (this.state.inputTax) : null)

    if (this.state.cashVoucher.taxable) {
      this.removeCashVoucherContent(index, this.state.cashVoucherContent.filter(item => item.account_code === this.state.withHoldingTax.account_code)[0])
    }
  }



  handleAutocomplete(event, values) {
    if (event.target.id.includes("payee")) {
      this.setState({ payee: values })
    } else if (event.target.id.includes("expenseType")) {
      this.setState({ expenseType: values })
      if (values !== null) {
        var index = this.state.cashVoucherContent.indexOf(this.state.cashVoucherContent.filter(item => item.account_code === this.state.contentBank.code)[0])
        var indexTax = this.state.cashVoucherContent.indexOf(this.state.cashVoucherContent.filter(item => item.account_code === this.state.withHoldingTax.account_code)[0])
        var initialAmount = 0
        if (this.state.cashVoucher.taxable) {
          if (parseFloat(values.multiplier) > 0) {
            initialAmount = parseFloat(this.state.cashVoucherContent[index]['amount_credit']) + parseFloat(this.state.cashVoucherContent[indexTax]['amount_credit'])

            const updatedCVContents = this.state.cashVoucherContent.filter(item => item.account_code !== this.state.withHoldingTax.account_code)
            updatedCVContents[index]["amount_credit"] = parseFloat(initialAmount - (initialAmount / 1.12 * parseFloat(values.multiplier))).toFixed(2)

            var item = this.state.withHoldingTax
            item['amount_credit'] = parseFloat(initialAmount / 1.12 * parseFloat(values.multiplier)).toFixed(2)
            this.setState({ cashVoucherContent: [...updatedCVContents, item] })
            this.setState({
              cashVoucher: {
                ...this.state.cashVoucher,
                amount: parseFloat(initialAmount - (initialAmount / 1.12 * parseFloat(values.multiplier))).toFixed(2),
              }
            })
          }
          else {
            if (indexTax) {
              initialAmount = parseFloat(this.state.cashVoucher.amount) + parseFloat(this.state.cashVoucherContent[indexTax]['amount_credit'])
            }
            var items = this.state.cashVoucherContent
            items[index]["amount_credit"] = initialAmount
            this.setState({ cashVoucherContent: items.filter(item => item.account_code !== this.state.withHoldingTax.account_code) })
            this.setState({
              cashVoucher: {
                ...this.state.cashVoucher,
                amount: initialAmount,
                taxable: false
              }
            })
          }
        }
      }
    } else if (event.target.id.includes("branch")) {
      if (values !== null) {
        axios({
          method: 'get',
          url: '/v1/cash_vouchers/search?branch_id=' + values.id + '&sort_by[date_of_cash]=desc',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
         this.getReferenceNo()
        })
      }
      this.setState({ branch: values })
    }
  }



  populateContent(params, value, tax) {
    var contents = []
    var inputTax = tax
    if(!this.state.fromBills){
    if (this.state.cashVoucherContent.length <= 2 && this.state.withInputTax) {
      if (params.toLowerCase() === 'amount') {
        if (this.state.contentBank !== undefined) {
          contents = [
            {
              account_code: inputTax.code,
              account: inputTax,
              amount_debit: parseFloat(((value / 1.12 * .12) * 100) / 100).toFixed(2),
              amount_credit: 0
            },
            {
              account_code: this.state.contentBank.code,
              account: this.state.contentBank.account,
              amount_debit: 0,
              amount_credit: value
            },
          ]
        }
      }
      this.setState({ cashVoucherContent: contents })
    } else {
      var index = this.state.cashVoucherContent.indexOf(this.state.cashVoucherContent.filter(item => item.account_code === this.state.contentBank.code)[0])
      contents = this.state.cashVoucherContent
      if (params.toLowerCase() === 'amount') {
        contents[index]["amount_credit"] = value

      } else if (params.toLowerCase() === 'bank') {
        var bankIdx = 0
        if (this.state.withInputTax) {
          bankIdx = 1
        }
        contents[bankIdx]["account_code"] = value.code
        contents[bankIdx]["account"] = value
        contents[bankIdx]["amount_debit"] = 0
        contents[bankIdx]["amount_credit"] = this.state.cashVoucher.taxable ? parseFloat(this.state.cashVoucher.amount - (this.state.cashVoucher.amount / 1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2) : this.state.cashVoucher.amount
      }
      this.setState({ cashVoucherContent: contents })
    }
    }
    else{
      contents=this.state.cashVoucherContent
      contents[0].amount_debit=value
    }
  }

  handleSubmit() {
    var cashVoucherContent = []
    var fordeleteCashVoucherContent = this.state.fordeleteCashVoucherContent
    if (this.state.cashVoucherContent.length > 0) {
      this.state.cashVoucherContent.map((content) => {
        var item = {
          id: content.id,
          account_code: content.account.code,
          account_id: content.account.id,
          amount_credit: content.amount_credit,
          amount_debit: content.amount_debit
        }
        cashVoucherContent.push(item)
      })
    }
    const item = this.state.cashVoucher
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/cash_vouchers/'
    } else {
      if (fordeleteCashVoucherContent.length > 0) {
        fordeleteCashVoucherContent.map((content) => {
          var item = {
            id: content.id,
            account_code: content.account.code,
            account_id: content.account.id,
            _destroy: true,
            amount_credit: 0,
            amount_debit: 0
          }
          cashVoucherContent.push(item)
        })
      }

      method = 'put'
      url = '/v1/cash_vouchers/' + item.id
    }
    item["check_voucher_contents_attributes"] = cashVoucherContent

    item['bill_ids'] = this.state.selectedBillIds
    item["payee_id"] = this.state.payee.id
    item["expense_type_id"] = this.state.expenseType.id
    item["branch_id"] = this.state.branch.id
    item["reference_no"] = this.state.cashVoucher.reference_no
    item["postfix"] = localStorage.getItem('cashvoucher_prefix') ? (localStorage.getItem('cashvoucher_prefix')) : 'CV'
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({ cashVoucher: newData })
      this.setState({ redirect: true })
    }).catch(error => {
      if (error.response.status === 422) {
        var errors = {}
        Object.keys(error.response.data.errors[0].detail.errors).map((field) => {
          errors[field] = error.response.data.errors[0].detail.errors[field][0]
        })
        this.setState({ errorMessages: errors })
      } else {
        this.setState({ isOpen: true, message: error.response.status.toString() + " Unexpected Error Occurred", type: 'error' })
      }
    })

  }


  handleSwitch(event) {
    var index = this.state.cashVoucherContent.indexOf(this.state.cashVoucherContent.filter(item=>item.account_code===this.state.contentBank.code)[0])
    const value = event.target.checked
    var inputTaxIndex = 0
    if(this.state.inputTax.code){
      inputTaxIndex = this.state.cashVoucherContent.indexOf(this.state.cashVoucherContent.filter(item=>item.account_code===this.state.inputTax.code)[0])
   }

    if(index!==-1){
      if (value === true) {
        const updatedCVContents = this.state.cashVoucherContent
        let amt = 0
        var item=this.state.withHoldingTax
        if(inputTaxIndex!==-1 || this.state.isBillWithInputTax){      
          item['amount_credit']=  parseFloat(this.state.cashVoucher.amount/1.12 * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
          amt = parseFloat(this.state.cashVoucher.amount-(this.state.cashVoucher.amount/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
          updatedCVContents[index]["amount_credit"]= parseFloat(this.state.cashVoucher.amount-(this.state.cashVoucher.amount/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
        } else {
          item['amount_credit']=  parseFloat(this.state.cashVoucher.amount * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
          amt = parseFloat(this.state.cashVoucher.amount-(this.state.cashVoucher.amount * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
          updatedCVContents[index]["amount_credit"]= parseFloat(this.state.cashVoucher.amount-(this.state.cashVoucher.amount * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
        }
        this.setState({ cashVoucherContent: [...updatedCVContents, item] })
        this.setState({ 
          cashVoucher: {
            ...this.state.cashVoucher, 
            amount:amt,
            taxable: value
          }
        })
      } else {
        var amtToAdd = 0
        var inputAmt = 0
        var items = this.state.cashVoucherContent
        if (this.state.cashVoucherContent.length > 0) {
          this.state.cashVoucherContent.map((voucher) => {
            if (voucher.account_code === this.state.withHoldingTax.account_code) {
              amtToAdd = voucher.amount_credit
              if (voucher.id !== undefined) {
                this.setState({ fordeleteCashVoucherContent: [...this.state.fordeleteCashVoucherContent, voucher] })
              }
            }
          })
        }
        items[index]["amount_credit"]=parseFloat(parseFloat(this.state.cashVoucher.amount) + parseFloat(amtToAdd)).toFixed(2)
        inputAmt = parseFloat(parseFloat(this.state.cashVoucher.amount) + parseFloat(amtToAdd)).toFixed(2)
        if(inputTaxIndex!==-1){
          items[inputTaxIndex]["amount_debit"]= parseFloat((inputAmt / 1.12 * .12) * 100 / 100).toFixed(2)
        }
        this.setState({ forEditCashVoucherContent: [...this.state.forEditCashVoucherContent.filter(item => item.id ===items[index].id), items[index]] })
        this.setState({
          cashVoucher: {
            ...this.state.cashVoucher, 
            amount: parseFloat(this.state.cashVoucher.amount) + parseFloat(amtToAdd),
            taxable: value
          }
        }) 
        this.setState({cashVoucherContent: items.filter(item => item.account_code !== this.state.withHoldingTax.account_code)})
      }  
    }else{
      this.setState({cashVoucher: {...this.state.cashVoucher, taxable: value}})
    }
  
  this.setState({billTaxable: !value})
  }

  addCashVoucherContent() {
    this.setState({ cashVoucherContent: [...this.state.cashVoucherContent, { ...this.state.initialCashVoucherContent }] })
  }

  handleCashVoucherContentChange(e) {
    var value = null

    const updatedVoucher = [...this.state.cashVoucherContent]
    if (e.target.dataset.fieldType === "amount_debit" || e.target.dataset.fieldType === "amount_credit") {
      value = parseFloat(e.target.value).toFixed(2)
    } else {
      value = e.target.value
    }
    updatedVoucher[e.target.dataset.id][e.target.dataset.fieldType] = value
    if (updatedVoucher[e.target.dataset.id]["id"] !== undefined) {
      this.setState({ forEditCashVoucherContent: [...this.state.forEditCashVoucherContent.filter(item => item.id !== updatedVoucher[e.target.dataset.id].id), updatedVoucher[e.target.dataset.id]] })
    }
    this.setState({ cashVoucherContent: updatedVoucher })
  }

  removeCashVoucherContent(idx, content) {
    var index = this.state.cashVoucherContent.indexOf(this.state.cashVoucherContent.filter(item => item.account_code === this.state.contentBank.code)[0])
    const updatedVouchers = [...this.state.cashVoucherContent]
    if (updatedVouchers[idx].account_code === this.state.withHoldingTax.account_code) {

      this.setState({ cashVoucher: { ...this.state.cashVoucher, taxable: false } })
      updatedVouchers[index]['amount_credit'] = parseFloat(this.state.cashVoucher.amount) + parseFloat(updatedVouchers[idx].amount_credit)
      this.setState({ cashVoucher: { ...this.state.cashVoucher, amount: parseFloat(this.state.cashVoucher.amount) + parseFloat(updatedVouchers[idx].amount_credit) } })
    }
    if (updatedVouchers[idx].account_code === this.state.inputTax.code) {
      this.setState({ withInputTax: false })
    }

    updatedVouchers.splice(idx, 1)
    if (content.id !== undefined) {
      var item = this.state.cashVoucherContent[idx]
      this.setState({ fordeleteCashVoucherContent: [...this.state.fordeleteCashVoucherContent, item] })
    }
    this.setState({ cashVoucherContent: updatedVouchers })
  }

  handleContentAutocomplete(event, values) {
    var updatedVoucher = [...this.state.cashVoucherContent]
    var editVouchers = [...this.state.forEditCashVoucherContent]
    if (values !== null) {

      updatedVoucher[event]["account"] = values
      updatedVoucher[event]["account_code"] = values.code

      if (updatedVoucher[event]["id"] !== undefined) {
        this.setState({
          forEditCashVoucherContent: this.state.forEditCashVoucherContent.filter(item => item.id !== item.id)
        })
        this.setState({ forEditCashVoucherContent: [...editVouchers, updatedVoucher[event]] })
      } else {
        this.setState({ cashVoucherContent: updatedVoucher })
      }
    } else {
      updatedVoucher = [...this.state.cashVoucherContent]
      updatedVoucher[event]["account"] = {}
      updatedVoucher[event]["account_code"] = ''
      this.setState({ cashVoucherContent: updatedVoucher })
    }

  }
  handleCashVoucherContentAmount(event, idx, params) {
    var value = 0
    if (event.floatValue !== undefined) {
      value = event.floatValue

    }
    const updatedVoucher = [...this.state.cashVoucherContent]
    updatedVoucher[idx][params] = value
    if (updatedVoucher[idx]["id"] !== undefined) {
      this.setState({ forEditCashVoucherContent: [...this.state.forEditCashVoucherContent.filter(item => item.id !== updatedVoucher[idx].id), updatedVoucher[idx]] })
    }
    this.setState({ cashVoucherContent: updatedVoucher })
  }
  backToIndex() {
    this.setState({
      cashVoucher: {
        ...this.state.cashVoucher,
        id: ""
      }
    })
    this.setState({ redirect: true })
  }
  handleAutocompleteSearch(e, value) {
    axios({
      method: 'get',
      url: '/v1/payees/search?name=' + value,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ payees: resp.data.payees })
    })
  }

  handleInputTax(event) {
    const value = event.target.checked
    this.setState({ withInputTax: value })
    let initialAmount = 0
    this.state.cashVoucherContent.forEach((content) => {
      initialAmount = parseFloat(initialAmount) + parseFloat(content.amount_credit)
    })
    
    if (!value) {

      if (this.state.cashVoucherContent.length > 0) {
        this.state.cashVoucherContent.map((voucher) => {
          if (voucher.account_code === this.state.inputTax.code) {
            if (voucher.id !== undefined) {
              this.setState({ fordeleteCashVoucherContent: [...this.state.fordeleteCashVoucherContent, voucher] })
            }
          }
        })
      }

      if (this.state.isBillTaxExpanded) {
        this.setState({
          cashVoucherContent: this.state.cashVoucherContent.filter(item => item.account_code !== this.state.inputTax.code)
        })
      } else {
        if (this.state.cashVoucher.taxable) {
          let withHoldingTax = this.state.withHoldingTax
          let bank = this.state.contentBank

          this.setState({
            cashVoucherContent: [
              ...this.state.cashVoucherContent.filter((content) => content.account_code !== bank.code && content.account_code !== withHoldingTax.account_code && content.account_code !== this.state.inputTax.code),
              {
                account_code: bank.code,
                account: bank.account,
                amount_debit: this.state.cashVoucherContent.find((content) => content.account_code === bank.code)?.amount_debit,
                amount_credit: parseFloat(initialAmount-(initialAmount * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
              },
              {
                ...withHoldingTax,
                amount_credit: parseFloat(initialAmount * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
              }
            ]
          })
          this.setState({
            cashVoucher: {
              ...this.state.cashVoucher,
              amount: parseFloat(initialAmount-(initialAmount * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
            }
          })
        } else {
          this.setState({
            cashVoucherContent: this.state.cashVoucherContent.filter(item => item.account_code !== this.state.inputTax.code)
          })
        }
      }
    } else {
      if(this.state.cashVoucher.taxable) {
        let withHoldingTax = this.state.withHoldingTax
        let bank = this.state.contentBank

        this.setState({
          cashVoucherContent: [
            {
              account_code: this.state.inputTax.code,
              account: this.state.inputTax,
              amount_debit: parseFloat(((parseFloat(initialAmount) / 1.12 * .12)  * 100) / 100).toFixed(2),
              amount_credit: 0
            },
            ...this.state.cashVoucherContent.filter((content) => content.account_code !== bank.code && content.account_code !== withHoldingTax.account_code),
            {
              account_code: bank.code,
              account: bank.account,
              amount_debit: this.state.cashVoucherContent.find((content) => content.account_code === bank.code)?.amount_debit,
              amount_credit: parseFloat(initialAmount-(initialAmount/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
            },
            {
              ...withHoldingTax,
              amount_credit: parseFloat(initialAmount/1.12 * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
            }
          ]
        })
        this.setState({
          cashVoucher: {
            ...this.state.cashVoucher,
            amount: parseFloat(initialAmount-(initialAmount/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
          }
        })
      } else {
        this.setState({
          cashVoucherContent: [{
            account_code: this.state.inputTax.code,
            account: this.state.inputTax,
            amount_debit: parseFloat(((parseFloat(initialAmount) / 1.12 * .12) * 100) / 100).toFixed(2),
            amount_credit: 0
          }, 
          ...this.state.cashVoucherContent]
        })
      }
    }
    this.setState({billInputTax: !value})
  }

  openSelectBills(){
    this.setState({ billContents: []})
    this.setState({cashVoucherContent: []})
    if(this.state.payee.id){
      this.setState({openBillsPopUp: true})
    }
  }

  groupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

  
  closeBills(){
    this.setState({ billContents: []})
    var payableContents = []
    if(this.state.selectedBillIds.length>0){
      this.state.selectedBillIds.map((billId)=>{
        axios({
          method: 'get',
          url: '/v1/bills/'+billId+'/contents',
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(resp => {
        payableContents = [...resp.data.filter(acc =>acc.account.name.includes("Accounts Payable")),...payableContents]
        this.setState({ billContents: [...payableContents]})
        if(payableContents.length === this.state.selectedBillIds.length){
          this.populateBills(payableContents)
        }
      })
    })}else{
      this.setState({ openBillsPopUp: false})
    }    
  }

  populateBills(billContents){
    var accountPayables = []
    var payableContents = billContents
    if(this.state.selectedBills.length>0){
           var amt = 0
           this.state.selectedBills.map((bill)=>{
             amt = parseFloat(parseFloat(amt) + parseFloat(bill.amount)).toFixed(2)
           })
           this.setState({
            cashVoucher: {
              ...this.state.cashVoucher,
              amount: amt
            }
          })
         }else{
           this.setState({cashVoucherContent: []})
           this.setState({
             cashVoucher: {
               ...this.state.cashVoucher,
               amount: 0
             }
           })
         }
         if (this.state.bank !== undefined) {
          var index = this.state.cashVoucherContent.indexOf(this.state.cashVoucherContent.filter(item=>item.account_code===this.state.contentBank.code)[0])
          var item=this.state.withHoldingTax
          item['amount_credit']=  parseFloat(amt/1.12 * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
          var inputTax = {
            account_code: this.state.inputTax.code,
            account: this.state.inputTax,
            amount_debit: parseFloat(((amt / 1.12 * .12) * 100) / 100).toFixed(2),
            amount_credit: 0
          }
          var contents = [
            {
              account_code: this.state.contentBank.code,
              account: this.state.contentBank,
              amount_debit: 0,
              amount_credit: this.state.cashVoucher.taxable?parseFloat(amt-(amt/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2): parseFloat(amt).toFixed(2)
            }
          ]
        }
        if(this.state.cashVoucher.taxable && index!==-1){ 
        this.removeCashVoucherContent(index,this.state.cashVoucherContent.filter(item=>item.account_code===this.state.withHoldingTax.account_code)[0])
      }
    if(billContents.length>0){
      Object.keys(this.groupArrayOfObjects(payableContents, "account_id")).map((item) => {
       var account = this.state.accounts.filter(acc=>acc.id.toString()===item)[0]
      var accountPayable = {}
      var sum = this.groupArrayOfObjects(payableContents, "account_id")[item].reduce((partial_sum, a) => parseFloat(parseFloat(partial_sum) + parseFloat(a.amount_credit)).toFixed(2),0)
        accountPayable = {
          account_code: account.code,
          account: account,
          amount_credit: 0,
          amount_debit: sum
        }
        accountPayables.push(accountPayable)
        if (this.state.cashVoucher.taxable && this.state.withInputTax) {
          this.setState({cashVoucherContent: [inputTax, ...contents,item,...accountPayables],openBillsPopUp: false})
        }else if (!this.state.cashVoucher.taxable && this.state.withInputTax) {
          this.setState({cashVoucherContent: [inputTax, ...contents,...accountPayables],openBillsPopUp: false})
        }else if (this.state.cashVoucher.taxable && !this.state.withInputTax) {
          this.setState({cashVoucherContent: [ ...contents,item,...accountPayables],openBillsPopUp: false})
        }else{
          this.setState({cashVoucherContent: [ ...contents,...accountPayables],openBillsPopUp: false})
        }
      })
    }
  }

  
  selectAllBills(){
    var checkAll = []
    var unCheckAll = this.state.selectedBillIds
    var checkRemaining = this.state.selectedBillIds
    if (this.state.bills.length > 0) {
      if (this.state.selectedBillIds.length <= 0) {
        this.state.bills.map((trans) => {
          checkAll.push(trans.id)

        })
         this.setState({ selectedBillIds: checkAll })
         this.setState({ selectedBills: this.state.bills})
      } else if (this.state.selectedBillIds.length === this.state.bills.length) {
        this.state.bills.map((trans) => {
          unCheckAll.splice(unCheckAll.indexOf(trans.id), 1)
        })
        this.setState({ selectedBillIds: unCheckAll })
        this.setState({ selectedBills: []})
      } else {
        this.state.bills.map((transMenu) => {
          var addState = true
          this.state.selectedBillIds.map((selectedId) => {
            if (selectedId === transMenu.id) {
              addState = false
            }
          })
          if (addState === true) {
            checkRemaining.push(transMenu.id)
            this.setState({ selectedBills: [...this.state.selectedBills, transMenu]})
          }
        })
        return this.setState({ selectedCases: checkRemaining })
      }
    }
  }

  handleCheckBills(e,item){
      var selectedExisting = this.state.selectedBillIds
      if (e.target.checked === true) {
        this.setState({ selectedBills: [...this.state.selectedBills, { ...item }] })
        selectedExisting.push(item.id)
      }
      else {
        this.setState({
          selectedBills: this.state.selectedBills.filter(bill => bill.id !== item.id)
        })
        selectedExisting.splice(selectedExisting.indexOf(item.id), 1)
      }
      this.setState({ selectedBillIds: selectedExisting})
  }

  setAmt(value){
    this.setState({
      cashVoucher: {
        ...this.state.cashVoucher,
        amount: value,
        taxable: false,
      }
    })
  }

  handleBillInputTax(e){
    this.setState({billInputTax: e.target.checked})
  }

  handleBillSwitch (e){
      this.setState({billTaxable: e.target.checked})
  }

  handleDueDate(e){
    this.setState({[e.target.name]: e.target.value})
  }

  filter(){
    this.setState({
      pageNumber: 1,
      totalPages: 0,
      totalCount: 0,
    })

    axios({
      method: 'get',
      url: '/v1/bills/search?payee_id='+this.state.payee.id +
        '&with_input_vat='+this.state.billInputTax.toString() +
        '&taxable='+this.state.billTaxable.toString() +
        '&due_at_from='+this.state.due_at_from +
        '&due_at_to='+this.state.due_at_to +
        '&is_paid=false' +
        '&page='+this.state.pageNumber +
        `${this.state.select_bill_reference_no ? `&reference_no=${this.state.select_bill_reference_no}` : ''}`,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({
        bills: resp.data.bills,
        totalCount: resp.data.meta.total_count,
        totalPages: resp.data.meta.total_pages
      })
    })
  }

  padFix(n) {
    return ('00000000'+n.toString()).match(/\S{8}$/);
  }

  getReferenceNo(){
    axios.get('/v1/cash_vouchers/generate_ref_no', {
      params: { branch_id: this.state.branch.id},
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({cashVoucher: {...this.state.cashVoucher,
        prefix: `${localStorage.getItem('cashvoucher_prefix')?(localStorage.getItem('cashvoucher_prefix')):'CA'}${this.state.branch.name.charAt(0)}`,
        reference_no: this.padFix(resp.data.reference_no)[0]}})
    })
  }

  removeBill(billToRemove){
    var bill= billToRemove
    this.setState({selectedBills: this.state.selectedBills.filter(item=> item.id !==bill.id)})
    this.setState({selectedBillIds: this.state.selectedBillIds.filter(item=> item !==bill.id)})
    
    if(this.state.selectedBills.filter(item=> item.id !==bill.id).length>0){
      var amt = 0
      this.state.selectedBills.filter(item=> item.id !==bill.id).map((bill)=>{
        amt = parseFloat(parseFloat(amt) + parseFloat(bill.amount)).toFixed(2)
      })
    }else{
      this.setState({cashVoucherContent: []})
      this.setState({
        cashVoucher: {
          ...this.state.cashVoucher,
          amount: 0
        }
      })
    }
    if(amt!==undefined){
      this.setState({cashVoucherContent: []})
        var index = this.state.cashVoucherContent.indexOf(this.state.cashVoucherContent.filter(item=>item.account_code===this.state.contentBank.code)[0])
          this.setState({
            cashVoucher: {...this.state.cashVoucher,amount: this.state.cashVoucher.taxable?parseFloat(amt-(amt/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2): amt}
          })
          if (this.state.bank !== undefined) {
            var item=this.state.withHoldingTax
            item['amount_credit']=  parseFloat(amt/1.12 * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
            var inputTax = {
              account_code: this.state.inputTax.code,
              account: this.state.inputTax,
              amount_debit: parseFloat(((amt / 1.12 * .12) * 100) / 100).toFixed(2),
              amount_credit: 0
            }
            var contents = [
              {
                account_code: this.state.contentBank.code,
                account: this.state.contentBank.account,
                amount_debit: 0,
                amount_credit: this.state.cashVoucher.taxable?parseFloat(amt-(amt/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2): amt
              }
            ]
            if (this.state.cashVoucher.taxable && this.state.withInputTax) {
              this.setState({cashVoucherContent: [inputTax, ...contents,item]})
            }else if (!this.state.cashVoucher.taxable && this.state.withInputTax) {
              this.setState({cashVoucherContent: [inputTax, ...contents]})
            }else if (this.state.cashVoucher.taxable && !this.state.withInputTax) {
              this.setState({cashVoucherContent: [ ...contents,item]})
            }else{
              this.setState({cashVoucherContent: [ ...contents]})
            } 
          }
          if(this.state.cashVoucher.taxable && index!==-1){ 
          this.removeCashVoucherContent(index,this.state.cashVoucherContent.filter(item=>item.account_code===this.state.withHoldingTax.account_code)[0])
        }
      this.setState({openBillsPopUp: false})
    }
    this.setState({openBillsPopUp: false})
  }

  handlePageNumberChange(event, value) {
    axios({
      method: 'get',
      url: '/v1/bills/search?payee_id='+this.state.payee.id +
        '&with_input_vat='+this.state.billInputTax.toString() +
        '&taxable='+this.state.billTaxable.toString() +
        '&due_at_from='+this.state.due_at_from +
        '&due_at_to='+this.state.due_at_to +
        '&is_paid=false' +
        '&page='+value +
        `${this.state.select_bill_reference_no ? `&reference_no=${this.state.select_bill_reference_no}` : ''}`,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({
        bills: resp.data.bills,
        totalCount: resp.data.meta.total_count,
        totalPages: resp.data.meta.total_pages
      })
    })
    this.setState({ pageNumber: value })
  }

  handleSelectBillReferenceNo(event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { redirect } = this.state

    if (redirect)
      return (<Redirect to={{
        pathname: '/cash_vouchers/' + this.state.cashVoucher.id
      }} />)
    return (

      this.state.load ? (
        <>
        <CashVoucherForm
          error={this.state.errorMessages}
          handleSubmit={this.handleSubmit}
          handleAutocomplete={this.handleAutocomplete}
          handleInputChange={this.handleInputChange}
          handleCashVoucherContentChange={this.handleCashVoucherContentChange}
          removeCashVoucherContent={this.removeCashVoucherContent}
          addCashVoucherContent={this.addCashVoucherContent}
          handleSwitch={this.handleSwitch}
          amountChange={this.amountChange}
          accounts={this.state.accounts}
          branches={this.state.branches}
          payee={this.state.payee}
          branch={this.state.branch}
          bank={this.state.bank}
          expenseType={this.state.expenseType}
          cashVoucher={this.state.cashVoucher}
          cashVoucherContent={this.state.cashVoucherContent}
          payees={this.state.payees}
          banks={this.state.banks}
          expenseTypes={this.state.expenseTypes}
          backToIndex={this.backToIndex}
          handleContentAutocomplete={this.handleContentAutocomplete}
          handleCashVoucherContentAmount={this.handleCashVoucherContentAmount}
          handleAutocompleteSearch={this.handleAutocompleteSearch}
          withInputTax={this.state.withInputTax}
          handleInputTax={this.handleInputTax}
          contentBank={this.state.contentBank}
          selectedBills={this.state.selectedBills}
          openSelectBills={this.openSelectBills}
          setAmt={this.setAmt}
          fromBills={this.state.fromBills}
          getReferenceNo={this.getReferenceNo}
          removeBill={this.removeBill}
          isBillWithInputTax={this.state.isBillWithInputTax}
          isBillTaxExpanded={this.state.isBillTaxExpanded}
        />
         <SimplePopUp
          openPopup={this.state.openBillsPopUp}
          title="Select Bills"
          handleClose={this.closeBills}
          maxWidth='lg' 
        >
           <>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <TextField
                autoComplete="off"
                variant="outlined"
                type="date"
                size="small"
                name="due_at_from"
                fullWidth
                label="Start Date"
                onChange={this.handleDueDate}
                value={this.state.due_at_from}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoComplete="off"
                variant="outlined"
                type="date"
                size="small"
                name="due_at_to"
                fullWidth
                label="End Date"
                onChange={this.handleDueDate}
                value={this.state.due_at_to}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                autoComplete="off"
                variant="outlined"
                size="small"
                name="select_bill_reference_no"
                fullWidth
                label="Reference No."
                onChange={this.handleSelectBillReferenceNo}
                value={this.state.select_bill_reference_no}
              />
            </Grid> 
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    disabled={this.state.cashVoucher.taxable}
                    checked={this.state.billTaxable}
                    onChange={this.handleBillSwitch}
                    name="taxable" 
                  />
                }
                label="Tax Expandable"
                style={{ margin: 0 }}
              />
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    disabled={this.state.withInputTax}
                    checked={this.state.billInputTax}
                    onChange={this.handleBillInputTax}
                    name="withInputTax" 
                  />
                }
                label="With Input VAT"
                style={{ margin: 0 }}
                />
            </Grid> 
          </Grid>
          <div className={styles.actionButton}>
            <Button 
              onClick={this.filter}
              color="primary"
              variant="outlined"
            >
              Filter
            </Button>
          </div>
          {
            this.state.bills.length>0?(
              <SelectBills 
                bills={this.state.bills}
                selectAllBills={this.selectAllBills}
                handleCheckBills={this.handleCheckBills}
                selectedBills={this.state.selectedBillIds}
                closeBills={this.closeBills}
                billTaxable={this.state.billTaxable}
                billInputTax={this.state.billInputTax}
                handlePageNumberChange={this.handlePageNumberChange}
                pageNumber={this.state.pageNumber}
                totalPages={this.state.totalPages}
                totalCount={this.state.totalCount}
              />
            ):null
          }
          </>
        </SimplePopUp>
        </>
      ) : <ProgressBar model={this.state.model} />
    )
  }
}

export default CashVoucherNewAndUpdate

CashVoucherNewAndUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      bill_id: PropTypes.string
    })
  }),
  location: PropTypes.object
}
