import React from 'react'
import styles from './Payees.module.css'

import PropTypes from 'prop-types'

import {
	TextField,
	Button,
	MenuItem,
	Grid
} from '@material-ui/core'

class PayeesSearch extends React.Component {
	render() {
		return (
			<div>
				<Grid container spacing={1}>
					<Grid item xs={6} lg={3}>
						<TextField
							autoComplete="off"
							className={styles.textFields}
							variant="outlined"
							size="small"
							name="name"
							fullWidth={true}
							label="Name"
							onChange={this.props.handleChange}
							onKeyPress={this.props.handleKeyPress}
							value={this.props.item.name}
							id="payeeSearchForm-name"
						/>
					</Grid>
					<Grid item xs={6} lg={3}>
						<TextField
							autoComplete="off"
							className={styles.textFields}
							variant="outlined"
							size="small"
							name="address"
							fullWidth={true}
							label="Address"
							onChange={this.props.handleChange}
							onKeyPress={this.props.handleKeyPress}
							value={this.props.item.address}
							id="payeeSearchForm-address"
						/>
					</Grid>
					<Grid item xs={6} lg={3}>
						<TextField
							autoComplete="off"
							className={styles.textFields}
							variant="outlined"
							size="small"
							name="tin"
							fullWidth={true}
							label="TIN No."
							onChange={this.props.handleChange}
							onKeyPress={this.props.handleKeyPress}
							value={this.props.item.tin}
							id="payeeSearchForm-tin"
						/>
					</Grid>
					<Grid item xs={6} lg={3}>
						<TextField
							autoComplete="off"
							select
							className={styles.textFields}
							variant="outlined"
							size="small"
							name="classification"
							fullWidth={true}
							label="Classification"
							onChange={this.props.handleChange}
							value={this.props.item.classification || ''}
							id="payeeSearchForm-classification"
						>
							<MenuItem value="">&nbsp;</MenuItem>
							<MenuItem value="vatable">Vatable</MenuItem>
							<MenuItem value="non_vatable">Non Vatable</MenuItem>
						</TextField>
					</Grid>
				</Grid>
				<div className={styles.actionButton}>
					<Button variant="outlined" id="payee-search" color="primary" onClick={() => this.props.search()} >Search</Button>
					<Button variant="outlined" id="payee-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
				</div>
				
			</div>
		)
	}
}
export default PayeesSearch

PayeesSearch.propTypes = {
	handleChange: PropTypes.func,
	item: PropTypes.object,
	search: PropTypes.func,
	clearSearch: PropTypes.func,
	handleKeyPress: PropTypes.func
}
