import React from 'react'

import PropTypes from 'prop-types'

import { TextField, IconButton, TableCell, TableRow, TableBody, MenuItem } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import styles from './Journal.module.css'
import NumberFormat from 'react-number-format'

class JournalEntries extends React.Component {

  render() {
    const accountNameId = `account_name_${this.props.idx}`
    const accountCreditId = `account_credit_${this.props.idx}`
    const accountDebitId = `account_debit_${this.props.idx}`
    const deleteButton = `delete_account_${this.props.idx}`

    return (
      <TableBody key={`journal_entry-${this.props.idx}`} name="journal_entry">
        <TableRow>
        <TableCell className={this.props.withReference?null:styles.small}>
            <label> {this.props.journalEntries[this.props.idx].account.code}</label>
          </TableCell>

          <TableCell className={styles.accountWidth}>
            <Autocomplete
              value={this.props.journalEntries[this.props.idx].account}
              id={accountNameId}
              name={accountNameId}
              fullWidth
              size="small"
              options={(this.props.accounts).sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={(event, value) => this.props.handleAutocomplete(this.props.idx, value)}
              renderInput={(params) => (
                <TextField {...params} name={accountNameId} variant="outlined" label="Account" />
              )}
            />
          </TableCell>
          {
            this.props.withReference?(
            <>
            <TableCell className={styles.medium}>
              <TextField
              autoComplete="off"
               variant="outlined" 
               size="small"
               fullWidth 
               name="description"
               value={this.props.journalEntries[this.props.idx].description}
               onChange={(event) => this.props.handleMemo(this.props.idx,event)}
               />
          </TableCell>
          <TableCell className={styles.small}>
              <TextField variant="outlined" fullWidth size="small" 
              onChange={(event) => this.props.handleReferences(this.props.idx,event)}
              value={this.props.journalEntries[this.props.idx].reference_type}
              name='reference_type'
              select>
              {
                this.props.referenceTypes.length> 0?(
                  this.props.referenceTypes.map((referenceType,idx)=>(
                    <MenuItem key={idx} value={referenceType}>{referenceType}</MenuItem>
                  ))
                ):null
              }
              </TextField>
          </TableCell>
          
          <TableCell className={styles.medium}>

          <Autocomplete
              value={this.props.journalEntries[this.props.idx].reference}
              id={accountNameId}
              name={accountNameId}
              fullWidth
              size="small"
              options={(this.props.references).sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={(event, value) => this.props.handleReferenceAutoComplete(this.props.idx, value)}
              onInputChange={(event, value) => this.props.handleReferenceAutoCompleteSearch(this.props.idx,value)}
              renderInput={(params) => (
                <TextField {...params} name={accountNameId} variant="outlined"  />
              )}
            />
          </TableCell>
            </>):null
          }
          
          <TableCell className={styles.medium}>
            <NumberFormat
              customInput={TextField}
              autoComplete="off"
              variant="outlined"
              className={styles.amountBody}
              size="small"
              name={accountDebitId}
              fullWidth
              label="Debit"
              thousandSeparator={true}
              onValueChange={(event)=>this.props.handleJournalEntriesAmountChange(event,this.props.idx,'amount_debit')}
              value={this.props.journalEntries[this.props.idx].amount_debit}
              decimalSeparator="."
              fixedDecimalScale={true}
              decimalScale={2}
            />
          </TableCell>
          <TableCell className={styles.medium}>
            <NumberFormat
              customInput={TextField}
              autoComplete="off"
              variant="outlined"
              className={styles.amountBody}
              size="small"
              name={accountCreditId}
              fullWidth
              label="Credit"
              thousandSeparator={true}
              onValueChange={(event)=>this.props.handleJournalEntriesAmountChange(event,this.props.idx,'amount_credit')}
              value={this.props.journalEntries[this.props.idx].amount_credit}
              decimalSeparator="."
              fixedDecimalScale={true}
              decimalScale={2}
            />
          </TableCell>
        </TableRow>
        <TableRow
          className={styles.actionButtonHoverable}
          align="right">
              <IconButton
              color="secondary"
              name={deleteButton}
              onClick={() => this.props.removeJournalEntry(this.props.idx, this.props.journalEntries[this.props.idx])}>
              <DeleteForeverIcon />
            </IconButton>
          </TableRow>
      </TableBody>
    )
  }
}

export default JournalEntries

JournalEntries.propTypes = {
  idx: PropTypes.number,
  accounts: PropTypes.array,
  journalEntries: PropTypes.array,
  handleJournalEntriesChange: PropTypes.func,
  removeJournalEntry: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleJournalEntriesAmountChange: PropTypes.func,
  withReference: PropTypes.bool,
  referenceTypes: PropTypes.array,
	handleReferences: PropTypes.func,
  references: PropTypes.array,
  handleReferenceAutoComplete: PropTypes.func,
  handleReferenceAutoCompleteSearch: PropTypes.func,
  handleMemo: PropTypes.func
}
