// eslint-disable-next-line
import React from 'react';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import axios from 'axios'
import PropTypes from 'prop-types'
import style from './IncomeStatements.module.css'
import ProgressBar from "../../ProgressBar/ProgressBar";
import { withRouter } from 'react-router-dom'
import IncomeSearchForm from './IncomeSearchForm'
import IncomeStatementTable from './IncomeStatementTable'
import Checkbox from '@material-ui/core/Checkbox'
import AlertMessage from '../../Notify/AlertMessage'
import ReactToPrint from 'react-to-print';
import { IconButton } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print'
import * as Excel from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment';

export const Component = withRouter(() => {

})

class IncomeStatements extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Income Statement',
            incomeStatements: [],
            account: { months: [], is_posted: 'true' },
            title: '',
            load: false,
            tableHead: ['name', 'code', 'name type', 'sales', 'provision for income tax', 'income_special_projects', 'gross_profit'],
            branch: {},
            branches: [],
            withPagination: true,
            currentPage: 1,
            totalPages: 1,
            notConsolidated: false,
            includeZeroes: false,
            months: [
                { value: 1, name: "January" },
                { value: 2, name: "February" },
                { value: 3, name: "March" },
                { value: 4, name: "April" },
                { value: 5, name: "May" },
                { value: 6, name: "June" },
                { value: 7, name: "July" },
                { value: 8, name: "August" },
                { value: 9, name: "September" },
                { value: 10, name: "October" },
                { value: 11, name: "November" },
                { value: 12, name: "December" }
            ],
            urlPrint: {},
            searchUrl: '/v1/accounts/income_statement',
            isOpen: false,
            message: '',
            type: '',
            forPrint: false,
            totalNetIncome: {}
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleAutoComplete = this.handleAutoComplete.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.handleSelectChange = this.handleSelectChange.bind(this)
        this.handleCheck = this.handleCheck.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.changePrintLayout = this.changePrintLayout.bind(this)
        this.handleXlsxDownloadClick = this.handleXlsxDownloadClick.bind(this)
        this.transformDataToExcelFormat = this.transformDataToExcelFormat.bind(this)
        this.exportToXlsx = this.exportToXlsx.bind(this)
        this.numberFormatter = this.numberFormatter.bind(this)
    }

    componentDidMount() {
        var searchUrl = this.state.searchUrl + this.props.location.search
        var searchParams = { months: [], is_posted: 'true' }
        const query = new URLSearchParams(this.props.location.search)
        query.forEach(function (value, key) {
            if (key !== 'months') {
                if (key === 'code') {
                    searchParams[key] = value.replace(/\*/g, '')
                } else {
                    searchParams[key] = value
                }
            }
        })
        if (query.get('branch_id') !== undefined && query.get('branch_id') !== null && query.get('branch_id') !== '') {
            axios({
                method: 'get',
                url: '/v1/branches/' + query.get('branch_id'),
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                searchParams["branch"] = resp.data
            })
        }
        if (query.get('months') !== undefined && query.get('months') !== null) {
            var months = query.get('months').split(',')
            var searchitemMonth = []
            this.state.months.map((stateMonth) => {
                months.map((queryMonth) => {
                    if (stateMonth.value === parseInt(queryMonth)) {
                        searchitemMonth.push(stateMonth)
                    }
                })
            })
            searchParams["months"] = searchitemMonth
        }

        this.setState({ account: searchParams, load: true })
        this.loadIncomeStatements(searchUrl, this.props.location.search ? true : false, true)
    }

    getSearchParams() {
        var searchParams = []
        Object.entries(this.state.account).map(([key, value]) => {
            if (value != '' &&value != 'all' && key != 'months' && key != 'branch' && value != undefined && value != null) {
                searchParams.push([key, value].join("="))
            }
        })
        if (this.state.account.branch) {
            if (this.state.account.branch.id) {
                searchParams.push("branch_id=" + this.state.account.branch.id)
            }
        }
        if (this.state.account.months !== undefined && this.state.account.months !== null) {
            if (this.state.account.months.length > 0) {
                var months = ""
                this.state.account.months.map((month, idx) => {
                    if (idx === this.state.account.months.length - 1) {
                        months = months + month.value
                    } else {
                        months = months + month.value + ','
                    }
                })
                searchParams.push("months=" + months)
            }

        }
        return searchParams
    }


    loadIncomeStatements(url, withSearchParams, firstLoad) {
        this.setState({ load: false })
        if (withSearchParams) {
            axios({
                method: 'get',
                url: url,
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ incomeStatements: resp.data.accounts, withResultsLabel: true })
                this.setState({ urlPrint: resp.data.meta })
                this.setState({ notConsolidated: true, load: true })
                this.setState({ totalNetIncome: resp.data.meta })
            }).catch(error => {
                this.setState({
                    isOpen: true,
                    message: error.response.status.toString() + " Unexpected Error Occurred",
                    type: 'error'
                })
            })
        } else {
            this.setState({ load: true })
        }
        if (firstLoad) {
            if(localStorage.getItem('branches')) {
                this.setState({ branches: JSON.parse(localStorage.getItem('branches')) })
            }else{
            axios({
                method: 'get',
                url: '/v1/branches',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ branches: resp.data })
            })
        }
        }
    }

    handleSubmit() {
        this.setState({ load: false })
        const { history } = this.props
        var searchParams = this.getSearchParams().join("&")
        const params = new URLSearchParams()
        params.append("/search", searchParams)
        history.push({ search: searchParams })
        this.loadIncomeStatements([this.state.searchUrl, searchParams].join("?"), true, false)
    }

    handleInputChange(e) {
        this.setState({
            account: {
                ...this.state.account,
                [e.target.name]: e.target.value
            }
        })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit()
        }
    }

    clearSearch() {
        const { history } = this.props
        history.push({ search: '' })
        this.setState({ incomeStatements: [], urlPrint: {} })
        this.setState({ notConsolidated: false })
        this.setState({ account: { as_of_date: '', is_posted: '', branch: {}, months: [] } })
    }

    handleAutoComplete(event, values) {
        this.setState({
            account: {
                ...this.state.account,
                branch: values
            }
        })
    }
    handleSelectChange(event, values) {
        this.setState({
            account: {
                ...this.state.account,
                months: values
            }
        })
    }

    handleCheck(e) {
        this.setState({ includeZeroes: !this.state.includeZeroes })
        var zeroText = "with_zero=" + e.target.checked.toString()
        var newExportExcel = this.state.urlPrint.export_excel_url.replace('with_zero=false', zeroText).replace('with_zero=true', zeroText)
        var newReportsUrl = this.state.urlPrint.reports_url.replace('with_zero=false', zeroText).replace('with_zero=true', zeroText)
        this.setState({
            urlPrint: {
                ...this.state.urlPrint,
                export_excel_url: newExportExcel,
                reports_url: newReportsUrl
            }
        })
    }

    handleCloseAlert(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({
            isOpen: false
        })
    }

    changePrintLayout(){
        return new Promise((resolve) => {
            this.setState({ forPrint: !this.state.forPrint }, () => resolve());
          });        
    }

    exportToXlsx(revenue, expense) {
        const { account, totalNetIncome } = this.state
        const workbook = new Excel.Workbook()
        const worksheet = workbook.addWorksheet('Income Statement Reports')
        const border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        }
        let totalRevenue = 0, totalExpense = 0

        const spanToCellLetter = String.fromCharCode((account.months.length ? account.months.length + 3 : 3) + 64)
        worksheet.mergeCells(`A1:${spanToCellLetter}1`)
        worksheet.getCell('A1').value = "Income Statement Reports"
        worksheet.mergeCells(`A2:${spanToCellLetter}2`)
        worksheet.getCell('A2').value = `As of: ${moment(account.as_of_date).format("L")}`
        worksheet.mergeCells(`A3:${spanToCellLetter}3`)
        worksheet.getCell('A3').value = `Branch: ${
            account.branch ?
                account.branch.name ?
                account.branch.name :
                'All'
                : 'All'
        }`
        worksheet.mergeCells(`A4:${spanToCellLetter}4`)
        worksheet.getCell('A4').value = `Status: ${
            account.is_posted ?
                account.is_posted === 'true' ?
                    "Posted"
                    : account.is_posted === 'false' ?
                    "Not Posted"
                    : 'All'
                : 'All'
        }`
        worksheet.mergeCells(`A5:${spanToCellLetter}5`)
        worksheet.getCell('A5').value = `Months: ${account.months.length ? 
            account.months.map((month) => month.name ).join(", ") :
            'All'
        }`

        worksheet.eachRow((row) => {
            row.eachCell({ includeEmpty: false }, (cell) => {
                cell.font = { size: 16, bold: true, name: 'Arial' }
                cell.alignment = { horizontal: 'center' }
            })
        })

        worksheet.mergeCells(`A6:${spanToCellLetter}6`)
        worksheet.getCell('A6').value = "Revenue"
        worksheet.getCell('A6').font = { size: 14, bold: true, name: 'Arial' }
        worksheet.getCell('A6').alignment = { horizontal: 'center' }
        worksheet.getCell('A6').border = border
        const monthsColumn = account.months
            .sort((a, b) => a.value - b.value)
            .map((month) => ({
                key: String(month.value),
                width: 20
            }))

        worksheet.columns = [
            {
                key: 'code',
                width: 20
            },
            {
                key: 'name',
                width: 60
            },
            ...monthsColumn,
            {
                key: 'asOfDate',
                width: 20
            }
        ]

        const monthsHeader = account.months
            .sort((a, b) => a.value - b.value)
            .map((month) => month.name)
        worksheet.getRow(worksheet.rowCount + 1).values = [
            'Account No',
            'Account Name',
            ...monthsHeader,
            `As of Date: ${moment(account.as_of_date).format("L")}`
        ]

        worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
            cell.font = { size: 12, name: 'Arial' }
            cell.alignment = { horizontal: 'center', wrapText: true, vertical: 'middle' }
            cell.border = border
        })

        revenue
        .sort(function (a, b) {
            if (a.code.toLowerCase() < b.code.toLowerCase()) return -1
            if (a.code.toLowerCase() > b.code.toLowerCase()) return 1
            return 0
        })
        .forEach((data) => {
            totalRevenue += data.asOfDate
            const row = worksheet.addRow({
                ...data,
                code: Number(data.code),
                ...data.monthBalance.reduce((prevValue, initialValue) => {
                    return {
                        ...prevValue,
                        [String(initialValue.month)]: this.numberFormatter(initialValue.amount)
                    }
                }, {}),
                asOfDate: this.numberFormatter(data.asOfDate)
            })
            row.eachCell((cell, colNumber) => {
                cell.alignment = { horizontal: 'right', vertical: 'middle', wrapText: true }
                if (colNumber === 1) cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }
                if (colNumber === 2) cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true }
                cell.border = border
                cell.font = { size: 12, name: 'Arial' }
            })
        })

        const totalRevenueOfMonths = account.months.map((month) => {
            return {
                [String(month.value)]: 
                    this.numberFormatter(revenue.reduce((sum, initialValue) => {
                        const monthExist = initialValue.monthBalance.find((monthBal) => monthBal.month === month.value)

                        return monthExist ?
                            monthExist.amount + sum :
                            sum
                    }, 0))
            }
        })
        const totalRevOfMonthsRow = totalRevenueOfMonths.reduce((prevValue, initialValue) => {
            return {
                ...prevValue,
                ...initialValue
            }
        }, {})
        const revenueLastRow = worksheet.addRow(totalRevOfMonthsRow)

        revenueLastRow.eachCell((cell) => {
            cell.border = border
            cell.font = { size: 12, name: 'Arial', bold: true}
            cell.alignment = { horizontal: 'right', vertical: 'middle', wrapText: true }
        })

        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`)
        worksheet.getCell(`A${worksheet.rowCount}`).value = "Total Revenue"
        worksheet.getCell(`A${worksheet.rowCount}`).font = { size: 12, name: 'Arial', bold: true}
        worksheet.getCell(`A${worksheet.rowCount}`).alignment = { horizontal: 'right', vertical: 'middle', wrapText: true }
        worksheet.getCell(`A${worksheet.rowCount}`).border = border
        worksheet.getCell(`${spanToCellLetter}${worksheet.rowCount}`).value = this.numberFormatter(totalRevenue)
        worksheet.getCell(`${spanToCellLetter}${worksheet.rowCount}`).font = { size: 12, name: 'Arial', bold: true}
        worksheet.getCell(`${spanToCellLetter}${worksheet.rowCount}`).alignment = { horizontal: 'right', vertical: 'middle', wrapText: true }
        worksheet.getCell(`${spanToCellLetter}${worksheet.rowCount}`).border = border

        worksheet.mergeCells(`A${worksheet.rowCount + 1}:${spanToCellLetter}${worksheet.rowCount + 1}`)
        worksheet.getCell(`A${worksheet.rowCount}`).value = "Expense"
        worksheet.getCell(`A${worksheet.rowCount}`).font = { size: 14, bold: true, name: 'Arial' }
        worksheet.getCell(`A${worksheet.rowCount}`).alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }
        worksheet.getCell(`A${worksheet.rowCount}`).border = border

        expense
        .sort(function (a, b) {
            if (a.code.toLowerCase() < b.code.toLowerCase()) return -1
            if (a.code.toLowerCase() > b.code.toLowerCase()) return 1
            return 0
        })
        .forEach((data) => {
            totalExpense += data.asOfDate
            const row = worksheet.addRow({
                ...data,
                code: Number(data.code),
                ...data.monthBalance.reduce((prevValue, initialValue) => {
                    return {
                        ...prevValue,
                        [String(initialValue.month)]: this.numberFormatter(initialValue.amount)
                    }
                }, {}),
                asOfDate: this.numberFormatter(data.asOfDate)
            })
            row.eachCell((cell, colNumber) => {
                cell.alignment = { horizontal: 'right', vertical: 'middle', wrapText: true }
                if (colNumber === 1) cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true }
                if (colNumber === 2) cell.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true }
                cell.border = border
                cell.font = { size: 12, name: 'Arial' }
            })
        })

        const totalExpensesOfMonths = account.months.map((month) => {
            return {
                [String(month.value)]: 
                    this.numberFormatter(expense.reduce((sum, initialValue) => {
                        const monthExist = initialValue.monthBalance.find((monthBal) => monthBal.month === month.value)

                        return monthExist ?
                            monthExist.amount + sum :
                            sum
                    }, 0))
            }
        })
        const totalExpOfMonthsRow = totalExpensesOfMonths.reduce((prevValue, initialValue) => {
            return {
                ...prevValue,
                ...initialValue
            }
        }, {})
        const expenseLastRow = worksheet.addRow(totalExpOfMonthsRow)

        expenseLastRow.eachCell((cell) => {
            cell.border = border
            cell.font = { size: 12, name: 'Arial', bold: true, color: 'red'}
            cell.alignment = { horizontal: 'right', vertical: 'middle', wrapText: true }
        })

        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`)
        worksheet.getCell(`A${worksheet.rowCount}`).value = "Total Expense"
        worksheet.getCell(`A${worksheet.rowCount}`).font = { size: 12, name: 'Arial', bold: true, color: 'red'}
        worksheet.getCell(`A${worksheet.rowCount}`).alignment = { horizontal: 'right', vertical: 'middle', wrapText: true }
        worksheet.getCell(`A${worksheet.rowCount}`).border = border
        worksheet.getCell(`${spanToCellLetter}${worksheet.rowCount}`).value = this.numberFormatter(totalExpense)
        worksheet.getCell(`${spanToCellLetter}${worksheet.rowCount}`).font = { size: 12, name: 'Arial', bold: true, color: 'red'}
        worksheet.getCell(`${spanToCellLetter}${worksheet.rowCount}`).alignment = { horizontal: 'right', vertical: 'middle', wrapText: true }
        worksheet.getCell(`${spanToCellLetter}${worksheet.rowCount}`).border = border

        const netIncomeRow = totalNetIncome.compared_income ? totalNetIncome.compared_income.reduce((prevValue, initialValue) => {
            return {
                ...prevValue,
                [initialValue.month]: 
                    Math.sign(initialValue.amount) === -1 ? 
                    `(${this.numberFormatter(Math.abs(initialValue.amount))})` : 
                    this.numberFormatter(initialValue.amount)
            }
        }, {}) : {}

        const netIncomeLastRow = worksheet.addRow(netIncomeRow)
        netIncomeLastRow.eachCell((cell) => {
            cell.border = border
            cell.font = { size: 12, name: 'Arial', bold: true, color: { argb: 'FFFF0000' }}
            cell.alignment = { horizontal: 'right', vertical: 'middle', wrapText: true }
        })

        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`)
        worksheet.getCell(`A${worksheet.rowCount}`).value = "NET INCOME"
        worksheet.getCell(`A${worksheet.rowCount}`).font = { size: 12, name: 'Arial', bold: true, color: { argb: 'FFFF0000' }}
        worksheet.getCell(`A${worksheet.rowCount}`).alignment = { horizontal: 'right', vertical: 'middle', wrapText: true }
        worksheet.getCell(`A${worksheet.rowCount}`).border = border
        worksheet.getCell(`${spanToCellLetter}${worksheet.rowCount}`).value =
            Math.sign(totalNetIncome.net_income) === -1 ? 
            `(${this.numberFormatter(Math.abs(totalNetIncome.net_income))})` : 
            this.numberFormatter(totalNetIncome.net_income)
        worksheet.getCell(`${spanToCellLetter}${worksheet.rowCount}`).font = { size: 12, name: 'Arial', bold: true, color: { argb: 'FFFF0000' }}
        worksheet.getCell(`${spanToCellLetter}${worksheet.rowCount}`).alignment = { horizontal: 'right', vertical: 'middle', wrapText: true }
        worksheet.getCell(`${spanToCellLetter}${worksheet.rowCount}`).border = border

        // save to excel file
        workbook.xlsx
        .writeBuffer()
        .then((res) => {
        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const blob = new Blob([res], {
            type: fileType
        })
        saveAs(blob, `income_statements.xlsx`)
            this.setState({
                isOpen: true,
                message: 'File saved!',
                type: 'success'
            })
        })
        .catch(() => {
            this.setState({
                isOpen: true,
                message: 'Error occured while saving data..',
                type: 'error'
            })
        })
    }

    numberFormatter(value) {
        return value
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    transformDataToExcelFormat(data) {
        const excelFormat = data.map((incomeStatement) => {
            const { 
                code,
                name,
                balance,
                month_balance,
            } = incomeStatement

            return {
                code,
                name,
                monthBalance: month_balance,
                asOfDate: balance
            }
        })

        return excelFormat
    }

    handleXlsxDownloadClick() {
        const { incomeStatements } = this.state
        const revenue = incomeStatements.filter((incomeStatement) => incomeStatement.type === 'Caes::Revenue')
        const expense = incomeStatements.filter((incomeStatement) => incomeStatement.type === 'Caes::Expense')
        const transformedRevenue = this.transformDataToExcelFormat(
            this.state.includeZeroes ? 
            revenue :
            revenue.filter((incomeStatement) => incomeStatement.balance !== 0.0))
        const transformedExpense = this.transformDataToExcelFormat(
            this.state.includeZeroes ? 
            expense :
            expense.filter((incomeStatement) => incomeStatement.balance !== 0.0))
        this.exportToXlsx(transformedRevenue, transformedExpense)
    }

    render() {
        const print = 
            <ReactToPrint
            trigger={() => <IconButton color="primary"><PrintIcon /></IconButton>}
            onBeforeGetContent={this.changePrintLayout}
            onAfterPrint={this.changePrintLayout}
            content={() => this.componentRef}
            />
        

        const withoutZeroes = this.state.incomeStatements.filter(item => item.balance !== 0)
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <IncomeSearchForm
                                item={this.state.account}
                                branches={this.state.branches}
                                handleSelectChange={this.handleSelectChange}
                                months={this.state.months}
                                handleAutoComplete={this.handleAutoComplete}
                                search={this.handleSubmit}
                                clearSearch={this.clearSearch}
                                handleChange={this.handleInputChange}
                                handleKeyPress={this.handleKeyPress}
                            />
                            {
                                this.state.incomeStatements.length > 0 ? (
                                    <><Checkbox
                                        checked={this.state.includeZeroes}
                                        onChange={this.handleCheck}
                                    /><b>Include Zeroes</b></>) : null
                            }
                            <div className={this.state.forPrint?style.pdfTable:null}>
                                <IncomeStatementTable
                                    months={this.state.months}
                                    urlPrint={this.state.urlPrint}
                                    items={this.state.includeZeroes ? (this.state.incomeStatements) : withoutZeroes}
                                    model={this.state.model}
                                    headers={this.state.tableHead}
                                    icon={<PollOutlinedIcon fontSize="large" />}
                                    searchItem={this.state.account}
                                    notConsolidated={this.state.notConsolidated}
                                    ref={el => (this.componentRef = el)}
                                    print={print}
                                    forPrint={this.state.forPrint}
                                    withXlsxDownload={true}
                                    handleXlsxDownloadClick={this.handleXlsxDownloadClick}
                                />
                            </div>
                            <AlertMessage
                                handleCloseAlert={this.handleCloseAlert}
                                isOpen={this.state.isOpen}
                                type={this.state.type}
                                message={this.state.message}
                            />
                        </div>
                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
            </>
        )
    }
}

export default withRouter(IncomeStatements)

IncomeStatements.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
}
