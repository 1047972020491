// eslint-disable-next-line
import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import ProgressBar from "../../ProgressBar/ProgressBar";
import { withRouter } from 'react-router-dom'
import BalanceSheetsSearchForm from './BalanceSheetsSearchForm'
import BalanceSheetsTable from './BalanceSheetsTable'
import ListAltIcon from '@material-ui/icons/ListAlt';
import PrintIcon from '@material-ui/icons/Print'
import ReactToPrint from "react-to-print";
import { IconButton } from '@material-ui/core';
import * as Excel from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment';
import AlertMessage from '../../Notify/AlertMessage';

export const Component = withRouter(() => {

})

class BalanceSheets extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Balance Sheet',
            balanceSheets: [],
            balanceSheetMeta: {},
            searchParams: { is_posted: 'true' },
            title: '',
            load: false,
            branch: {},
            branches: [],
            income: {},
            urlPrint: {},
            forPrint: false,
            searchUrl: '/v1/accounts/balance_sheet',
            isOpen: false,
            message: '',
            type: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleAutoComplete = this.handleAutoComplete.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.changePrintLayout = this.changePrintLayout.bind(this)
        this.transformDataToExcelFormat = this.transformDataToExcelFormat.bind(this)
        this.exportToXlsx = this.exportToXlsx.bind(this)
        this.handleXlsxDownloadClick = this.handleXlsxDownloadClick.bind(this)
        this.numberFormatter = this.numberFormatter.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
    }

    componentDidMount() {
        var branches = []
        if (localStorage.getItem('branches')) {
            this.setState({ branches: JSON.parse(localStorage.getItem('branches')), load: true })
            branches = JSON.parse(localStorage.getItem('branches'))
        } else {
            axios({
                method: 'get',
                url: '/v1/branches',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ branches: resp.data })
                branches = resp.data
            })
        }
        var searchUrl = this.state.searchUrl + this.props.location.search
        var searchParams = { is_posted: 'true' }
        const params = new URLSearchParams(this.props.location.search)
        // This is to load all params based on URLSearchParams
        params.forEach(function (value, key) {
            if (key !== 'page') {
                if (key === 'branch_id' && value !== null && value !== undefined) {
                    if (branches.length > 0) {
                        branches.find(branch => { branch.id.toString() === value ? searchParams['branch'] = branch : null })
                    }
                } else {
                    searchParams[key] = value
                }

            }
        })

        this.setState({ searchParams: searchParams })
        this.loadBalanceSheets(searchUrl, this.props.location.search ? true : false)

    }

    loadBalanceSheets(url, searchLoad) {

        if (searchLoad) {
            axios({
                method: 'get',
                url: url,
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                this.setState({ balanceSheets: resp.data.accounts, load: true, urlPrint: resp.data.meta, 
                    balanceSheetMeta: resp.data.meta })
            }).catch((error) => {
                if (error.response) { this.setState({ load: true, isOpen: true, message: error.response.status + " Unexpected Problem Occurred", type: 'error' }) }
            })
        } else {
            this.setState({ load: true })
        }
    }

    handleSubmit() {
        const { history } = this.props
        var searchParams = this.getSearchParams().join("&")
        const params = new URLSearchParams()
        params.append("/search", searchParams)
        history.push({ search: searchParams })
        this.loadBalanceSheets([this.state.searchUrl, searchParams].join("?"), true, false)
    }

    getSearchParams() {
        var searchParams = []
        // This is to load all params based on searchParams state.
        Object.entries(this.state.searchParams).map(([key, value]) => {
            if (value != '' && value != 'all' && value != undefined && value != null) {
                if (key === "branch") {
                    searchParams.push(['branch_id', value.id].join("="))
                } else {
                    searchParams.push([key, value].join("="))
                }
            }
        })

        return searchParams
    }

    handleInputChange(e) {
        this.setState({ searchParams: { ...this.state.searchParams, [e.target.name]: e.target.value } })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit()
        }
    }

    clearSearch() {
        const { history } = this.props
        history.push({ search: '' })
        this.setState({ balanceSheets: [], urlPrint: {} })
        this.setState({ searchParams: { date_to_compare: '', as_of_date: '', is_posted: '', branch: {} } })
    }

    handleAutoComplete(event, values) {
        this.setState({
            searchParams: { ...this.state.searchParams, branch: values }
        })
    }

    changePrintLayout() {
        return new Promise((resolve) => {
            this.setState({ forPrint: !this.state.forPrint }, () => resolve());
        });
    }

    numberFormatter(value) {
        return value
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    handleCloseAlert() {
        this.setState({
            isOpen: false
        })
    }

    transformDataToExcelFormat(data) {
        const { as_of_date, date_to_compare } = this.state.searchParams
        const excelFormat = data.map((asset) => {
            const { code, name, balance, compared_balance } = asset

            return {
                accountNo: code,
                accountName: name,
                [as_of_date]: balance,
                ...(date_to_compare ?
                    {
                        [date_to_compare]: compared_balance
                    } :
                    null
                )
            }
        })

        return excelFormat
    }

    exportToXlsx(liability, asset, capital) {
        const { as_of_date, date_to_compare, branch = null, is_posted } = this.state.searchParams
        let totalLiability = 0, totalAsset = 0, totalCapital = 0
        let totalComparedLiability = 0, totalAssetCompared = 0, totalCapitalCompared = 0
        const border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        }

        const fontSize12B = {
            size: 12,
            bold: true
        }

        const workbook = new Excel.Workbook()
        const worksheet = workbook.addWorksheet('Balance Sheet Reports')
        
        worksheet.mergeCells(`A1:${date_to_compare ? 'D' : 'C'}1`)
        worksheet.getCell('A1').value = "Balance Sheet Reports"
        worksheet.mergeCells(`A2:${date_to_compare ? 'D' : 'C'}2`)
        worksheet.getCell('A2').value = `As of: ${moment(as_of_date).format("MMMM DD, YYYY")}`
        worksheet.mergeCells(`A3:${date_to_compare ? 'D' : 'C'}3`)
        worksheet.getCell('A3').value = `Branch: ${ branch ? branch.name ? branch.name : 'All' : 'All' }`
        worksheet.mergeCells(`A4:${date_to_compare ? 'D' : 'C'}4`)
        worksheet.getCell('A4').value = `Status: ${
            is_posted === 'true' ? 'Posted' : 
            is_posted === 'false' ? 'Not Posted' 
            : 'All'
        }`
        worksheet.eachRow({ includeEmpty: false }, (row) => {
            row.eachCell((cell) => {
                cell.font = {
                    size: 16,
                    bold: true
                }
                cell.alignment = {
                  horizontal: 'center'
                }
            })
        })
        worksheet.columns = date_to_compare ? [
            {
              key: 'accountNo',
              width: 20
            },
            {
              key: 'accountName',
              width: 50
            },
            {
              key: date_to_compare,
              width: 20
            },
            {
              key: as_of_date,
              width: 20
            }
        ] : [
            {
              key: 'accountNo',
              width: 20
            },
            {
              key: 'accountName',
              width: 50
            },
            {
              key: as_of_date,
              width: 20
            }
        ]
        
        worksheet.mergeCells(`A${worksheet.rowCount + 1}:${date_to_compare ? 'D' : 'C'}${worksheet.rowCount + 1}`)
        worksheet.getCell(`A${worksheet.rowCount}`).value = "Asset"
        worksheet.getCell(`A${worksheet.rowCount}`).font = {
            size: 14,
            bold: true
        }
        worksheet.getCell(`A${worksheet.rowCount}`).alignment = { horizontal: 'center' }
        worksheet.getCell(`A${worksheet.rowCount}`).border = border
        worksheet.getRow(worksheet.rowCount + 1).values = date_to_compare ? [
            'Account No',
            'Account Name',
            date_to_compare,
            as_of_date
        ] : [
            'Account No',
            'Account Name',
            as_of_date
        ]

        worksheet.getRow(worksheet.rowCount).alignment = { horizontal: 'center' }
        worksheet.getRow(worksheet.rowCount).font = { size: 12 }
        worksheet.getRow(worksheet.rowCount).eachCell((cell) => cell.border = border )
        asset
        .sort((a, b) => {
            if (a.accountNo.toLowerCase() < b.accountNo.toLowerCase()) return -1
            if (a.accountNo.toLowerCase() > b.accountNo.toLowerCase()) return 1
            return 0
        })
        .forEach((data) => {
            totalAsset += Number(data[as_of_date])
            if(date_to_compare) totalAssetCompared += Number(data[date_to_compare])
            Object.entries(data).forEach(([key, value]) => {
                if(key === as_of_date || key === date_to_compare) {
                    data[key] = Math.sign(value) === -1 ? `(${this.numberFormatter(Math.abs(value))})` : this.numberFormatter(value)
                }
            })
            const row = worksheet.addRow(data)
            row.eachCell((cell, colNumber) => {
                if (colNumber === 1) cell.alignment = { horizontal: 'center' }
                if (colNumber === 3 || colNumber === 4) cell.alignment = { horizontal: 'right' }
                cell.border = border
                cell.font = { size: 12 }
            })
        })
        worksheet.mergeCells(`A${worksheet.rowCount + 1}:B${worksheet.rowCount + 1}`)
        worksheet.getCell(`B${worksheet.rowCount}`).value = "Total Asset"
        worksheet.getCell(`B${worksheet.rowCount}`).font = fontSize12B
        worksheet.getCell(`B${worksheet.rowCount}`).alignment = { horizontal: 'center' }
        worksheet.getCell(`B${worksheet.rowCount}`).border = border
        if(date_to_compare) {
            worksheet.getCell(`C${worksheet.rowCount}`).value =
                Math.sign(totalAssetCompared) === -1 ? `(${this.numberFormatter(Math.abs(totalAssetCompared))})` : this.numberFormatter(totalAssetCompared)
            worksheet.getCell(`C${worksheet.rowCount}`).font = fontSize12B
            worksheet.getCell(`C${worksheet.rowCount}`).alignment = { horizontal: 'right' }
            worksheet.getCell(`C${worksheet.rowCount}`).border = border
        }
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).value =
            Math.sign(totalAsset) === -1 ? `(${this.numberFormatter(Math.abs(totalAsset))})` : this.numberFormatter(totalAsset)
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).font = fontSize12B
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).alignment = { horizontal: 'right' }
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).border = border
        
        worksheet.mergeCells(`A${worksheet.rowCount + 1}:${date_to_compare ? 'D' : 'C'}${worksheet.rowCount + 1}`)
        worksheet.getCell(`A${worksheet.rowCount}`).value = "Liability"
        worksheet.getCell(`A${worksheet.rowCount}`).font = {
            size: 14,
            bold: true
        }
        worksheet.getCell(`A${worksheet.rowCount}`).alignment = { horizontal: 'center' }
        worksheet.getCell(`A${worksheet.rowCount}`).border = border
        worksheet.getRow(worksheet.rowCount + 1).values = date_to_compare ? [
            'Account No',
            'Account Name',
            date_to_compare,
            as_of_date
        ] : [
            'Account No',
            'Account Name',
            as_of_date
        ]
        worksheet.getRow(worksheet.rowCount).alignment = { horizontal: 'center' }
        worksheet.getRow(worksheet.rowCount).font = { size: 12 }
        worksheet.getRow(worksheet.rowCount).eachCell((cell) => cell.border = border )
        liability
        .sort((a, b) => {
            if (a.accountNo.toLowerCase() < b.accountNo.toLowerCase()) return -1
            if (a.accountNo.toLowerCase() > b.accountNo.toLowerCase()) return 1
            return 0
        })
        .forEach((data) => {
            totalLiability += Number(data[as_of_date])
            if(date_to_compare) totalComparedLiability += Number(data[date_to_compare])
            Object.entries(data).forEach(([key, value]) => {
                if(key === as_of_date || key === date_to_compare) {
                    data[key] = Math.sign(value) === -1 ? `(${this.numberFormatter(Math.abs(value))})` : this.numberFormatter(value)
                }
            })
            const row = worksheet.addRow(data)
            row.eachCell((cell, colNumber) => {
                if (colNumber === 1) cell.alignment = { horizontal: 'center' }
                if (colNumber === 3 || colNumber === 4) cell.alignment = { horizontal: 'right' }
                cell.border = border
                cell.font = { size: 12 }
            })
        })
        worksheet.mergeCells(`A${worksheet.rowCount + 1}:B${worksheet.rowCount + 1}`)
        worksheet.getCell(`B${worksheet.rowCount}`).value = "Total Liability"
        worksheet.getCell(`B${worksheet.rowCount}`).font = fontSize12B
        worksheet.getCell(`B${worksheet.rowCount}`).alignment = { horizontal: 'center' }
        worksheet.getCell(`B${worksheet.rowCount}`).border = border
        if(date_to_compare) {
            worksheet.getCell(`C${worksheet.rowCount}`).value =
                Math.sign(totalComparedLiability) === -1 ? `(${this.numberFormatter(Math.abs(totalComparedLiability))})` : this.numberFormatter(totalComparedLiability)
            worksheet.getCell(`C${worksheet.rowCount}`).font = fontSize12B
            worksheet.getCell(`C${worksheet.rowCount}`).alignment = { horizontal: 'right' }
            worksheet.getCell(`C${worksheet.rowCount}`).border = border
        }
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).value =
            Math.sign(totalLiability) === -1 ? `(${this.numberFormatter(Math.abs(totalLiability))})` : this.numberFormatter(totalLiability)
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).font = fontSize12B
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).alignment = { horizontal: 'right' }
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).border = border

        worksheet.mergeCells(`A${worksheet.rowCount + 1}:${date_to_compare ? 'D' : 'C'}${worksheet.rowCount + 1}`)
        worksheet.getCell(`A${worksheet.rowCount}`).value = "Capital"
        worksheet.getCell(`A${worksheet.rowCount}`).font = {
            size: 14,
            bold: true
        }
        worksheet.getCell(`A${worksheet.rowCount}`).alignment = { horizontal: 'center' }
        worksheet.getCell(`A${worksheet.rowCount}`).border = border
        worksheet.getRow(worksheet.rowCount + 1).values = date_to_compare ? [
            'Account No',
            'Account Name',
            date_to_compare,
            as_of_date
        ] : [
            'Account No',
            'Account Name',
            as_of_date
        ]
        worksheet.getRow(worksheet.rowCount).alignment = { horizontal: 'center' }
        worksheet.getRow(worksheet.rowCount).font = { size: 12 }
        worksheet.getRow(worksheet.rowCount).eachCell((cell) => cell.border = border )
        capital
        .sort((a, b) => {
            if (a.accountNo.toLowerCase() < b.accountNo.toLowerCase()) return -1
            if (a.accountNo.toLowerCase() > b.accountNo.toLowerCase()) return 1
            return 0
        })
        .forEach((data) => {
            totalCapital += Number(data[as_of_date])
            if(date_to_compare) totalCapitalCompared += Number(data[date_to_compare])
            Object.entries(data).forEach(([key, value]) => {
                if(key === as_of_date || key === date_to_compare) {
                    data[key] = Math.sign(value) === -1 ? `(${this.numberFormatter(Math.abs(value))})` : this.numberFormatter(value)
                }
            })
            const row = worksheet.addRow(data)
            row.eachCell((cell, colNumber) => {
                if (colNumber === 1) cell.alignment = { horizontal: 'center' }
                if (colNumber === 3 || colNumber === 4) cell.alignment = { horizontal: 'right' }
                cell.border = border
                cell.font = { size: 12 }
            })
        })

        worksheet.getCell(`B${worksheet.rowCount + 1}`).value = "Net Income"
        worksheet.getCell(`B${worksheet.rowCount}`).font = { size: 12 }
        worksheet.getRow(worksheet.rowCount).eachCell((cell) => cell.border = border )
        if(date_to_compare) {
            worksheet.getCell(`C${worksheet.rowCount}`).value = this.state.balanceSheetMeta.compared_net_income ? 
            Math.sign(this.state.balanceSheetMeta.compared_net_income) === -1 ? 
                `(${this.numberFormatter(Math.abs(this.state.balanceSheetMeta.compared_net_income))})` :
                this.numberFormatter(this.state.balanceSheetMeta.compared_net_income) :
            "0.00"
            worksheet.getCell(`C${worksheet.rowCount}`).font = { size: 12 }
            worksheet.getCell(`C${worksheet.rowCount}`).alignment = { horizontal: 'right' }
            worksheet.getCell(`C${worksheet.rowCount}`).border = border
        }
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).value = this.state.balanceSheetMeta.net_income ? 
            Math.sign(this.state.balanceSheetMeta.net_income) === -1 ? 
                `(${this.numberFormatter(Math.abs(this.state.balanceSheetMeta.net_income))})` :
                this.numberFormatter(this.state.balanceSheetMeta.net_income) :
            "0.00"
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).font = { size: 12 }
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).alignment = { horizontal: 'right' }
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).border = border

        totalCapital = totalCapital + this.state.balanceSheetMeta.net_income
        totalCapitalCompared = totalCapitalCompared + this.state.balanceSheetMeta.compared_net_income
        worksheet.mergeCells(`A${worksheet.rowCount + 1}:B${worksheet.rowCount + 1}`)
        worksheet.getCell(`B${worksheet.rowCount}`).value = "Total Capital"
        worksheet.getCell(`B${worksheet.rowCount}`).font = fontSize12B
        worksheet.getCell(`B${worksheet.rowCount}`).alignment = { horizontal: 'center' }
        worksheet.getCell(`B${worksheet.rowCount}`).border = border
        if(date_to_compare) {
            worksheet.getCell(`C${worksheet.rowCount}`).value = Math.sign(totalCapitalCompared) === -1 ? 
            `(${this.numberFormatter(Math.abs(totalCapitalCompared))})` :
            this.numberFormatter(totalCapitalCompared)
            worksheet.getCell(`C${worksheet.rowCount}`).font = fontSize12B
            worksheet.getCell(`C${worksheet.rowCount}`).alignment = { horizontal: 'right' }
            worksheet.getCell(`C${worksheet.rowCount}`).border = border
        }
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).value = Math.sign(totalCapital) === -1 ? 
            `(${this.numberFormatter(Math.abs(totalCapital))})` :
            this.numberFormatter(totalCapital)
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).font = fontSize12B
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).alignment = { horizontal: 'right' }
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).border = border
        
        worksheet.mergeCells(`A${worksheet.rowCount + 1}:B${worksheet.rowCount + 1}`)
        worksheet.getCell(`B${worksheet.rowCount}`).value = "Total Liability and Capital"
        worksheet.getCell(`B${worksheet.rowCount}`).font = fontSize12B
        worksheet.getCell(`B${worksheet.rowCount}`).alignment = { horizontal: 'center' }
        worksheet.getCell(`B${worksheet.rowCount}`).border = border
        if(date_to_compare) {
            worksheet.getCell(`C${worksheet.rowCount}`).value =
            Math.sign((totalCapitalCompared + totalComparedLiability)) === -1 ? `(${this.numberFormatter(Math.abs((totalCapitalCompared + totalComparedLiability)))})` : this.numberFormatter(totalCapitalCompared + totalComparedLiability)
            worksheet.getCell(`C${worksheet.rowCount}`).font = fontSize12B
            worksheet.getCell(`C${worksheet.rowCount}`).alignment = { horizontal: 'right' }
            worksheet.getCell(`C${worksheet.rowCount}`).border = border
        }
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).value =
            Math.sign((totalCapital + totalLiability)) === -1 ? `(${this.numberFormatter(Math.abs((totalCapital + totalLiability)))})` : this.numberFormatter(totalCapital + totalLiability)
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).font = fontSize12B
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).alignment = { horizontal: 'right' }
        worksheet.getCell(`${date_to_compare ? 'D' : 'C'}${worksheet.rowCount}`).border = border

        // save to excel file
        workbook.xlsx
        .writeBuffer()
        .then((res) => {
          const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          const blob = new Blob([res], {
            type: fileType
          })
          saveAs(blob, `balance_sheets.xlsx`)
          this.setState({
            isOpen: true,
            message: 'File saved!',
            type: 'success'
          })
        })
        .catch(() => {
          this.setState({
            isOpen: true,
            message: 'Error occured while saving data..',
            type: 'error'
          })
        })
    }

    handleXlsxDownloadClick() {
        const asset = this.state.balanceSheets.filter((balanceSheet) => balanceSheet.type === "Caes::Asset")
        const liability = this.state.balanceSheets.filter((balanceSheet) => balanceSheet.type === "Caes::Liability")
        const capital = this.state.balanceSheets.filter((balanceSheet) => balanceSheet.type === "Caes::Capital")
        const transformedAsset = this.transformDataToExcelFormat(asset)
        const transformedLiability = this.transformDataToExcelFormat(liability)
        const transformedCapital = this.transformDataToExcelFormat(capital)
        this.exportToXlsx(transformedLiability, transformedAsset, transformedCapital)
    }

    render() {
        const printPdf = <ReactToPrint
            trigger={() => {
                return <IconButton color="primary"><PrintIcon /></IconButton>
            }}
            onBeforeGetContent={this.changePrintLayout}
            onAfterPrint={this.changePrintLayout}
            content={() => this.componentRef}
        />
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <BalanceSheetsSearchForm
                                item={this.state.searchParams}
                                branches={this.state.branches}
                                search={this.handleSubmit}
                                clearSearch={this.clearSearch}
                                handleChange={this.handleInputChange}
                                handleKeyPress={this.handleKeyPress}
                                handleAutoComplete={this.handleAutoComplete}

                            />
                            <BalanceSheetsTable
                                searchItem={this.state.searchParams}
                                items={this.state.balanceSheets}
                                model={this.state.model}
                                headers={this.state.tableHead}
                                icon={<ListAltIcon fontSize="large" />}
                                urlPrint={this.state.urlPrint}
                                forPrint={this.state.forPrint}
                                printPdf={printPdf}
                                ref={el => (this.componentRef = el)}
                                withXlsxDownload={true}
                                handleXlsxDownloadClick={this.handleXlsxDownloadClick}
                            />

                        </div>
                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
                <AlertMessage
                    handleCloseAlert={this.handleCloseAlert}
                    isOpen={this.state.isOpen}
                    type={this.state.type}
                    message={this.state.message}
                />
            </>
        )
    }
}

export default withRouter(BalanceSheets)

BalanceSheets.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
}
