import React from 'react'
import styles from './Profile.module.css'

import PropTypes from 'prop-types'

import {
  Button
} from '@material-ui/core'
import Avatar from "@material-ui/core/Avatar";

class ProfileForm extends React.Component {
  render () {
    return (
      <div>
        <form>
          <Avatar src={this.props.item.avatar_url} className={styles.profilePicAvatarForm}>
          </Avatar>

          <input
            accept="image/*"
            className={styles.input}
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            name="avatar"
            type="file"
            onChange={(e) => this.props.onFileChange(e)}
          />
          <div className={styles.uploadInput} >
          <label htmlFor="raised-button-file">
            <Button variant="raised" component="span" className={styles.uploadButton}>
              Upload Photo
            </Button>
            <span className={styles.labelAvatar}>{this.props.item.avatar? this.props.item.avatar.name: null }</span>
          </label>
          </div>
        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined" id="profile-submit" disabled={!this.props.item.avatar } color="primary" onClick={() => this.props.handleSubmitAvatar()} >Save</Button>
        </div>
      </div>
    )
  }
}
export default ProfileForm

ProfileForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  handleSubmitAvatar: PropTypes.func,
  onFileChange: PropTypes.func,
  error: PropTypes.object
}
