import axios from 'axios';

export function setupAxios(username = '') {
    let baseUrl = '';
    const lexUsers = [
      "rcbaquiano",
      "hmjandusay",
      "adbondoc",
      "jobaqs",
      "gdpangan",
      "cavpalattao",
      "alysonn",
      "kmesan"
    ]

    if (/demo/.test(username)) {
      baseUrl = 'https://demo-caes-api';
    } else {
      switch (username) {
        case 'arielagni':
        case 'katunying':
        case 'jasmin.prejillana':
          baseUrl = 'api_domain';
          break;
        case 'jasmin.prejillana@fhaoassociates.com':
          baseUrl = 'https://fhao-caes-api2';
          break;
        case 'remelyn.talosig@fhaoassociates.com':
          baseUrl = 'https://fhao-caes-api3';
          break;
        case 'marylin.arcilla@fhaoassociates.com':
          baseUrl = 'https://fhao-caes-api4';
          break;
        case 'admin@louder.ph':
          baseUrl = 'https://fhao-caes-api5';
          break;
        case 'estoninateddy@gmail.com':
          baseUrl = 'https://fhao-caes-api6';
          break;
        case 'mguzman@patleacarl-chem.com.ph':
          baseUrl = 'https://fhao-caes-api7';
          break;
        case 'cynthia.honorio@perceptions-inc.com':
          baseUrl = 'https://fhao-caes-api9';
          break;
        case 'info@fhaoassociates.com':
          baseUrl = 'https://fhao-caes-api10';
          break;
        case 'ecd_admin_user':
          baseUrl = 'https://fhao-caes-api11';
          break;
        default:
          baseUrl = lexUsers.includes(username) ? 
            'https://lex-caes-api' : 
            'https://caes-tenacy-api'
          break;
      }
    }

    axios.defaults.baseURL = 
      baseUrl === 'api_domain' ?
      process.env.REACT_APP_API_DOMAIN : 
      `${baseUrl}${process.env.REACT_APP_DOMAIN}`;
}
