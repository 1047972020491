import React from 'react'
import styles from './Billing.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  Grid, IconButton, Table, TableHead, TableRow, TableCell, TableBody
} from '@material-ui/core'
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import CheckVoucherContentForm from "./CheckVoucherContentForm"
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import converter from "number-to-words";
import NumberFormat from 'react-number-format';
import DeleteIcon from '@material-ui/icons/Delete'
class BillingForm extends React.Component {
  getTotalDebit() {
		var totalDebit = 0
		if (this.props.checkVoucherContent.length > 0) {
			totalDebit = this.props.checkVoucherContent.reduce((a, b) => parseFloat(parseFloat(a + parseFloat(b.amount_debit)).toFixed(2)), 0)
		}
		return totalDebit
	}

	getTotalCredit() {
		var totalCredit = 0
		if (this.props.checkVoucherContent.length > 0) {
			totalCredit = this.props.checkVoucherContent.reduce((a, b) => parseFloat(parseFloat(a + parseFloat(b.amount_credit)).toFixed(2)), 0)
		}
		return totalCredit
	}
	getBalance() {
		var balance = 0
		if (this.props.checkVoucherContent.length > 0) {
			balance = parseFloat(this.getTotalCredit() - this.getTotalDebit()).toFixed(2)
		}
		return balance
	}

  getTotalPaymentAmount(){
    var totalAmt = 0
    if (this.props.paymentAccounts.length > 0) {
      totalAmt = this.props.paymentAccounts.reduce((a, b) => parseFloat(parseFloat(a + parseFloat(b.amount_credit)).toFixed(2)), 0)
    }
    
    return totalAmt
  }

  checkPayment(){
    var pay = false
    if(this.props.immediatePayment){
      if(this.getTotalPaymentAmount() !== this.props.item.amount){
        pay = true
      }
    }
    return pay
  }

  paymentBalance(){
    var balance = 0
    if (this.props.checkVoucherContent.length > 0) {
      balance = parseFloat(this.props.item.amount - this.getTotalPaymentAmount()).toFixed(2)
    }
    return balance
  }

  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Autocomplete
              id="branch"
              name="branch"
              fullWidth
              className={styles.textFields}
              size="small"
              options={(this.props.branches).sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}
              loading={this.props.branchAutoCompleteLoading}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleAutocomplete}
              onInputChange={(event, value, reason) => this.props.handleAutocompleteOnInputChange(event, value, reason)}
              value={this.props.branch}
              closeIcon={<CloseIcon fontSize="small" id="clear_branch_autocomplete" />}
              renderInput={(params) => (
                <TextField {...params} name="branch" variant="outlined" label="Branch Name" />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              required
              name="reference_no"
              fullWidth="true"
              label="Reference No."
              onChange={this.props.handleInputChange}
              value={this.props.item.reference_no}
              id="bill-reference_no"
            />
            <div className={styles.error}>{this.props.error.reference_no ? 'Reference number ' + this.props.error.reference_no : null}</div>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              name="payee"
              id="payee"
              key="payee"
              size="small"
              className={styles.textFields}
              loading={this.props.payeesAutoCompleteLoading}
              options={(this.props.payees).sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.id === value}
              onChange={this.props.handleAutocomplete}
              onInputChange={(event, value, reason) => this.props.handleAutocompleteOnInputChange(event, value, reason)}
              fullWidth={true}
              value={this.props.selectedPayee}
              closeIcon={<CloseIcon fontSize="small" id="clear_payee_autocomplete" />}
              renderInput={(params) => <TextField {...params} label="Payee" name="payee" variant="outlined" required />}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="filed_at"
              fullWidth="true"
              label="Filed at"
              type="date"
              onChange={this.props.handleInputChange}
              value={this.props.item.filed_at}
              id="bill-date"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              type="date"
              name="due_at"
              fullWidth="true"
              label="Due Date"
              onChange={this.props.handleInputChange}
              value={this.props.item.due_at}
              id="bill-due_date"
              required
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <CurrencyTextField
              label="Amount"
              name="amount"
              value={this.props.item.amount}
              currencySymbol=""
              fullWidth
              size="small"
              outputFormat="number"
              variant="outlined"
              className={styles.textFields}
              decimalCharacter="."
              digitGroupSeparator=","
              onChange={(event, value) => this.props.amountChange(value)}
              required
            />

            <div className={styles.amountInWordForm}>
              <div><b> &nbsp;&nbsp;  Amount in Words </b></div>
              <div><b>
                <i>

                  {this.props.item.amount > 0 ?
                    (
                      <div>
                        {`:`}&nbsp;&nbsp;
                        {`${converter.toWords(this.props.item.amount.toString().split('.')[0])}`}
                        {/*+*/}
                        {this.props.item.amount.toString().split('.').length === 2 && this.props.item.amount.toString().split('.')[1].toString !== null ?
                          (
                            <span>
                              <span className={styles.wordAnd}> and </span>
                              {this.props.item.amount.toString().split('.')[1]}
                              {(this.props.item.amount.toString().split('.')[1].length === 1 ? ('0') : ('')).toString()}
                              /100 Pesos Only
                            </span>
                          ) : <span><span className={styles.wordAnd}> and </span> 00/100 Pesos Only</span>
                        }
                      </div>
                    )
                    : null}
                </i>
              </b>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} className={styles.expenseBody}>
            <div className={styles.autoCompleteField}>
              <Autocomplete
                value={this.props.expenseType}
                id="expenseType"
                name="expenseType"
                fullWidth
                size="small"
                options={(this.props.expenseTypes).sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                })}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocomplete}
                renderInput={(params) => (
                  <TextField {...params} name="expenseType" variant="outlined" label="Expense Type" />
                )}
              />
            </div>
            <div className={styles.switchBody}>
              <div>
                <FormControlLabel
                  control={<Switch
                    size="medium"
                    checked={this.props.withInputTax}
                    disabled={!this.props.item.amount > 0}
                    onChange={this.props.handleInputTax}
                    name="with_input_vat" />}
                  label="Input VAT"
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Switch
                    size="medium"
                    checked={this.props.item.taxable}
                    onChange={this.props.handleSwitch}
                    disabled={this.props.expenseType === undefined || this.props.expenseType.multiplier === undefined || parseFloat(this.props.expenseType.multiplier) <= 0}
                    name="taxable" />}
                  label="Tax Expandable"
                />
              </div>
              <div>
                <FormControlLabel
                  control={<Switch
                    size="medium"
                    checked={this.props.immediatePayment}
                    onChange={this.props.handleImmediatePayment}
                    disabled={!this.props.selectedPayee || this.props.selectedPayee===null||!this.props.selectedPayee.id}
                    name="immediatePayment" />}
                  label="Immediate Payment"
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="project_name"
              fullWidth="true"
              label="Project Name"
              onChange={this.props.handleInputChange}
              value={this.props.item.project_name || ''}
              id="project_name"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="remarks"
              fullWidth="true"
              label="Particulars"
              required
              onChange={this.props.handleInputChange}
              value={this.props.item.remarks}
              id="bill-remarks"
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
        </Grid>
        {
          this.props.immediatePayment ? (
            <>
              <div className={styles.headerContent}>
                <div> Payment Account(s)</div>
                <div> Balance: {this.paymentBalance()}</div>
                
                <IconButton
                  variant="addContent"
                  name="add_content"
                  disabled={!this.props.selectedPayee}
                  onClick={this.props.addPaymentAccount}
                >
                  <AddCircleOutlineIcon fontSize="medium" />
                </IconButton>
              </div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Account Code</b>
                    </TableCell>
                    <TableCell>
                      <b>Account Name</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>Amount</b>
                    </TableCell>
                    <TableCell align="center">
                      <b>Action</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={styles.tableBody} name="check_content">
                  {this.props.paymentAccounts.map((val, idx) => (
                    <TableRow key={`check_content-${idx}`}>
                      <TableCell className={styles.codeField}>
                        <label> {this.props.paymentAccounts[idx].account_code}</label>
                      </TableCell>
                      <TableCell className={styles.accountWidth}>
                        <Autocomplete
                          value={this.props.paymentAccounts[idx].account}
                          fullWidth
                          size="small"
                          className={styles.checkAccount}
                          options={(this.props.accounts).sort(function (a, b) {
                            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                            return 0;
                          })}
                          getOptionLabel={(option) => option.name || ''}
                          getOptionSelected={(option, value) => option.name === value.name}
                          onChange={(event, value) => this.props.handlePaymentAccount(idx, value)}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Payment Account" />
                          )}
                        />
                      </TableCell>
                      <TableCell className={styles.debitCreditField}>
                        <NumberFormat
                          customInput={TextField}
                          autoComplete="off"
                          variant="outlined"
                          className={styles.amountBody}
                          size="small"
                          fullWidth
                          thousandSeparator={true}
                          onValueChange={(event) => this.props.handlePaymentAmt(event, idx, 'amount_credit')}
                          value={this.props.paymentAccounts[idx].amount_credit}
                          decimalSeparator="."
                          fixedDecimalScale={true}
                        />
                      </TableCell>
                      <TableCell align="center"><IconButton onClick={()=>this.props.removePaymentAccount(idx)}><DeleteIcon color="secondary" /></IconButton></TableCell>
                    </TableRow>

                  ))}

                  <TableRow>
                    
                    <TableCell></TableCell>
                    <TableCell align="right"><b>Total :</b></TableCell>
                    <TableCell align="right" className={styles.amountTotal}>&#8369; {this.getTotalPaymentAmount().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>

          ) : null
        }
        <>
          <div className={styles.headerContent}>
            <div> Bill Details</div>
            <div> Balance: {this.getBalance()}</div>

            <IconButton
              variant="addContent"
              name="add_content"
              disabled={!this.props.selectedPayee}
              onClick={this.props.addCheckVoucherContent}
            >
              <AddCircleOutlineIcon fontSize="medium" />
            </IconButton>
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Account Code</b>
                </TableCell>
                <TableCell>
                  <b>Account Name</b>
                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell align="right">
                  <b>Debit</b>
                </TableCell>
                <TableCell align="right">
                  <b>Credit</b>
                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody className={styles.tableBody} name="check_content">
              {this.props.checkVoucherContent.map((val, idx) => (
                <CheckVoucherContentForm
                  key={`check_content-${idx}`}
                  idx={idx}
                  name={`account_content_${idx}`}
                  handleCheckVoucherContentChange={this.props.handleCheckVoucherContentChange}
                  removeCheckVoucherContent={this.props.removeCheckVoucherContent}
                  accounts={this.props.accounts}
                  handleAutocomplete={this.props.handleContentAutocomplete}
                  checkVoucherContent={this.props.checkVoucherContent}
                  handleCheckVoucherContentAmount={this.props.handleCheckVoucherContentAmount}
                  accountPayable={this.props.accountPayable}
                />

              ))}

              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right"><b>Total :</b></TableCell>
                <TableCell align="right" className={styles.amountTotal}>&#8369; {this.getTotalDebit().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                <TableCell align="right" className={styles.amountTotal}>&#8369; {this.getTotalCredit().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>

        <div className={styles.actionButton}>
          <a href="/bills/">
            <Button variant="outlined"> back </Button>
          </a>
          <Button variant="outlined" id="bill-submit" color="primary" name="bill-submit"
            disabled={this.getTotalCredit() !== this.getTotalDebit()
              || !this.props.branch
              || !this.props.branch.id
              || !this.props.selectedPayee
              || !this.props.selectedPayee.id
              || this.props.item.remarks === ""
              || this.props.item.amount === ""
              || this.props.item.due_at === ""
              || !this.props.checkVoucherContent.length > 0
              || this.checkPayment()
            }
            onClick={() => this.props.handleSubmit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default BillingForm

BillingForm.propTypes = {
  handleInputChange: PropTypes.func,
  item: PropTypes.object,
  expenseType: PropTypes.object,
  selectedPayee: PropTypes.object,
  expenseTypes: PropTypes.array,
  payees: PropTypes.array,
  payeesAutoCompleteLoading: PropTypes.bool,
  bankAutoCompleteLoading: PropTypes.bool,
  branchAutoCompleteLoading: PropTypes.bool,
  withInputTax: PropTypes.bool,
  handleAutocomplete: PropTypes.func,
  addCheckVoucherContent: PropTypes.func,
  handleAutocompleteOnInputChange: PropTypes.func,
  handleContentAutocomplete: PropTypes.func,
  handleCheckVoucherContentChange: PropTypes.func,
  removeCheckVoucherContent: PropTypes.func,
  handleCheckVoucherContentAmount: PropTypes.func,
  handleInputTax: PropTypes.func,
  handleSwitch: PropTypes.func,
  openTermsForm: PropTypes.func,
  checkVoucherContent: PropTypes.array,
  amountChange: PropTypes.array,
  branches: PropTypes.array,
  branch: PropTypes.object,
  bank: PropTypes.object,
  accountPayable: PropTypes.object,
  banks: PropTypes.array,
  accounts: PropTypes.array,
  paymentTerms: PropTypes.array,
  error: PropTypes.object,
  handleSubmit: PropTypes.func,
  paymentAccounts: PropTypes.array,
  handlePaymentAccount: PropTypes.func,
  handlePaymentAmt: PropTypes.func,
  immediatePayment: PropTypes.bool,
  handleImmediatePayment: PropTypes.func,
  addPaymentAccount: PropTypes.func,
  removePaymentAccount: PropTypes.func
}
