import React from 'react'

import PropTypes from 'prop-types'

import { TextField, IconButton, TableCell, TableRow } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import styles from './Billing.module.css'
import NumberFormat from 'react-number-format'

class CheckVoucherContentForm extends React.Component {

  render() {
    var index = this.props.checkVoucherContent.indexOf(this.props.checkVoucherContent.filter(item=>item.account_code===this.props.accountPayable.account_code)[0])
    const accountNameId = `account_name_${this.props.idx}`
    const accountCreditId = `account_credit_${this.props.idx}`
    const accountDebitId = `account_debit_${this.props.idx}`
    const deleteButton = `delete_account_${this.props.idx}`
    return (
      <>
        <TableRow key={`check_content-${this.props.idx}`}>
          <TableCell className={styles.codeField}>
            <label> {this.props.checkVoucherContent[this.props.idx].account_code}</label>
          </TableCell>
          <TableCell className={styles.accountWidth}>
            <Autocomplete
              value={this.props.checkVoucherContent[this.props.idx].account}
              id={accountNameId}
              name={accountNameId}
              fullWidth
              size="small"
              className={styles.checkAccount}
              options={(this.props.accounts).sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
              })}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={(event, value) => this.props.handleAutocomplete(this.props.idx, value)}
              renderInput={(params) => (
                <TextField {...params} name={accountNameId} variant="outlined" label="Account" />
              )}
            />
          </TableCell>
          <TableCell>
            </TableCell>
          <TableCell className={styles.debitCreditField}>
            <NumberFormat
              customInput={TextField}
              autoComplete="off"
              variant="outlined"
              className={styles.amountBody}
              size="small"
              name={accountDebitId}
              fullWidth

              thousandSeparator={true}
              onValueChange={(event) => this.props.handleCheckVoucherContentAmount(event, this.props.idx, 'amount_debit')}
              value={this.props.checkVoucherContent[this.props.idx].amount_debit}
              decimalSeparator="."
              fixedDecimalScale={true}

            />
          </TableCell>
          <TableCell  className={styles.debitCreditField}>
            <NumberFormat
              customInput={TextField}
              autoComplete="off"
              variant="outlined"
              className={styles.amountBody}
              size="small"
              name={accountCreditId}
              fullWidth

              thousandSeparator={true}
              onValueChange={(event) => this.props.handleCheckVoucherContentAmount(event, this.props.idx, 'amount_credit')}
              value={this.props.checkVoucherContent[this.props.idx].amount_credit}
              decimalSeparator="."
              fixedDecimalScale={true}
            />
          </TableCell>
          <TableCell
            className={styles.actionButtonHoverable}
            align="right">
            <IconButton
              color="secondary"
              name={deleteButton}
              classname={index===this.props.idx ? styles.displayNoneDelete : styles.DisplayDelete}
              onClick={() => this.props.removeCheckVoucherContent(this.props.idx, this.props.checkVoucherContent[this.props.idx])}>
              <DeleteForeverIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      </>
    )
  }
}

export default CheckVoucherContentForm

CheckVoucherContentForm.propTypes = {
  idx: PropTypes.number,
  accounts: PropTypes.array,
  checkVoucherContent: PropTypes.array,
  handleCheckVoucherContentChange: PropTypes.func,
  removeCheckVoucherContent: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleCheckVoucherContentAmount: PropTypes.func,
  accountPayable: PropTypes.object,
}
