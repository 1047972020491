import React from 'react'
import styles from './TrialBalances.module.css'

import PropTypes from 'prop-types'

import {
    TextField,
    Button,
    MenuItem,
    Grid
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

class TrialBalanceSearchForm extends React.Component {
    render() {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <TextField
                            type="date"
                            autoComplete="off"
                            className={styles.textFields}
                            variant="outlined"
                            size="small"
                            name="as_of_date"
                            fullWidth={true}
                            label="As of Date"
                            onChange={this.props.handleChange}
                            onKeyPress={this.props.handleKeyPress}
                            value={this.props.item.as_of_date}
                            id="trialBalance-as_of_date"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            value={this.props.item.branch}
                            id="branch"
                            name="branch"
                            fullWidth
                            size="small"
                            className={styles.autoCompleteField}
                            options={(this.props.branches).sort(function (a, b) {
                                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                return 0;
                            })}
                            getOptionLabel={(option) => option.name || ''}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={this.props.handleAutoComplete}
                            renderInput={(params) => (
                                <TextField {...params} name="branch" variant="outlined" label="Branch" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            autoComplete="off"
                            select
                            className={styles.textFields}
                            variant="outlined"
                            size="small"
                            name="status"
                            fullWidth={true}
                            label="Status"
                            onChange={this.props.handleChange}
                            value={this.props.item.status || ''}
                            id="trialBalance-classification"
                        >
                            <MenuItem value ="all">All</MenuItem>
                            <MenuItem value="true">Posted</MenuItem>
                            <MenuItem value="false">Not Posted</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <div className={styles.actionButton}>
                    <Button variant="outlined" id="trialBalance-search" disabled={!this.props.item.as_of_date} color="primary" onClick={() => this.props.search()} >Search</Button>
                    <Button variant="outlined" id="trialBalance-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
                </div>

            </div>
        )
    }
}
export default TrialBalanceSearchForm

TrialBalanceSearchForm.propTypes = {
    handleChange: PropTypes.func,
    item: PropTypes.object,
    branches: PropTypes.array,
    search: PropTypes.func,
    clearSearch: PropTypes.func,
    handleKeyPress: PropTypes.func,
    handleAutoComplete: PropTypes.func
}
