import React from 'react'

import PropTypes from 'prop-types'

import {TableCell, TableRow, IconButton} from '@material-ui/core'
import moment from 'moment'
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete'
import styles from './CheckVoucher.module.css'

class BillsForm extends React.Component {
  render() {
    return (
      <>
        <TableRow className={styles.tableBody}>
          <TableCell className={styles.refNo}>
            {this.props.selectedBills[this.props.idx].reference_no}
          </TableCell>
          <TableCell className={styles.small}>
            {moment(this.props.selectedBills[this.props.idx].due_at).format('MM/DD/YY')}
          </TableCell>
          <TableCell className={styles.particulars}>
            {this.props.selectedBills[this.props.idx].remarks}
          </TableCell>
          <TableCell className={styles.small}>
            {this.props.selectedBills[this.props.idx].taxable ? <CheckIcon/> : <ClearIcon/>}
          </TableCell>
          <TableCell className={styles.small}>
            {this.props.selectedBills[this.props.idx].with_input_vat ? <CheckIcon/> : <ClearIcon/>}
          </TableCell>
          <TableCell align="right" className={styles.amtheaderlast}>
            {parseFloat(this.props.selectedBills[this.props.idx].amount).toLocaleString(navigator.language, {minimumFractionDigits: 2})}
          </TableCell>
          <TableCell align="right" className={styles.actionButtonHoverableBill} >
            <IconButton color="secondary"
                           onClick={() => this.props.removeBill(this.props.selectedBills[this.props.idx])}>
              <DeleteIcon/> </IconButton>
          </TableCell>
        </TableRow>
      </>
    )
  }
}

export default BillsForm

BillsForm.propTypes = {
  idx: PropTypes.number,
  selectedBills: PropTypes.array,
  removeBill: PropTypes.removeBill
}
