// eslint-disable-next-line
import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import TrialBalanceTable from './TrialBalanceTable'
import ProgressBar from "../../ProgressBar/ProgressBar";
import { withRouter } from 'react-router-dom'
import TrialBalanceSearchForm from './TrialBalanceSearchForm'
import AlertMessage from '../../Notify/AlertMessage'
import PrintIcon from '@material-ui/icons/Print'
import ReactToPrint from "react-to-print";
import { IconButton } from '@material-ui/core';
import * as Excel from 'exceljs'
import { saveAs } from 'file-saver'
import moment from 'moment';

export const Component = withRouter(() => {

})

class TrialBalances extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            model: 'Trial Balance',
            trialBalances: [],
            account: { status: 'true' },
            title: '',
            load: false,
            tableHead: ['name', 'name_type', 'code', 'amount_balance'],
            branch: {},
            branches: [],
            urlPrint: {},
            searchUrl: '/v1/accounts/trial_balance',
            isOpen: false,
            forPrint: false,
            message: '',
            type: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleAutoComplete = this.handleAutoComplete.bind(this)
        this.clearSearch = this.clearSearch.bind(this)
        this.handleCloseAlert = this.handleCloseAlert.bind(this)
        this.changePrintLayout = this.changePrintLayout.bind(this)
        this.handleXlsxDownloadClick = this.handleXlsxDownloadClick.bind(this)
        this.numberFormatter = this.numberFormatter.bind(this)
        this.exportToXlsx = this.exportToXlsx.bind(this)
        this.transformDataToExcelFormat = this.transformDataToExcelFormat.bind(this)
    }

    componentDidMount() {
        var branches = []
        if (localStorage.getItem('branches')) {
            this.setState({ branches: JSON.parse(localStorage.getItem('branches')) })
            branches = JSON.parse(localStorage.getItem('branches'))
        } else {
            axios({
                method: 'get',
                url: '/v1/branches',
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => { 
                this.setState({ branches: resp.data })
                localStorage.setItem('branches', JSON.stringify(resp.data))
                branches = resp.data
             })
        }
        var searchUrl = this.state.searchUrl + this.props.location.search
        var searchParams = { status: true }
        const query = new URLSearchParams(this.props.location.search)
        query.forEach(function (value, key) {
            if (key !== 'branch_id') {
                searchParams[key] = value
            }
        })
        if (query.get('branch_id') !== undefined && query.get('branch_id') !== null && query.get('branch_id') !== '') {
            if(branches.length > 0){
                searchParams['branch'] = branches.find(branch => branch.id.toString() == query.get('branch_id'))
                this.setState({ account: searchParams})
            }
        } else { this.setState({ account: searchParams }) }
        searchUrl= searchUrl.includes('&status=all') ? searchUrl.replace('&status=all', '') : searchUrl
        searchUrl= searchUrl.includes('status=all') ? searchUrl.replace('status=all', '') : searchUrl
        this.loadTrialBalances(searchUrl, this.props.location.search ? true : false, true)

    }

    loadTrialBalances(url, withSearchParams) {
        if (withSearchParams) {
            axios({
                method: 'get',
                url: url,
                headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
                const newData = resp.data.accounts.map((item) => ({
                    id: item.id, name: item.name, code: item.code,
                    amount_balance: item.amount_balance,
                    debit: item.amount_balance > 0 ? (item.amount_balance) : 0,
                    credit: item.amount_balance < 0 ? (item.amount_balance * -1) : 0
                }))
                this.setState({ trialBalances: newData, urlPrint: resp.data.meta, load: true })
            }).catch(error => {
                this.setState({
                    isOpen: true, load: true,
                    message: error.response.status.toString() + " Unexpected Error Occurred", type: 'error'
                })
            })
        }
        this.setState({ load: true })
    }

    handleSubmit() {
        this.setState({ load: false })
        const { history } = this.props
        var searchParams = this.getSearchParams().join("&")
        const params = new URLSearchParams()
        params.append("/search", searchParams)
        history.push({ search: searchParams })
        searchParams = searchParams.includes('&status=all') ? searchParams.replace('&status=all', '') : searchParams
        searchParams = searchParams.includes('status=all') ? searchParams.replace('status=all', '') : searchParams
        this.loadTrialBalances([this.state.searchUrl, searchParams].join("?"), true, false)
    }


    handleInputChange(e) {
        this.setState({ account: { ...this.state.account, [e.target.name]: e.target.value } })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit()
        }
    }

    clearSearch() {
        const { history } = this.props
        history.push({ search: '' })
        this.setState({ account: { as_of_date: '', status: '', branch: {} } })
        this.setState({ trialBalances: [], urlPrint: {} })
    }

    handleAutoComplete(event, values) {
        this.setState({ account: { ...this.state.account, branch: values } })
    }
    getSearchParams() {
        var searchParams = []
        Object.entries(this.state.account).map(([key, value]) => {
            if (value != '' && key != 'months' && key != 'branch' && value != undefined && value != null) {
                searchParams.push([key, value].join("="))
            }
        })
        if (this.state.account.branch) {
            if (this.state.account.branch.id) {
                searchParams.push("branch_id=" + this.state.account.branch.id)
            }
        }
        return searchParams
    }

    handleCloseAlert(event, reason) {
        if (reason === 'clickaway') { this.setState({ isOpen: false }) }
    }

    changePrintLayout() {
        return new Promise((resolve) => {
            this.setState({ forPrint: !this.state.forPrint }, () => resolve());
        });
    }

    exportToXlsx(dataToPrint) {
        const { account } = this.state
        const workbook = new Excel.Workbook()
        const worksheet = workbook.addWorksheet('Trial Balance Reports')
        const border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
        }
        let totalCredit = 0, totalDebit = 0

        worksheet.mergeCells('A1:D1')
        worksheet.getCell('A1').value = "Trial Balance Reports"
        worksheet.mergeCells('A2:D2')
        worksheet.getCell('A2').value = `As of: ${moment(account.as_of_date).format("L")}`
        worksheet.mergeCells('A3:D3')
        worksheet.getCell('A3').value = `Branch: ${
            account.branch ? 
                account.branch.name ? account.branch.name 
                : 'All' 
            : 'All'
        }`
        worksheet.mergeCells('A4:D4')
        worksheet.getCell('A4').value = `Status: ${ 
            account.status === 'true' || account.status === true ? 'Posted' : 
            account.status === 'false' ? 'Not Posted' 
            : 'All' }`

        worksheet.eachRow((row) => {
            row.eachCell({ includeEmpty: false }, (cell) => {
                cell.font = { size: 15, bold: true, name: 'Arial' }
                cell.alignment = { horizontal: 'center' }
            })
        })

        worksheet.columns = [
            {
                key: 'accountCode',
                width: 20
            },
            {
                key: 'accountTitle',
                width: 50
            },
            {
                key: 'debit',
                width: 50
            },
            {
                key: 'credit',
                width: 50
            }
        ]
        worksheet.getRow(worksheet.rowCount + 1).values = [
            'Account Code',
            'Account Title',
            'Debit',
            'Credit'
        ]
        worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
            cell.font = { size: 12, name: 'Arial' }
            cell.alignment = { horizontal: 'center' }
            cell.border = border
        })

        const sortedData = dataToPrint
        .filter((data) => data.amountBalance !== 0)
        .sort(function (a, b) {
            if (a.accountCode.toLowerCase() < b.accountCode.toLowerCase()) return -1
            if (a.accountCode.toLowerCase() > b.accountCode.toLowerCase()) return 1
            return 0
        })
        const finalSort = [
            ...sortedData.filter((data) => data.debit),
            ...sortedData.filter((data) => data.credit)
        ]

        finalSort
        .forEach((data) => {
            const { accountCode, accountTitle, debit, credit } = data
            totalCredit += credit
            totalDebit += debit
            const row = worksheet.addRow({
                accountCode: Number(accountCode),
                accountTitle,
                debit: this.numberFormatter(debit),
                credit: this.numberFormatter(credit)
            })
            row.eachCell((cell, colNumber) => {
                if (colNumber === 1) cell.alignment = { horizontal: 'center', wrapText: true, vertical: 'middle' }
                if (colNumber === 2) cell.alignment = { wrapText: true, vertical: 'middle' }
                if (colNumber === 3 || colNumber === 4) cell.alignment = { horizontal: 'right', wrapText: true, vertical: 'middle'  }
                cell.border = border
                cell.font = { size: 12, name: 'Arial' }
            })
        })

        const total = worksheet.addRow({
            debit: this.numberFormatter(totalDebit),
            credit: this.numberFormatter(totalCredit)
        })
        worksheet.mergeCells(`A${worksheet.rowCount}:B${worksheet.rowCount}`)
        worksheet.getCell(`A${worksheet.rowCount}`).value = 'Total'
        total.eachCell((cell, colNumber) => {
            cell.alignment = { horizontal: 'center' }
            cell.font = { size: 12, name: 'Arial', bold: true }
            cell.border = border
            if (colNumber === 3 || colNumber === 4) cell.alignment = { horizontal: 'right' }
        })

        // save to excel file
        workbook.xlsx
        .writeBuffer()
        .then((res) => {
        const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const blob = new Blob([res], {
            type: fileType
        })
        saveAs(blob, `trial_balances.xlsx`)
        this.setState({
            isOpen: true,
            message: 'File saved!',
            type: 'success'
        })
        })
        .catch(() => {
        this.setState({
            isOpen: true,
            message: 'Error occured while saving data..',
            type: 'error'
        })
        })
    }

    numberFormatter(value) {
        return value
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    transformDataToExcelFormat(data) {
        const excelFormat = data.map((trialBalance) => {
            const { 
                code,
                name, 
                debit, 
                credit, 
                amount_balance
            } = trialBalance

            return {
                accountCode: code,
                accountTitle: name,
                debit,
                credit,
                amountBalance: amount_balance
            }
        })

        return excelFormat
    }

    handleXlsxDownloadClick() {
        const { trialBalances } = this.state
        const dataToPrint = this.transformDataToExcelFormat(trialBalances)
        this.exportToXlsx(dataToPrint)
    }

    render() {
        const printPdf = <ReactToPrint
            trigger={() => {
                return <IconButton color="primary"><PrintIcon /></IconButton>
            }}
            onBeforeGetContent={this.changePrintLayout}
            onAfterPrint={this.changePrintLayout}
            content={() => this.componentRef}
        />
        return (
            <>
                {
                    this.state.load ? (
                        <div>
                            <TrialBalanceSearchForm
                                item={this.state.account}
                                branches={this.state.branches}
                                handleChange={this.handleInputChange}
                                handleAutoComplete={this.handleAutoComplete}
                                search={this.handleSubmit}
                                clearSearch={this.clearSearch}
                                handleKeyPress={this.handleKeyPress}
                            />
                            <TrialBalanceTable
                                items={this.state.trialBalances}
                                model={this.state.model}
                                urlPrint={this.state.urlPrint}
                                forPrint={this.state.forPrint}
                                printPdf={printPdf}
                                ref={el => (this.componentRef = el)}
                                withXlsxDownload={true}
                                handleXlsxDownloadClick={this.handleXlsxDownloadClick}
                            />
                            <AlertMessage
                                handleCloseAlert={this.handleCloseAlert}
                                isOpen={this.state.isOpen}
                                type={this.state.type}
                                message={this.state.message}
                            />

                        </div>
                    ) : (
                        <ProgressBar model={this.state.model} />
                    )}
            </>
        )
    }
}

export default withRouter(TrialBalances)

TrialBalances.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object
}
