// eslint-disable-next-line

import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import { withRouter, Redirect } from 'react-router-dom'
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

import BookIcon from '@material-ui/icons/Book'
import PropTypes from "prop-types";
import CashVoucherSearch from './CashVoucherSearch'
export const Component = withRouter(() => {

})
class CashVoucher extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      model: 'Cash Voucher',
      cashVouchers: [],
      banks: [],
      payees: [],
      branches: [],
      openPopup: false,
      openDeletePopup: false,
      cashVoucher: {},
      redirectToAdd: false,
      initialCashVoucher: { id: '',
        prefix: '',
        reference_no: '',
        bank_id: '',
        branch_id: '',
        payee_id: '',
        journal_id: '',
        post_status: '',
      },
      searchCashVoucher: {},
      title: '',
      load: false,
      tableHead: [ 'prefix', 'branch.name', 'payee.name', 'remarks', 'amount', 'post_status','status' ],
      tableHeadForPayeeShow: [ 'prefix', 'bank.name','remarks', 'amount', 'post_status','status' ],
      withShow: false,
      withPagination: true,
      totalPages: 1,
      currentPage: 1,
      maxWidth: 'sm',
      error_messages: '',
      isOpen: false,
      message: '',
      type: '',
      totalResults: 0,
      withResultsLabel: false,
      expenseTypes: [],
      perPage: '20',
      searchUrl: '/v1/cash_vouchers/search'
    }
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleAutocompleteSearch = this.handleAutocompleteSearch.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handlePerPageChange=this.handlePerPageChange.bind(this)
  }

  componentDidMount () {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    var perPage = ''
    const params = new URLSearchParams(this.props.location.search)
    params.forEach(function(value, key) {
      if (key !== 'page'&& key !== 'branch_id'&&key !== 'payee_id') {
        searchParams[key] = value.replace(/\*/g,'')
      }
      if(key === 'per_page'){perPage=value}
    })
    if(params.get('branch_id')){
      axios({
        method: 'get',
        url: '/v1/branches/'+params.get('branch_id'),
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        searchParams.branch = resp.data
      })
    }
    if(params.get('payee_id')){
      axios({
        method: 'get',
        url: '/v1/payees/'+params.get('payee_id'),
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        searchParams.payee = resp.data
      })
    }
    if(params.get('expense_type_id')){
      axios({
        method: 'get',
        url: '/v1/check_voucher_expense_types/'+params.get('expense_type_id'),
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        searchParams.expense_type = resp.data
      })
    }
    if(perPage){this.setState({perPage: perPage})}
    this.setState({ searchCashVoucher: searchParams })
    this.loadCashVoucher(searchUrl, true)
  }

  loadCashVoucher (url, firstLoad) {
    axios({
      method: 'get',
      url: url.includes('?') ? url+'&sort_by[reference_no]=desc': url+'?sort_by[reference_no]=desc',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ cashVouchers: resp.data.cash_vouchers,totalPages: resp.data.meta.total_pages,currentPage: resp.data.meta.current_page })
      this.setState({ totalResults: resp.data.meta.total_count,withResultsLabel: true })
      this.setState({ load: true })
    }).catch(error => {
      this.setState({
        isOpen: true,
        message: error.response.status.toString() + " Unexpected Error Occurred",
        type: 'error'
      })
    })
    if(firstLoad){
      axios({
        method: 'get',
        url: '/v1/banks/search?per_page=1000',
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ banks: resp.data.banks })
      })
      axios({
        method: 'get',
        url: '/v1/payees/search?per_page=10000',
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ payees: resp.data.payees })
      })
      axios({
        method: 'get',
        url: '/v1/branches',
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ branches: resp.data })
      })
      axios({
        method: 'get',
        url: '/v1/check_voucher_expense_types',
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ expenseTypes: resp.data })
      })
    }
  }

  handleDeleteItem () {
    const DeleteItemId = this.state.cashVoucher.id
    axios({
      method: 'delete',
      url: '/v1/cash_vouchers/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        cashVouchers: this.state.cashVouchers.filter(item => item.id !== DeleteItemId)
      })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
      this.setState({ openDeletePopup: false })
    }).catch(error => {
      this.setState({
        isOpen: true,
        message: error.response.status.toString() + " Unexpected Error Occurred",
        type: 'error'
      })
    })
  }

  handleClose () {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: ''})
  }

  handleCreateorUpdateItem (item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
      this.setState({redirectToAdd: true})
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true })
    this.setState({ cashVoucher: item })
    this.setState({ title: textTitle })
  }

  handleInputChange (e) {
    this.setState({
      cashVoucher: {
        ...this.state.cashVoucher,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup (item, model) {
    this.setState({ model: model })
    this.setState({ cashVoucher: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose () {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert (event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      isOpen: false
    })
  }

  getSearchParams() {
    var searchParams = []
    Object.entries(this.state.searchCashVoucher).map(([key, value]) => {
      if (key!=='per_page'&&key!=='branch'&&key!=='payee'&&key!=='bank'&&key!=='expense_type' &&value != '' && value != undefined && value != null) {
        searchParams.push([key,  key==='reference_no'?value: value ].join("="))
      }
    })
    if(this.state.searchCashVoucher.branch){
      if(this.state.searchCashVoucher.branch.id){
        searchParams.push('branch_id='+this.state.searchCashVoucher.branch.id.toString())    
      }
    }
    if(this.state.searchCashVoucher.payee){
      if(this.state.searchCashVoucher.payee.id){
        searchParams.push('payee_id='+this.state.searchCashVoucher.payee.id.toString())    
      }
    }
    if(this.state.searchCashVoucher.bank){
      if(this.state.searchCashVoucher.bank.id){
        searchParams.push('bank_id='+this.state.searchCashVoucher.bank.id.toString())    
      }
    }
    if(this.state.searchCashVoucher.expense_type){
      if(this.state.searchCashVoucher.expense_type.id){
        searchParams.push('expense_type_id='+this.state.searchCashVoucher.expense_type.id.toString())    
      }
    }
    searchParams.push('per_page='+this.state.perPage)
    return searchParams
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadCashVoucher([this.state.searchUrl, searchParams].join("?"),false)
  }

  handleSearchInputChange(e) {
    this.setState({
      searchCashVoucher: {
        ...this.state.searchCashVoucher,
        [e.target.name]: e.target.value
      }
    })
  }


  handleAutocompleteSearch(event, values) {
    if (event.target.id.includes("branch")) {
      this.setState({searchCashVoucher:{...this.state.searchCashVoucher,branch: values}})
    } else if (event.target.id.includes("payee")) {
      this.setState({searchCashVoucher:{...this.state.searchCashVoucher,payee: values}})
    }else if (event.target.id.includes("expense_type")) {
      this.setState({searchCashVoucher:{...this.state.searchCashVoucher,expense_type: values}})
    }
  }


  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadCashVoucher([this.state.searchUrl, searchParams].join("?"), false)
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: '' })
    this.loadCashVoucher(this.state.searchUrl,false)
    this.setState({ searchCashVoucher: { q: '', filed_at_from: '', filed_at_to: '', payee_id: "", bank_id: '', branch: {}, status: '', remarks: '', reference_no: '', check_no: ''} })
  }

  handlePerPageChange(e){
    var searchParams = this.getSearchParams()
    searchParams.map((value, index) => {
      if(value.includes("per_page")) {
        searchParams.splice(index, 1)
      }
    })
    searchParams.push(["per_page", e.target.value].join("="))
    searchParams = searchParams.join("&")
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ perPage: e.target.value })
    this.loadCashVoucher([this.state.searchUrl, searchParams].join("?"),false)
  }

  render () {
    if(this.state.redirectToAdd){
      return (<Redirect to={{
        pathname: this.props.cashVoucherPayee? ('/payees/' + this.props.payeeId + '/cash_voucher/new'):('/cash_voucher/new')
      }} />)
    }
    return (
      <>
        {
          this.state.load ? (
            <div>
              {this.props.cashVoucherPayee !== true ? (
              <CashVoucherSearch
                item={this.state.searchCashVoucher}
                clearSearch={this.clearSearch}
                banks={this.state.banks}
                branches={this.state.branches}
                payees={this.state.payees}
                handleChange={this.handleSearchInputChange}
                handleAutocompleteSearch={this.handleAutocompleteSearch}
                handleKeyPress={this.handleKeyPress}
                search={this.handleSearch}
                expenseTypes={this.state.expenseTypes}
              />
              ): null }
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialCashVoucher}
                items={this.props.cashVoucherPayee ? (this.props.cashVoucherPayees):(this.state.cashVouchers)}
                cashVoucherPayee={this.props.cashVoucherPayee}
                model={this.state.model}
                headers={this.props.cashVoucherPayee ? (this.state.tableHeadForPayeeShow):(this.state.tableHead)}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                totalPages={this.state.totalPages}
                withPagination={this.props.cashVoucherPayee ? null:(this.state.withPagination)}
                currentPage={this.state.currentPage}
                item={this.state.cashVoucher}
                handlePageChange={this.handlePageChange}
                icon={<BookIcon fontSize="large"/>}
                payeeId={this.props.payeeId}
                withResultsLabel={this.state.withResultsLabel}
                handlePerPageChange={this.handlePerPageChange}
                perPage={this.state.perPage}
                totalResults={this.state.totalResults}
              />

              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>

          ) : (
            this.props.cashVoucherPayee !== true  ? (
                <ProgressBar model={this.state.model}/>
              ):null
          )}
      </>
    )
  }
}

export default withRouter(CashVoucher)

CashVoucher.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  cashVoucherPayees: PropTypes.array,
  cashVoucherPayee: PropTypes.bool,
  payeeId: PropTypes.number
}
