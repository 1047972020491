import React from 'react'
import styles from './Payees.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  MenuItem,
  InputAdornment,
  Tooltip,
  IconButton,
  Grid
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'

class PayeeForm extends React.Component {
  render() {
    return (
      <>
      <Grid container spacing={1}>
      <Grid item xs={12}>
        <div>
        <TextField
          autoComplete="off"
          className={styles.textFields}
          variant="outlined"
          size="small"
          name="name"
          fullWidth="true"
          label="Name"
          onChange={this.props.onchange}
          value={this.props.item.name}
          id="payee-name"
          required
        />
        </div>
        <div className={styles.errorMessage}>{this.props.error.name?'Name ' +this.props.error.name:null}</div>
      </Grid>
      <Grid item xs={12}>
      <div>
        <TextField
          autoComplete="off"
          className={styles.textFields}
          variant="outlined"
          size="small"
          name="address"
          fullWidth="true"
          label="Address"
          onChange={this.props.onchange}
          value={this.props.item.address}
          id="payee-address"
          required
        />
        </div>
        <div className={styles.errorMessage}>{this.props.error.address?'Address '+this.props.error.address:null}</div>
      </Grid>
       
        <Grid item xs={6}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="business_style"
              fullWidth="true"
              label="Business Style"
              onChange={this.props.onchange}
              value={this.props.item.business_style}
              id="payee_business_style"
            />
        </Grid>
        <Grid item xs={6}>
          <div>
          <TextField
            autoComplete="off"
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="reference_no"
            fullWidth="true"
            label="Reference no."
            onChange={this.props.onchange}
            value={this.props.item.reference_no}
            InputProps={{
              endAdornment:
                <InputAdornment position="end" >{this.props.item.reference_no ? <Tooltip title="Get Reference No"><IconButton id="refresh_ref_no" name="refresh_ref_no" onClick={()=>this.props.getReferenceNo()}><RefreshIcon /></IconButton></Tooltip> : null}</InputAdornment>,
            }}
            id="reference_no"
          /></div>
          <div className={styles.errorMessage} >{this.props.error.reference_no?'Reference No '+this.props.error.reference_no:null}</div>
        </Grid>
        <Grid item xs={6}>
          <TextField
            autoComplete="off"
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="tin"
            fullWidth="true"
            label="Tin No."
            onChange={this.props.onchange}
            value={this.props.item.tin}
            id="payee-tin"
          />
        </Grid>
        <Grid item xs={6}>
          <div>
          <TextField
            autoComplete="off"
            select
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="classification"
            fullWidth="true"
            label="Classification"
            onChange={this.props.onchange}
            value={this.props.item.classification}
            id="payee-classification"
          >
            <MenuItem value="vatable">Vatable</MenuItem>
            <MenuItem value="non_vatable">Non Vatable</MenuItem>
          </TextField></div>
          <div className={styles.errorMessage}>{this.props.error.classification?'Classification '+this.props.error.classification:null}</div>
        </Grid>
      </Grid>
      <div className={styles.actionButton}>
        <Button variant="outlined" disabled={!this.props.item.name} id="payee-submit" color="primary" onClick={() => this.props.submit()} >Save</Button>
      </div>
      </>
    )
  }
}
export default PayeeForm

PayeeForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func,
  error: PropTypes.object,
  getReferenceNo: PropTypes.func
}
