import React from 'react'
import styles from './Banks.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  Grid
} from '@material-ui/core'
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

class BanksSearch extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="name"
              fullWidth={true}
              label="Name"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.name}
              id="account-name"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="address"
              fullWidth={true}
              label="Address"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.address}
              id="address"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="bank_account_number"
              fullWidth={true}
              label="Bank Account Number"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.bank_account_number}
              id="bank_account_number"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Autocomplete
              name="account_id"
              size="small"
              options={this.props.accounts}
              className={styles.textFields}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option.name === value.name}
              onChange={this.props.handleBankSearch}
              fullWidth="true"
              value={this.props.item.account_id}
              renderInput={(params) => <TextField {...params} label="Account Name" variant="outlined" />}
            />
          </Grid>
        </Grid>

        <div className={styles.actionButton}>
          <Button variant="outlined" id="bank-search" color="primary"
                  onClick={() => this.props.search()}>Search</Button>
          <Button variant="outlined" id="bank-clearSearch" color="secondary"
                  onClick={() => this.props.clearSearch()}>Clear</Button>
        </div>

      </div>
    )
  }
}

export default BanksSearch

BanksSearch.propTypes = {
  handleChange: PropTypes.func,
  item: PropTypes.object,
  accounts: PropTypes.array,
  search: PropTypes.func,
  clearSearch: PropTypes.func,
  handleBankSearch: PropTypes.func,
  handleKeyPress: PropTypes.func
}
