// eslint-disable-next-line
import React from 'react'
import Cookies from 'js-cookie';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from 'react-router-dom'
import clsx from 'clsx'
import axios from 'axios'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import HomeIcon from '@material-ui/icons/Home'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import BookIcon from '@material-ui/icons/Book'
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp'
import PaymentIcon from '@material-ui/icons/Payment'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CheckVoucher from "./pages/CheckVoucher/CheckVoucher";
import CheckVoucherNewAndUpdate from "./pages/CheckVoucher/CheckVoucherNewAndUpdate"
import CheckVoucherShow from "./pages/CheckVoucher/CheckVoucherShow";
import CashVoucher from './pages/CashVouchers/CashVoucher';
import CashVoucherNewAndUpdate from './pages/CashVouchers/CashVoucherNewAndUpdate';
import CashVoucherShow from './pages/CashVouchers/CashVoucherShow';
import style from './App.module.css'
import Payees from './pages/Payees/Payees'
import ExpenseTypes from './pages/ExpenseTypes/ExpenseTypes'
import PayeeShow from "./pages/Payees/PayeeShow";
import DisbursementRegister from "./pages/DisbursmentRegisters/DisbursementRegister";
import Journals from './shared/JournalComponent/Journals/Journals'
import JournalShow from './shared/JournalComponent/Journals/JournalShow'
import JournalsNewAndUpdate from './shared/JournalComponent/Journals/JournalsNewAndUpdate'
import JournalRegisters from './shared/JournalComponent/GeneralJournalRegisters/JournalRegisters'
import BalanceSheets from './shared/JournalComponent/BalanceSheets/BalanceSheets'
import ConsolidatedBalanceSheets from './shared/JournalComponent/ConsolidatedBalanceSheets/ConsolidatedBalanceSheets'
import ConsolidatedIncomeStatements from './shared/JournalComponent/IncomeStatements/ConsolidatedIncomeStatements'
import IncomeStatements from './shared/JournalComponent/IncomeStatements/IncomeStatements';
import TrialBalances from './shared/JournalComponent/TrialBalances/TrialBalances'
import GeneralLedgers from './shared/JournalComponent/GeneralLedgers/GeneralLedgers'
import Banks from "./shared/Settings/Banks/Banks";
import NoteIcon from '@material-ui/icons/Note';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import NotesIcon from '@material-ui/icons/Notes';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ClassIcon from '@material-ui/icons/Class';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import SettingsSystemDaydreamOutlinedIcon from '@material-ui/icons/SettingsSystemDaydreamOutlined';
import SettingsInputCompositeOutlinedIcon from '@material-ui/icons/SettingsInputCompositeOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import RefreshIcon from '@material-ui/icons/Refresh';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Accounts from "./shared/Settings/Accounts/Accounts";
import Branches from "./shared/Settings/Branches/Branches";
import SignIn from "./shared/Sessions/SignIn"
import AccountLink from "./shared/ModuleLinks/AccountLink"
import logo from '../../src/components/images/lexLogo.png'
import Bills from "./pages/Billing/Bills";
import BillNewAndUpdate from "./pages/Billing/BillNewAndUpdate";
import BillingShow from "./pages/Billing/BillingShow";
import Permissions from "./shared/Settings/Permissions/Permissions";
import PermissionNewUpdate from "./shared/Settings/Permissions/PermissionNewUpdate";
import UpdateProfile from "./pages/Profile/UpdateProfile";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import ForgotPassword from "../components/shared/Sessions/ForgotPassword";
import UnlockInstruction from './shared/Sessions/UnlockInstruction';
import ResetPassword from './shared/Sessions/ResetPassword';
import { setupAxios } from '../helpers/axios';
import { removeAllCookies } from '../helpers/cookies';

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true,
      linkOpen: false,
      isOpenAccount: false,
      token: '',
      CurrentUser: '',
      currentUser: {},
      signIn: false,
      openPopup: false,
      apps: [],
      noSidenav: false,
      returnToLogin: false,
      anchorEl: null,
      hasOnboarded: true
    }
    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.toggleLink = this.toggleLink.bind(this)
    this.logOut = this.logOut.bind(this)
    this.setToken = this.setToken.bind(this)
    this.setLoad = this.setLoad.bind(this)
    this.loadAttribute = this.loadAttribute.bind(this)
    this.toggleAcount = this.toggleAcount.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleClosePopup = this.handleClosePopup.bind(this)
    this.setboarding = this.setboarding.bind(this)
    this.handleRefreshStorage = this.handleRefreshStorage.bind(this)
  }

  componentDidMount() {
    var token = Cookies.get('token')
    var checkvoucher_prefix = Cookies.get('checkvoucher_prefix')
    var cashvoucher_prefix = Cookies.get('cashvoucher_prefix')
    if (token) {
      localStorage.setItem('api_key', token)
      localStorage.setItem('checkvoucher_prefix',checkvoucher_prefix)
      localStorage.setItem('cashvoucher_prefix',cashvoucher_prefix)
      this.loadAttribute()
    } else {
      this.logOut()
    }
    if(Cookies.get('current_user') && !localStorage.getItem('username') ){
      localStorage.setItem('username',JSON.parse(Cookies.get('current_user')).username)
    }
    if(Cookies.get("current_user")){
      this.setState({ currentUser: JSON.parse(Cookies.get("current_user")) })
      if(JSON.parse(Cookies.get("current_user")).has_onboarded !== undefined){
        this.setState({hasOnboarded: JSON.parse(Cookies.get("current_user")).has_onboarded })
      }
      if (JSON.parse(Cookies.get("current_user")).is_employee) {
        this.logOut()
      }
    }

  }

  clearSession() {
    // Clear local storage
    localStorage.removeItem('api_key')
    localStorage.removeItem('username')
    localStorage.removeItem('password')
    localStorage.removeItem('cashvoucher_prefix')
    localStorage.removeItem('checkvoucher_prefix')
    localStorage.removeItem('branches')
    localStorage.removeItem('user_apps')
    localStorage.removeItem('username')

    removeAllCookies()
  }
  
  logOut() {
    this.setState({
      anchorEl: null,
      load: false,
      returnToLogin: true,
    })

    this.clearSession()
  }

  setToken(token) {
    this.setState({ token: token })
    if(Cookies.get("current_user")){
      this.setState({ currentUser: JSON.parse(Cookies.get("current_user")) })
    }
    this.setState({returnToLogin: false})
  }

  setboarding(boarding){
    this.setState({hasOnboarded: boarding})
  }

  setLoad(load) {
    setTimeout(() => {
      this.setState({ load : load })
   }, 1000);
  }

  toggleDrawer() {
    this.setState({ open: !this.state.open })
  }

  toggleLink() {
    this.setState({linkOpen: !this.state.linkOpen})
  }
  
  toggleAcount(event) {
    this.setState({ anchorEl: event.currentTarget })
  }
  
  handleClose() {
    this.setState({ anchorEl: null })
  }

  handleChangePassword() {
    this.setState({ openPopup: true, anchorEl: null })
  }
  
  handleClosePopup() {
    this.setState({ openPopup: false })
  }

  loadAttribute() {
    let username = Cookies.get("current_user") ? JSON.parse(Cookies.get("current_user")).username : localStorage.getItem('username')
    setupAxios(username)
    
    if(localStorage.getItem('user_apps')){
      this.setState({ apps: JSON.parse(localStorage.getItem('user_apps')) })
    }else{
      this.loadApps()
    }
    if(!localStorage.getItem('branches')){
      this.loadBranches()
    }
    this.setState({load:true})
  }

  handleRefreshStorage(){
    this.loadBranches()
    this.loadApps()
  }

  loadApps() {
    axios({
      method: 'get',
      url: '/v1/apps',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      if(resp.data.length>0){ 
      var apps=[]
      axios({
        method: 'get',
        url: '/v1/user',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(res => {
        if(res.data.app_ids.length>0){
          apps = resp.data.filter(({id}) => res.data.app_ids.includes(id))
          localStorage.setItem('user_apps', JSON.stringify(apps))
          this.setState({apps: apps}) 
        }
      })
    }
    if (resp.data.filter(item => item.url.includes(location.host)).length === 0) {
      return location.href = JSON.parse(Cookies.get("current_user")).redirect_to
    }
    })
  }

  loadBranches() {
    axios({
      method: 'get',
      url: '/v1/branches',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
        localStorage.setItem('branches', JSON.stringify(resp.data))
    })
  }

  render() {
    const { anchorEl } = this.state
    
    if(!Cookies.get('token')) {
      if  (location.pathname === '/login' || location.pathname === '/') {
        return <SignIn setboarding={this.setboarding}
         setToken={this.setToken} setLoad={this.setLoad} loadAttribute={this.loadAttribute}
        />
      }else if (location.pathname === '/forgot_password') {
        return <ForgotPassword />
      }
      else if (location.pathname === '/unlock_account') {
        return <UnlockInstruction />
      }
      else if (location.pathname === '/reset_password') {
        return <ResetPassword />
      }
      else{
        return location.href='/'
      }
    }
   

    if(this.state.returnToLogin){
      return <SignIn setboarding={this.setboarding} setToken={this.setToken} setLoad={this.setLoad} loadAttribute={this.loadAttribute}/>
    }
   
    const MENUS = [
      { label: 'Home', icon: <HomeIcon />, link: '/' },
      { label: 'Payees', icon: <PeopleOutlineIcon />, link: '/payees' },
      { label: 'Bills', icon: <DescriptionIcon />, link: '/bills' },
      { label: 'Check Vouchers', icon: <BookIcon />, link: '/check_vouchers' },
      { label: 'Cash Vouchers', icon: <NoteIcon />, link: '/cash_vouchers' },
    ]
    const MENUSWITHSUBS = [
      { parentLabel: 'Reports', parentIcon: <AssessmentOutlinedIcon />, childLabel: 'Disbursement Register', childIcon: <PaymentIcon />, link: '/disbursement_register' },
    ]

    const APSettings = [
      { menuLabel: 'AP Settings', parentIcon: <PermDataSettingIcon />,
        children: [
          { label: 'Expense Type', icon: <AccountBalanceWalletIcon />, link: '/expense_type'}
        ]
      }
    ]
    const GeneralAccounting = [
      {
        menuLabel: 'General Accounting', menuIcon: <FilterNoneIcon />,
        children: [
          { label: 'Journal Voucher', icon: <CreditCardIcon />, link: '/journals' },
        ],
        childrenWithSubs: [
          {
            parentLabel: 'Reports', parentIcon: <NotesIcon />,
            children: [
              { label: 'General Journal Register', icon: <AssignmentIcon />, link: '/journal_registers' },
              { label: 'General Ledger Report', icon: <ClearAllIcon />, link: '/general_ledgers' },
              { label: 'Balance Sheet', icon: <ListAltIcon />, link: '/balance_sheets' },
              { label: 'Consolidated Balance Sheet(*)', icon: <ClassIcon />, link: '/consolidated_balance_sheets' },
              { label: 'Trial Balance', icon: <SignalCellularAltIcon />, link: '/trial_balances' },
              { label: 'Income Statement', icon: <AccountBalanceIcon />, link: '/income_statements' },
              { label: 'Consolidated Income Statement(*)', icon: <AmpStoriesIcon />, link: '/consolidated_income_statements' }
            ]
          }
        ]
      }
    ]
    const Settings = [
      {
        menuLabel: 'Settings', menuIcon: <SettingsOutlinedIcon />,
        children: [
          { label: 'Account Setup', icon: <SettingsSystemDaydreamOutlinedIcon />, link: '/accounts' },
          { label: 'Branches', icon: <SettingsInputCompositeOutlinedIcon />, link: '/branches' },
          { label: 'Banks', icon: <AccountBalanceOutlinedIcon />, link: '/banks' },
        ],
        childrenWithSubs: [
          {
            parentLabel: 'Users Access and Permissions', parentIcon: <NotesIcon />,
            children: [
              { label: 'Permissions', icon: <AssignmentIcon />, link: '/permissions' },
            ]
          }
        ]
      }
    ]
    return (
      <>
        {
          this.state.load ? (
            <Router>
              <div className={style.root}>
                <CssBaseline />
                <AppBar
                  position="fixed"
                  className={clsx(style.appBar, {
                    [style.appBarShift]: this.state.open
                  })}
                >
                  <Toolbar>
                  {
                  this.state.noSidenav?null:
                  <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={this.toggleDrawer}
                        edge="start"
                        className={clsx(style.menuButton, {
                          [style.hide]: this.state.open
                        })}
                      >
                        <MenuIcon />
                      </IconButton>
                    }

                    <div className={style.headerTitle}>
                    <Grid
                        container spacing={1}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >  
                    <Grid item xs={12} sm={5} md={5} lg={3}>
                      <div className={style.hederListBody}>
                        <Typography variant="h6" nowrap='true'
                                    style={{flexGrow: 1}}
                        >
                          {process.env.REACT_APP_TITLE}
                        </Typography>
                        <div className={style.linkList}>
                          
                          <div>
                          {!this.state.hasOnboarded? null:
                            <AccountLink
                              toggleLink={this.toggleLink}
                              linkOpen={this.state.linkOpen}
                              apps={this.state.apps}
                            />
                          }
                          </div>
                        
                        </div>
                        
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={7} md={7} lg={9} className={style.rightBody}>
                      <div className={style.rightSideLogout}>
                        <div className={style.headerCurrentUser}>
                          <div><b>Welcome, <span nowrap='true'>{this.state.currentUser.name}</span></b></div>
                        </div>
                        {
                          !this.state.hasOnboarded?
                          <Tooltip title="logout"  arrow> 
                            <IconButton  onClick={this.logOut} style={{color: 'white'}}><ExitToAppSharpIcon/></IconButton>
                          </Tooltip>:
                        <>
                        
                        <Tooltip title="manage account"  arrow>
                        <Button aria-controls="fade-menu" aria-haspopup="true" onClick={this.toggleAcount}>
                        {anchorEl ? <ExpandLessIcon style={{ color: '#fff' }}/> : <ExpandMoreIcon style={{ color: '#fff' }}/> }
                        </Button>
                        </Tooltip>
                          <Menu
                            id="fade-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                            TransitionComponent={Fade}
                            className={style.accountBody}
                          >
                            <Link to={'/profile'} className={style.profileLink}>
                              <MenuItem onClick={this.handleClose} className={style.accountButton}><AccountCircleIcon/> Profile</MenuItem>
                            </Link>
                            <Divider light />
                            <MenuItem onClick={this.logOut} className={style.accountButton}><ExitToAppSharpIcon/>Log Out</MenuItem>
                          </Menu>
                          <div><Tooltip onClick={this.handleRefreshStorage} arrow title="Refresh Storage Data"><IconButton style={{ color: '#fff' }}><RefreshIcon /></IconButton></Tooltip></div>
                          </>
                        }
                      </div>
                      </Grid>
                      </Grid>
                    </div>
                  </Toolbar>
                </AppBar>
                {
                  this.state.noSidenav? null:
                
                <Drawer
                  variant="permanent"
                  className={clsx(style.drawer, {
                    [style.drawerOpen]: this.state.open,
                    [style.drawerClose]: !this.state.open
                  })}
                  classes={{
                    paper: clsx({
                      [style.drawerOpen1]: this.state.open,
                      [style.drawerClose2]: !this.state.open
                    })
                  }}
                >
                  <div className={style.toolbar}>
                    <div className={style.companyLogo}>
                      <img src={process.env.REACT_APP_LOGO_URL ? process.env.REACT_APP_LOGO_URL : logo }   alt="Company Logo" className={style.companyLogo}/>
                    </div>
                    <IconButton onClick={this.toggleDrawer}>
                      {style.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                  </div>
                  <Divider />
                  <List name="sidenav">
                    {!this.state.hasOnboarded? null:
                    MENUS.map((menu) => (
                      <ListItem button key={menu.label} name={menu.link} component={Link} to={menu.link}>
                        <Tooltip title={menu.label}>
                          <ListItemIcon>{menu.icon}</ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={menu.label} />
                      </ListItem>
                    ))}
                    {!this.state.hasOnboarded? null:
                    MENUSWITHSUBS.map((menu,idx) => (
                      <ListItem style={{ padding: '0' }} key={idx} disableGutters={true}>
                        <Accordion style={{ borderRadius: 'unset', boxShadow: 'inherit' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={style.flexCustom}
                          >
                            <ListItemIcon>{menu.parentIcon}</ListItemIcon>
                            <ListItemText primary={menu.parentLabel} className={style.parentLabel} />
                          </AccordionSummary>
                          <AccordionDetails className={style.subBody} >
                            <List className={style.listItem} >
                              <ListItem className={style.subMenu} button component={Link} to={menu.link}>
                                <Tooltip title={menu.childLabel}>
                                  <ListItemIcon>{menu.childIcon}</ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={menu.childLabel} />
                              </ListItem>
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </ListItem>
                    ))}

                    {!this.state.hasOnboarded? null:
                    APSettings.map((menu,idx) => (
                      <ListItem style={{ padding: '0' }} key={idx} disableGutters={true}>
                        <Accordion style={{ borderRadius: 'unset', boxShadow: 'inherit' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={style.flexCustom}
                          >
                            <ListItemIcon>{menu.parentIcon}</ListItemIcon>
                            <ListItemText primary={menu.menuLabel} className={style.parentLabel} />
                          </AccordionSummary>
                          <AccordionDetails className={style.subBody} >
                            <List className={style.listItem} >
                              {menu.children.map((menu,idx) => (
                                <ListItem className={style.subMenu} button key={idx} name={menu.link} component={Link} to={menu.link}>
                                  <Tooltip title={menu.label}>
                                    <ListItemIcon>{menu.icon}</ListItemIcon>
                                  </Tooltip>
                                  <ListItemText primary={menu.label} />
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </ListItem>
                    ))}
                     {!this.state.hasOnboarded? null:GeneralAccounting.map((menu,idx) => (
                      <ListItem style={{ padding: '0' }} key={idx} disableGutters={true}>
                        <Accordion style={{ borderRadius: 'unset', boxShadow: 'inherit' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={style.flexCustom}
                          >
                            <ListItemIcon>{menu.menuIcon}</ListItemIcon>
                            <ListItemText primary={menu.menuLabel} className={style.parentLabel} />
                          </AccordionSummary>
                          <AccordionDetails style={{ paddingTop: '0', paddingBottom: '0', padding: '0'  }} >
                            <List className={style.subMenuFirstGenss}>
                              {menu.children.map((menu,idx) => (
                                <ListItem className={style.subMenu} button key={idx} name={menu.link} component={Link} to={menu.link}>
                                  <Tooltip title={menu.label}>
                                    <ListItemIcon>{menu.icon}</ListItemIcon>
                                  </Tooltip>
                                  <ListItemText primary={menu.label} />
                                </ListItem>
                              ))}

                              {menu.childrenWithSubs.map((menu,idx) => (
                                <ListItem style={{ padding: '0' }} key={idx} >
                                  <Accordion style={{ borderRadius: 'unset', boxShadow: 'inherit' }}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      className={style.flexCustom}
                                    >
                                        <ListItemIcon style={{ paddingLeft: '16px' }} >{menu.parentIcon}</ListItemIcon>
                                        <ListItemText primary={menu.parentLabel} className={style.labelReports} />
                                    </AccordionSummary>
                                    <AccordionDetails className={style.sub2MenuGenBody} >
                                      <List className={style.sub2MenuGen} >
                                      {
                                        this.state.currentUser?(
                                          this.state.currentUser.is_from_cdasia?
                                          (<>
                                          {menu.children.map((menu,idx) => (
                                            <ListItem button key={idx} name={menu.link} component={Link} to={menu.link}>
                                              <Tooltip title={menu.label}>
                                                <ListItemIcon>{menu.icon}</ListItemIcon>
                                              </Tooltip>
                                              <ListItemText primary={menu.label} className={style.lastLabel}/>
                                            </ListItem>
                                          ))}
                                          </>):
                                          (<>
                                            {menu.children.map((menu,idx) => (
                                              menu.label.includes("Consolidated") === false && menu.label.includes("consolidated")===false &&
                                              <ListItem button key={idx} name={menu.link} component={Link} to={menu.link}>
                                                <Tooltip title={menu.label}>
                                                  <ListItemIcon>{menu.icon}</ListItemIcon>
                                                </Tooltip>
                                                <ListItemText primary={menu.label} className={style.lastLabel}/>
                                              </ListItem>
                                            ))}
                                            </>)
                                        ):null
                                      }                                   
                                      </List>
                                    </AccordionDetails>
                                  </Accordion>
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </ListItem>
                    ))}
                     {!this.state.hasOnboarded? null:Settings.map((menu,idx) => (
                      <ListItem style={{ padding: '0' }} key={idx} >
                        <Accordion style={{ borderRadius: 'unset', boxShadow: 'inherit' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={style.flexCustom}
                          >
                            <ListItemIcon>{menu.menuIcon}</ListItemIcon>
                            <ListItemText primary={menu.menuLabel} className={style.parentLabel} />
                          </AccordionSummary>
                          <AccordionDetails style={{ paddingTop: '0', paddingBottom: '0', padding: '0'  }} >
                            <List style={{ paddingTop: '0', paddingBottom: '0', padding: '0' }} >
                              {menu.children.map((menu,idx) => (
                                <ListItem className={style.subMenu} button key={idx} name={menu.link} component={Link} to={menu.link}>
                                  <Tooltip title={menu.label}>
                                    <ListItemIcon>{menu.icon}</ListItemIcon>
                                  </Tooltip>
                                  <ListItemText primary={menu.label} />
                                </ListItem>
                              ))}

                              {menu.childrenWithSubs.map((menu,idx) => (
                                <ListItem style={{ padding: '0' }} key={idx} >
                                  <Accordion style={{ borderRadius: 'unset', boxShadow: 'inherit' }}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon className={style.toggle} />}
                                      className={style.flexCustom}
                                    >
                                      <ListItemIcon style={{ paddingLeft: '16px' }}>{menu.parentIcon}</ListItemIcon>
                                      <ListItemText primary={menu.parentLabel} className={style.parentLabel} />
                                    </AccordionSummary>
                                    <AccordionDetails className={style.subBody} >
                                      <List className={style.listItem}>
                                        {menu.children.map((menu,idx) => (
                                          <ListItem className={style.subMenu} button key={idx} name={menu.link} component={Link} to={menu.link}>
                                            <Tooltip title={menu.label}>
                                              <ListItemIcon>{menu.icon}</ListItemIcon>
                                            </Tooltip>
                                            <ListItemText primary={menu.label} />
                                          </ListItem>
                                        ))}
                                      </List>
                                    </AccordionDetails>
                                  </Accordion>
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </ListItem>
                    ))}
                  </List>
                  {!this.state.hasOnboarded? null:process.env.REACT_APP_POWERED_BY !== "" && process.env.REACT_APP_POWERED_BY !== undefined ?
                    <div className={this.state.open === true? style.companyName : style.displayNone }>
                    <p>
                      {process.env.REACT_APP_POWERED_BY}
                    </p>
                  </div> :null}
                </Drawer>
                }
                <div className={this.state.open? style.openBody : style.closeBody}>
                    <Grid container style={{ flexWrap: 'inherit' }}
                      className={this.state.open === true ? "openSidenav" : "none"}
                    >
                      <Grid item xs={12}>
                        <main className={style.content}>
                          {
                            !this.state.hasOnboarded?
                              <></>
                            :
                          <Switch>
                            <Route exact path="/">
                              <Redirect to="/payees" />
                            </Route>

                            <Route exact path="/payees">
                              <Payees />
                            </Route>
                            <Route exact path="/check_vouchers">
                              <CheckVoucher />
                            </Route>
                            <Route exact path="/check_vouchers/:id" component={CheckVoucherShow} />
                            <Route exact path="/payees/:payee_id/check_vouchers/:id" component={CheckVoucherShow} />

                            <Route exact path="/check_voucher/new" component={CheckVoucherNewAndUpdate} />
                            <Route exact path="/bills/:bill_id/check_voucher/new" component={CheckVoucherNewAndUpdate} />
                            <Route exact path="/check_vouchers/:id/edit" component={CheckVoucherNewAndUpdate} />
                            <Route exact path="/payees/:id/check_vouchers/new" component={CheckVoucherNewAndUpdate} />
                            <Route exact path="/cash_vouchers">
                              <CashVoucher />
                            </Route>
                            <Route exact path="/cash_vouchers/:id" component={CashVoucherShow} />
                            <Route exact path="/cash_voucher/new" component={CashVoucherNewAndUpdate} />
                            <Route exact path="/cash_vouchers/:id/edit" component={CashVoucherNewAndUpdate} />
                            <Route exact path="/bills/:bill_id/cash_vouchers/new" component={CashVoucherNewAndUpdate} />
                            <Route exact path="/payees/:id/cash_vouchers/new" component={CashVoucherNewAndUpdate} />
                            <Route exact path="/payees/:id" component={PayeeShow} />
                            <Route exact path="/accounts">
                            <Accounts />
                            </Route>
                            <Route exact path="/branches">
                              <Branches />
                            </Route>
                            <Route exact path="/banks">
                              <Banks />
                            </Route>
                            <Route exact path="/disbursement_register">
                              <DisbursementRegister />
                            </Route>
                            <Route exact path="/expense_type">
                              <ExpenseTypes />
                            </Route>
                            <Route exact path="/journals">
                              <Journals />
                            </Route>
                            <Route exact path="/journals/:id" component={JournalShow} />
                            <Route exact path="/journal/new" component={JournalsNewAndUpdate} />
                            <Route exact path="/journals/:id/edit" component={JournalsNewAndUpdate} />
                            <Route exact path="/journal_registers">
                              <JournalRegisters />
                            </Route>
                            <Route exact path="/balance_sheets">
                              <BalanceSheets />
                            </Route>
                            <Route exact path="/consolidated_balance_sheets">
                              <ConsolidatedBalanceSheets />
                            </Route>
                            <Route exact path="/trial_balances">
                              <TrialBalances />
                            </Route>
                            <Route exact path="/income_statements">
                              <IncomeStatements />
                            </Route>
                            <Route exact path="/consolidated_income_statements">
                              <ConsolidatedIncomeStatements />
                            </Route>
                            <Route exact path="/general_ledgers">
                              <GeneralLedgers />
                            </Route>
                            <Route exact path="/bills">
                              <Bills />
                            </Route>
                            <Route exact path="/bills/new" component={BillNewAndUpdate} />
                            <Route exact path="/bills/:bill_id" component={BillingShow} />
                            <Route exact path="/bills/:bill_id/edit" component={BillNewAndUpdate} />
                            <Route exact path="/permissions">
                              <Permissions/>
                            </Route>
                            <Route exact path="/profile">
                              <UpdateProfile/>
                            </Route>
                            <Route exact path="/permissions/new" component={PermissionNewUpdate} />
                            <Route exact path="/permissions/:permission_id" component={PermissionNewUpdate} />
                            <Route exact path="/login" render={() => ( localStorage.getItem('api_key') ? <Redirect to="/" /> : <Redirect to="/login" />)} />                            
                            {/* <Route exact path="/login" component={SignIn} /> */}
                            <Route path="*">
                              Route Not Found
                            </Route>

                          </Switch>
                        }
                        </main>
                      </Grid>
                    </Grid>
                  </div>
              </div>
            </Router>
              ) : (<LinearProgress />)
        }
      </>
    )
  }
}
export default App
