// eslint-disable-next-line

import React from 'react'

import SimpleTable from '../../SimpleTable/SimpleTable'

import axios from 'axios'
import AlertMessage from "../../Notify/AlertMessage";
import ProgressBar from "../../ProgressBar/ProgressBar";
import JournalSearchForm from './JournalSearchForm';

import CreditCardIcon from '@material-ui/icons/CreditCard'
import PropTypes from "prop-types";
import { withRouter, Redirect } from 'react-router-dom'
export const Component = withRouter(() => {

})
class Journals extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			model: 'Journal',
			journals: [],
			banks: [],
			payees: [],
			branches: [],
			openPopup: false,
			openDeletePopup: false,
			journal: {},
			initialjournal: {
				id: '',
				date_of_check: '',
				prefix: '',
				check_no: '',
				reference_no: '',
				bank_id: '',
				branch_id: '',
				payee_id: '',
				journal_id: '',
				post_status: '',
			},
			searchjournal: {},
			title: '',
			load: false,
			tableHead: ['date_of_journal', 'reference_no', 'description', 'branch_name', 'amount', 'is_posted'],
			tableHeader: ['date_of_journal', 'reference_no', 'description', 'amount', 'is_posted'],
			withShow: false,
			withPagination: true,
			totalPages: 1,
			currentPage: 1,
			maxWidth: 'sm',
			error_messages: '',
			isOpen: false,
			message: '',
			type: '',
			redirectToAdd: false,
			perPage: '20',
			totalResults: 1,
			searchUrl: '/v1/journals/search'
		}
		this.handleDeleteItem = this.handleDeleteItem.bind(this)
		this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
		this.handleClose = this.handleClose.bind(this)
		this.handleInputChange = this.handleInputChange.bind(this)
		this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
		this.handleDeleteClose = this.handleDeleteClose.bind(this)
		this.handleCloseAlert = this.handleCloseAlert.bind(this)
		this.handlePageChange = this.handlePageChange.bind(this)
		this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
		this.handleAutocompleteSearch = this.handleAutocompleteSearch.bind(this)
		this.handleSearch = this.handleSearch.bind(this)
		this.clearSearch = this.clearSearch.bind(this)
		this.handleKeyPress = this.handleKeyPress.bind(this)
		this.handlePerPageChange = this.handlePerPageChange.bind(this)
	}

	componentDidMount() {
		var searchUrl = this.state.searchUrl + this.props.location.search
		var searchParams = {}
		var perPage = ''
		const params = new URLSearchParams(this.props.location.search)
		params.forEach(function (value, key) {
			if (key !== 'page' && key !== 'branch_id') {
				searchParams[key] = value.replace(/\*/g, '')
			}
			if (key === 'per_page') { perPage = value }
		})
		if (params.get('branch_id')) {
			axios({
				method: 'get',
				url: '/v1/branches/' + params.get('branch_id'),
				headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
			}).then(resp => {
				searchParams.branch = resp.data
			})
		}

		if (perPage) { this.setState({ perPage: perPage }) }
		this.setState({ searchjournal: searchParams })
		this.loadjournal(searchUrl, true)
	}

	loadjournal(url, firstLoad) {
		axios({
			method: 'get',
			url: url.includes('?') ? url + '&resource_type=Journal&sort_by[date_of_journal]=desc' : url + '?&resource_type=Journal&sort_by[date_of_journal]=desc',
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(resp => {
			this.setState({ journals: resp.data.journals })
			this.setState({ totalPages: resp.data.meta.total_pages })
			this.setState({ currentPage: resp.data.meta.current_page })
			this.setState({ totalResults: resp.data.meta.total_count })
			this.setState({ load: true })
		}).catch(error => {
			this.setState({
				isOpen: true,
				message: error.response.status.toString() + " Unexpected Error Occurred",
				type: 'error'
			})
		})
		if (firstLoad) {
			axios({
				method: 'get',
				url: '/v1/banks/search?per_page=1000',
				headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
			}).then(resp => {
				this.setState({ banks: resp.data.banks })
			})
			axios({
				method: 'get',
				url: '/v1/payees/search?per_page=10000',
				headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
			}).then(resp => {
				this.setState({ payees: resp.data.payees })
			})
			if(localStorage.getItem('branches')) {
                this.setState({ branches: JSON.parse(localStorage.getItem('branches')) })
            }else{
				axios({
					method: 'get',
					url: '/v1/branches',
					headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
				}).then(resp => {
					this.setState({ branches: resp.data })
				})
			}
		}

	}

	handleDeleteItem() {
		const DeleteItemId = this.state.journal.id
		axios({
			method: 'delete',
			url: '/v1/journals/' + DeleteItemId,
			headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
		}).then(() => {
			this.setState({
				journals: this.state.journals.filter(item => item.id !== DeleteItemId)
			})
			this.setState({
				isOpen: true,
				message: 'Deleted Successfully',
				type: 'error'
			})
			this.setState({ openDeletePopup: false })
		}).catch(error => {
			this.setState({
				isOpen: true,
				message: error.response.status.toString() + " Unexpected Error Occurred",
				type: 'error'
			})
		})
	}

	handleClose() {
		this.setState({ openPopup: false })
		this.setState({ openDeletePopup: false })
		this.setState({ error_messages: '' })
	}

	handleCreateorUpdateItem(item, isAdd, model) {
		var textTitle = ''
		if (isAdd) {
			textTitle = 'Create ' + model
			this.setState({ redirectToAdd: true })
		} else {
			textTitle = 'Edit ' + model
		}
		this.setState({ openPopup: true })
		this.setState({ journal: item })
		this.setState({ title: textTitle })
	}

	handleInputChange(e) {
		this.setState({
			journal: {
				...this.state.journal,
				[e.target.name]: e.target.value
			}
		})
	}

	handleOpenDeletePopup(item, model) {
		this.setState({ model: model })
		this.setState({ journal: item })
		this.setState({ openDeletePopup: true })
	}

	handleDeleteClose() {
		this.setState({ openDeletePopup: false })
	}

	handleCloseAlert(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		this.setState({
			isOpen: false
		})
	}

	handlePageChange(event, value) {
		var searchParams = this.getSearchParams()
		searchParams.push(["page", value].join("="))
		searchParams = searchParams.join("&")
		const { history } = this.props
		const params = new URLSearchParams()
		params.append("/search", searchParams)
		history.push({ search: searchParams })
		this.setState({ currentPage: value })
		this.loadjournal([this.state.searchUrl, searchParams].join("?"), false)
	}

	handleSearchInputChange(e) {
		if (e.target.name === "starts_on_from") {
			var dateStart = new Date(e.target.value).toLocaleDateString('fr-Ca')
			var lastDay = new Date(new Date(dateStart).getFullYear(), new Date(dateStart).getMonth() + 1, 0).toLocaleDateString('fr-Ca')
			this.setState({
				searchjournal: {
					...this.state.searchjournal,
					[e.target.name]: e.target.value,
					starts_on_to: lastDay
				}
			})
		}
		else {
			this.setState({
				searchjournal: {
					...this.state.searchjournal,
					[e.target.name]: e.target.value
				}
			})
		}
	}


	handleAutocompleteSearch(event, values) {
		if (event.target.id.includes("branch")) {
			this.setState({
				searchjournal:
				{
					...this.state.searchjournal,
					branch: values
				}
			})
		}
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			this.handleSearch()
		}
	}

	handleSearch() {
		const { history } = this.props
		var searchParams = this.getSearchParams().join("&")
		const params = new URLSearchParams()
		params.append("/search", searchParams)
		history.push({ search: searchParams })
		this.loadjournal([this.state.searchUrl, searchParams].join("?"), false)
	}

	clearSearch() {
		const { history } = this.props
		history.push({ search: '' })
		this.loadjournal('/v1/journals/search?', this.state.perPage)
		this.setState({ searchjournal: { starts_on_from: '', starts_on_to: '', branch: {}, is_posted: '', reference_no: '' } })

	}

	handlePerPageChange(e) {
		var searchParams = this.getSearchParams()
		searchParams.map((value, index) => {
			if (value.includes("per_page")) {
				searchParams.splice(index, 1)
			}
		})
		searchParams.push(["per_page", e.target.value].join("="))
		searchParams = searchParams.join("&")
		const { history } = this.props
		const params = new URLSearchParams()
		params.append("/search", searchParams)
		history.push({ search: searchParams })
		this.setState({ perPage: e.target.value })
		this.loadjournal([this.state.searchUrl, searchParams].join("?"), false)
	}

	getSearchParams() {
		var searchParams = []
		Object.entries(this.state.searchjournal).map(([key, value]) => {
			if (key !== 'per_page' && key !== 'branch' && value != '' && value != undefined && value != null) {
				searchParams.push([key, key === 'reference_no' ? value : value].join("="))
			}
		})
		if (this.state.searchjournal.branch) {
			if (this.state.searchjournal.branch.id) {
				searchParams.push('branch_id=' + this.state.searchjournal.branch.id.toString())
			}
		}

		searchParams.push('per_page=' + this.state.perPage)
		return searchParams
	}

	render() {
		if (this.state.redirectToAdd) {
			return (<Redirect to={{
				pathname: '/journal/new'
			}} />)
		}
		return (
			<>
				{
					this.state.load ? (
						<div>

							<JournalSearchForm
								item={this.state.searchjournal}
								clearSearch={this.clearSearch}
								banks={this.state.banks}
								branches={this.state.branches}
								payees={this.state.payees}
								handleChange={this.handleSearchInputChange}
								handleAutocompleteSearch={this.handleAutocompleteSearch}
								handleKeyPress={this.handleKeyPress}
								search={this.handleSearch}
							/>

							<SimpleTable
								handleClick={this.handleCreateorUpdateItem}
								initialItem={this.state.initialjournal}
								items={this.state.journals}
								model={this.state.model}
								headers={this.state.branches.length > 1 ? this.state.tableHead : this.state.tableHeader}
								withShow={this.state.withShow}
								withEdit={false}
								totalPages={this.state.totalPages}
								withPagination={this.state.withPagination}
								currentPage={this.state.currentPage}
								item={this.state.journal}
								handlePageChange={this.handlePageChange}
								icon={<CreditCardIcon fontSize="large" />}
								withResultsLabel={true}
								totalResults={this.state.totalResults}
								perPage={this.state.perPage}
								handlePerPageChange={this.handlePerPageChange}
							/>

							<AlertMessage
								notify={this.state.notify}
								handleCloseAlert={this.handleCloseAlert}
								isOpen={this.state.isOpen}
								type={this.state.type}
								message={this.state.message}
							/>
						</div>

					) : (

						<ProgressBar model={this.state.model} />

					)}
			</>
		)
	}
}

export default withRouter(Journals)

Journals.propTypes = {
	location: PropTypes.object,
	history: PropTypes.object
}
