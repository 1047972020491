import React from 'react'

import PropTypes from 'prop-types'
class CompanyHeader extends React.Component {
  render () {
    return (
      <></>
    )
  }
}
export default CompanyHeader

CompanyHeader.propTypes = { 
  forPrint: PropTypes.bool

}
