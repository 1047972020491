// eslint-disable-next-line
import React from 'react'
import PropTypes from 'prop-types'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import DescriptionIcon from '@material-ui/icons/Description'
import BillingSearch from './BillingSearch'
import axios from 'axios'
import { withRouter, Redirect } from 'react-router-dom'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";

export const Component = withRouter(() => {

})

class Bill extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Bill',
      bills: [],
      payees: [],
      branches: [],
      expenseTypes: [],
      searchBill: {},
      load: false,
      withPagination: true,
      totalPages: 1,
      currentPage: 1,
      redirectToAdd: false,
      tableHead: [ 'reference_no', 'payee.name', 'remarks', 'amount', 'is_paid', 'post_status', ],
      withResultsLabel: false,perPage: '20',totalResults: 1,
      searchUrl: '/v1/bills/search',
    }
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleAutocompleteSearch = this.handleAutocompleteSearch.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.loadbills = this.loadbills.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handlePerPageChange=this.handlePerPageChange.bind(this)
  }

  componentDidMount() {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    var perPage = ''
    const params = new URLSearchParams(this.props.location.search)
    params.forEach(function(value, key) {
      if (key !== 'page'&& key !== 'branch_id'&&key !== 'payee_id'&&key !== 'bank_id') {
        searchParams[key] = value.replace(/\*/g,'')
      }
      if(key === 'per_page'){perPage=value}
    })
    if(params.get('branch_id')){
      axios({
        method: 'get',
        url: '/v1/branches/'+params.get('branch_id'),
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        searchParams.branch = resp.data
      })
    }
    if(params.get('payee_id')){
      axios({
        method: 'get',
        url: '/v1/payees/'+params.get('payee_id'),
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        searchParams.payee = resp.data
      })
    }
  
    if(params.get('expense_type_id')){
      axios({
        method: 'get',
        url: '/v1/check_voucher_expense_types/'+params.get('expense_type_id'),
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        searchParams.expense_type = resp.data
      })
    }
    if(perPage){this.setState({perPage: perPage})}
    this.setState({ searchBill: searchParams })
    this.loadbills(searchUrl, true)
  }

  loadbills(url,firstLoad) {
    
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ bills: resp.data.bills,totalPages: resp.data.meta.total_pages,currentPage: resp.data.meta.current_page,withResultsLabel:true, load: true })
      this.setState({ totalResults: resp.data.meta.total_count})
    }).catch(error => {
      this.setState({
        isOpen: true,
        message: error.response.status.toString() + " Unexpected Error Occurred",
        type: 'error'
      })
    })
    if(firstLoad){
      axios({
        method: 'get',
        url: '/v1/payees/search?per_page=10000',
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ payees: resp.data.payees })
      })
      axios({
        method: 'get',
        url: '/v1/branches',
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ branches: resp.data })
      })
      axios({
        method: 'get',
        url: '/v1/check_voucher_expense_types',
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ expenseTypes: resp.data })
      })
    }
    
  }

  handleCreateorUpdateItem() {
    this.setState({redirectToAdd: true})
  }
  
  handleSearchInputChange(e) {
    this.setState({
      searchBill: {
        ...this.state.searchBill,
        [e.target.name]: e.target.value
      }
    })
  }

  handleAutocompleteSearch(event, values) {
   if (event.target.id.includes("payee")) {
      this.setState({ searchBill: { ...this.state.searchBill, payee: values } })
    }else if (event.target.id.includes("expense_type")) {
      this.setState({
        searchBill: { ...this.state.searchBill, expense_type: values } }) }
    else if (event.target.id.includes("branch")) {
      this.setState({
        searchBill:
          {
            ...this.state.searchBill,
            branch: values
          }
      })
    }
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadbills([this.state.searchUrl, searchParams].join("?"),false)
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadbills([this.state.searchUrl, searchParams].join("?"), false)
  }

  clearSearch() {
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", 'per_page='+this.state.perPage)
    history.push({ search: 'per_page='+this.state.perPage })
    this.loadbills(this.state.searchUrl,false)
    this.setState({ searchBill: { q: '', filed_at_from: '', filed_at_to: '', due_at_from: '', due_at_to: '', payee_id: "", status: '', remarks: '', taxable: '', reference_no: '', is_paid: ''} })
  }

  handlePerPageChange(e){
    var searchParams = this.getSearchParams()
    searchParams.map((value, index) => {
      if(value.includes("per_page")) {
        searchParams.splice(index, 1)
      }
    })
    searchParams.push(["per_page", e.target.value].join("="))
    searchParams = searchParams.join("&")
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ perPage: e.target.value })
    this.loadbills([this.state.searchUrl, searchParams].join("?"),false)
  }

  getSearchParams() {
    var searchParams = []
    Object.entries(this.state.searchBill).map(([key, value]) => {
      if (key!=='per_page'&&key!=='branch'&&key!=='payee'&&key!=='bank'&&key!=='expense_type' &&value != '' && value != undefined && value != null) {
        searchParams.push([key,  key==='reference_no'?value: value ].join("="))
      }
    })
    if(this.state.searchBill.branch){
      if(this.state.searchBill.branch.id){
        searchParams.push('branch_id='+this.state.searchBill.branch.id.toString())    
      }
    }
    if(this.state.searchBill.payee){
      if(this.state.searchBill.payee.id){
        searchParams.push('payee_id='+this.state.searchBill.payee.id.toString())    
      }
    }
    if(this.state.searchBill.expense_type){
      if(this.state.searchBill.expense_type.id){
        searchParams.push('expense_type_id='+this.state.searchBill.expense_type.id.toString())    
      }
    }
    searchParams.push('per_page='+this.state.perPage)
    return searchParams
  }
  
  render() {
    if(this.state.redirectToAdd){
      return (<Redirect to={{
        pathname: this.state.redirectToAdd? ('/bills/new'):('')
      }} />)
    }
    return (
      <>
        {
          this.state.load ?
          (
            <div>
              <BillingSearch
                item={this.state.searchBill}
                clearSearch={this.clearSearch}
                payees={this.state.payees}
                branches={this.state.branches}
                handleChange={this.handleSearchInputChange}
                handleAutocompleteSearch={this.handleAutocompleteSearch}
                handleKeyPress={this.handleKeyPress}
                search={this.handleSearch}
                expenseTypes={this.state.expenseTypes}
              />
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialBill}
                items={this.state.bills}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                icon={<DescriptionIcon fontSize="large" />}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                handlePageChange={this.handlePageChange}
                withPagination={this.state.withPagination}
                totalResults={this.state.totalResults}
                withResultsLabel={this.state.withResultsLabel}
                perPage={this.state.perPage}
                handlePerPageChange={this.handlePerPageChange}
              />
            </div>
          ) : (
          <ProgressBar model={this.state.model}/>
        )}
      </>
    )
  }
}

export default withRouter(Bill)

Bill.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
