import React from 'react'
import styles from './Profile.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button
} from '@material-ui/core'

class ProfileForm extends React.Component {
  render () {
    return (
      <div>
        <form>
          <TextField
            autoComplete="off"
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="name"
            fullWidth="true"
            label="Name"
            onChange={this.props.onchange}
            value={this.props.item.name}
            id="expenseType-name"
          />

          <TextField
            autoComplete="off"
            className={styles.textFields}
            variant="outlined"
            size="small"
            name="email"
            fullWidth="true"
            label="Email"
            onChange={this.props.onchange}
            value={this.props.item.email}
            id="expenseType-description"
          />
        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined"  id="profile-submit" color="primary" onClick={() => this.props.submit()} >Save</Button>
        </div>
      </div>
    )
  }
}
export default ProfileForm

ProfileForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func,
  onFileChange: PropTypes.func,
  error: PropTypes.object
}
