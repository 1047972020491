// eslint-disable-next-line
import React from 'react'
import AssignmentIcon from '@material-ui/icons/Assignment'
import axios from 'axios'
import styles from './JournalRegisters.module.css'
import PropTypes from 'prop-types'
import SimpleTable from '../../SimpleTable/SimpleTable'
import ProgressBar from "../../ProgressBar/ProgressBar";
import JournalSearchForm from './JournalSearchForm'
import { withRouter } from 'react-router-dom'
import { Table, TableRow, TableCell } from '@material-ui/core'
import AlertMessage from '../../Notify/AlertMessage'
import PrintIcon from '@material-ui/icons/Print'
import ReactToPrint from "react-to-print";
import { IconButton } from '@material-ui/core';
import moment from 'moment'
import * as Excel from 'exceljs'
import { saveAs } from 'file-saver'

export const Component = withRouter(() => {

})

class JournalRegisters extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Journal Register',
      journalRegisters: [],
      searchParams: { is_posted: 'true' },
      title: '',
      load: false,
      tableHead: ['date_of_journal', 'reference_no', 'branch_name', 'is_posted', 'journal_entries'],
      branch: {},
      branches: [],
      currentPage: 1,
      totalPages: 1,
      urlPrint: {},
      totalLength: 0,
      forPrint: false,
      isOpen: false,
      message: '',
      type: '',
      searchUrl: '/v1/journals/search',
      isSearchStartFromSet: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleAutoComplete = this.handleAutoComplete.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.changePrintLayout = this.changePrintLayout.bind(this)
    this.handleXlsxDownloadClick = this.handleXlsxDownloadClick.bind(this)
    this.transformDataToExcelFormat = this.transformDataToExcelFormat.bind(this)
    this.exportToXlsx = this.exportToXlsx.bind(this)
    this.numberFormatter = this.numberFormatter.bind(this)
  }

  componentDidMount() {
    var branches = []
    if (localStorage.getItem('branches')) {
      this.setState({ branches: JSON.parse(localStorage.getItem('branches')), load: true })
      branches = JSON.parse(localStorage.getItem('branches'))
    } else {
      axios({
        method: 'get',
        url: '/v1/branches',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ branches: resp.data })
        branches = resp.data
      })
    }
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = { is_posted: 'true' }
    const params = new URLSearchParams(this.props.location.search)
    // This is to load all params based on URLSearchParams
    params.forEach(function (value, key) {
      if (key !== 'page') {
        if (key === 'branch_id' && value !== null && value !== undefined) {
          if (branches.length > 0) {
            branches.find(branch => { branch.id.toString() === value ? searchParams['branch'] = branch : null })
          }
        } else {
          searchParams[key] = value
        }
      }
    })
    this.setState({ searchParams: searchParams })
    this.loadGeneralJournalRegisters(searchUrl, this.props.location.search ? true : false)
  }

  loadGeneralJournalRegisters(url, withSearchParams) {
    if (withSearchParams) {
      axios({
        method: 'get',
        url: url.includes('?') ? url + '&resource_type=Journal&per_page=100000&sort_by[date_of_journal]=desc' : url + '?resource_type=Journal&per_page=100000&sort_by[date_of_journal]=desc',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        if (resp.data.journals.length > 0) {
          this.setState({ totalLength: resp.data.journals.length })
          var journalArray = []
          var isDoneLoading = false
          resp.data.journals.map((journal, idx) => {
            var item = journal

            axios({
              method: 'get',
              url: '/v1/journals/' + journal.id + '/journal_entries',
              headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
            }).then(resp => {
              item["journal_entries"] = resp.data
              journalArray.push(item)
              this.setState({ journalRegisters: [...this.state.journalRegisters, item] })
            })
            if (resp.data.journals.length - 1 === idx) {
              isDoneLoading = true

            }
          })
          this.setState({ load: isDoneLoading })
          this.setState({ urlPrint: { ...resp.data.meta, export_excel_url: undefined} })
        } else {
          this.setState({ load: true })
        }
      }).catch((error) => {
        if (error.response) { this.setState({ load: true, isOpen: true, message: error.response.status + " Unexpected Problem Occurred", type: 'error' }) }
      })
    } else {
      this.setState({ load: true })
    }
  }

  handleSubmit() {
    this.setState({ journalRegisters: [], load: false })
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadGeneralJournalRegisters([this.state.searchUrl, searchParams].join("?"), true, false)
    this.setState({
      isSearchStartFromSet: this.state.searchParams.starts_on_from ? true : false
    })
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")

    const { history } = this.props
    const params = new URLSearchParams()

    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.lo([this.state.searchUrl, searchParams].join("?"))
  }

  getSearchParams() {
    var searchParams = []
    // This is to load all params based on searchParams state.
    Object.entries(this.state.searchParams).map(([key, value]) => {
      if (value != '' && value != 'all' && value != undefined && value != null) {
        if (key === "branch") {
          searchParams.push(['branch_id', value.id].join("="))
        } else {
          searchParams.push([key, value].join("="))
        }
      }
    })

    return searchParams
  }

  handleInputChange(e) {
    if (e.target.name === "starts_on_from") {
      var dateStart = new Date(e.target.value).toLocaleDateString('fr-Ca')
      var lastDay = new Date(new Date(dateStart).getFullYear(), new Date(dateStart).getMonth() + 1, 0).toLocaleDateString('fr-Ca')
      this.setState({
        searchParams: {
          ...this.state.searchParams,
          [e.target.name]: e.target.value,
          starts_on_to: lastDay
        }
      })
    }
    else {
      this.setState({
        searchParams: {
          ...this.state.searchParams,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSubmit()
    }
  }

  clearSearch() {
    const { history } = this.props
    history.push({ search: '' })
    this.setState({ searchParams: { starts_on_from: '', starts_on_to: '', is_posted: '', branch: {} } })
    this.setState({ journalRegisters: [], urlPrint: {} })
    this.setState({ isSearchStartFromSet: false })
  }

  handleAutoComplete(event, values) {
    this.setState({
      searchParams: {
        ...this.state.searchParams,
        branch: values
      }
    })
  }

  changePrintLayout() {
    return new Promise((resolve) => {
      this.setState({ forPrint: !this.state.forPrint }, () => resolve());
    });
  }

  grandDebit() {
    var totalDebit = 0
    if (this.state.journalRegisters.length > 0) {
      this.state.journalRegisters.map((content) => {
        content.journal_entries.map((debit) => {
          totalDebit = parseFloat(totalDebit) + parseFloat(debit.amount_debit)
        })
      })
    }
    return totalDebit
  }

  grandCredit() {
    var totalCredit = 0
    if (this.state.journalRegisters.length > 0) {
      this.state.journalRegisters.map((content) => {
        content.journal_entries.map((credit) => {
          totalCredit = parseFloat(totalCredit) + parseFloat(credit.amount_credit)
        })
      })
    }
    return totalCredit
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }

  handleXlsxDownloadClick() {
    const { journalRegisters } = this.state
    const dataToPrint = this.transformDataToExcelFormat(journalRegisters)
    this.exportToXlsx(dataToPrint)
  }

  exportToXlsx(dataToPrint) {
    const workbook = new Excel.Workbook()
    const worksheet = workbook.addWorksheet('Journal Register Reports')
    const border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }

    worksheet.mergeCells('A1:H1')
    worksheet.getCell('A1').value = "Journal Register Reports"
    worksheet.getCell('A1').font = {
      size: 15,
      bold: true
    }
    worksheet.getCell('A1').alignment = {
      horizontal: 'center'
    }

    worksheet.columns = [
      {
        key: 'number',
        width: 10
      },
      {
        key: 'date',
        width: 15
      },
      {
        key: 'referenceNo',
        width: 15
      },
      {
        key: 'description',
        width: 50
      },
      {
        key: 'accountCode',
        width: 15
      },
      {
        key: 'accountName',
        width: 50
      },
      {
        key: 'debit',
        width: 18
      },
      {
        key: 'credit',
        width: 18
      }
    ]
    worksheet.getRow(worksheet.rowCount + 1).values = [
      'Number',
      'Date',
      'Reference No.',
      'Description',
      'Account Code',
      'Account Name',
      'Debit',
      'Credit'
    ]
    worksheet.eachRow({ includeEmpty: false }, (row, rowCount) => {
      if (rowCount === 1) return undefined
      row.eachCell((cell) => {
          cell.font = { size: 12 }
          cell.alignment = { horizontal: 'center' }
          cell.border = border
      })
    })

    let debitGrandTotal = 0, creditGrandTotal = 0
  
    dataToPrint.forEach((data) => {
      const { number, date, referenceNo, description } = data
      let totalDebit = 0, totalCredit = 0

      const sortedJournalEntries = data.journalEntries.sort((a, b) => {
        if ( a.account && b.account && a.account.code.toLowerCase() < b.account.code.toLowerCase()) return -1
        if ( a.account && b.account && a.account.code.toLowerCase() > b.account.code.toLowerCase()) return 1
        return 0
      })
      const finalSort = [
        ...sortedJournalEntries.filter((entry) => entry.debit !== "0.0"),
        ...sortedJournalEntries.filter((entry) => entry.debit === "0.0")
      ]
      finalSort.forEach((entry, idx) => {
        const { accountCode, accountName, debit, credit } = entry
        totalDebit += Number(debit)
        totalCredit += Number(credit)
        let row = {}
        if (idx === 0) {
          row = worksheet.addRow({
            number,
            date,
            referenceNo: Number(referenceNo), 
            description,
            accountCode: Number(accountCode),
            accountName,
            debit: this.numberFormatter(Number(debit)),
            credit: this.numberFormatter(Number(credit))
          })
        } else {
          row = worksheet.addRow({
            accountCode: Number(accountCode),
            accountName,
            debit: this.numberFormatter(Number(debit)),
            credit: this.numberFormatter(Number(credit))
          })
        }
        row.eachCell((cell, colNumber) => {
          if (colNumber === 1 || colNumber === 2 || colNumber === 3 || colNumber === 5) cell.alignment = { horizontal: 'center', vertical: 'middle' }
          if (colNumber === 4) cell.alignment = { horizontal: 'left', vertical: 'middle' }
          if (colNumber === 7 || colNumber === 8) cell.alignment = { horizontal: 'right', vertical: 'middle' }
          if (colNumber === 6) cell.alignment = { wrapText: true, vertical: 'middle' }
          cell.border = border
          cell.font = { size: 12 }
        })
      })
      const row = worksheet.addRow({
        accountName: "TOTAL",
        debit: this.numberFormatter(totalDebit),
        credit: this.numberFormatter(totalCredit)
      })
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (colNumber === 7 || colNumber === 8) cell.alignment = { horizontal: 'right' }
        if (colNumber === 6) cell.alignment = { horizontal: 'center' }
        cell.border = border
        cell.font = { size: 12, bold: true }
      })

      worksheet.mergeCells(`A${worksheet.rowCount - data.journalEntries.length}:A${worksheet.rowCount - 1}`)
      worksheet.mergeCells(`B${worksheet.rowCount - data.journalEntries.length}:B${worksheet.rowCount - 1}`)
      worksheet.mergeCells(`C${worksheet.rowCount - data.journalEntries.length}:C${worksheet.rowCount - 1}`)
      worksheet.mergeCells(`D${worksheet.rowCount - data.journalEntries.length}:D${worksheet.rowCount - 1}`)
      
      debitGrandTotal += totalDebit
      creditGrandTotal += totalCredit
    })
    const rowGrandTotal = worksheet.addRow({
      accountName: "GRANDTOTAL",
      debit: this.numberFormatter(debitGrandTotal),
      credit: this.numberFormatter(creditGrandTotal)
    })
    rowGrandTotal.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      if (colNumber === 7 || colNumber === 8) cell.alignment = { horizontal: 'right' }
      if (colNumber === 6) cell.alignment = { horizontal: 'center' }
      cell.border = border
      cell.font = { size: 12, bold: true }
    })
    worksheet.eachRow((row) => {
      row.eachCell((cell) => cell.font.name = 'Arial' )
    })

    // save to excel file
    workbook.xlsx
    .writeBuffer()
    .then((res) => {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const blob = new Blob([res], {
        type: fileType
      })
      saveAs(blob, `journal_registers.xlsx`)
      this.setState({
        isOpen: true,
        message: 'File saved!',
        type: 'success'
      })
    })
    .catch(() => {
      this.setState({
        isOpen: true,
        message: 'Error occured while saving data..',
        type: 'error'
      })
    })
  }

  transformDataToExcelFormat(data) {
    const excelFormat = data.map((journalEntry, idx) => {
      const { date_of_journal, reference_no, description, journal_entries } = journalEntry

      return {
        number: idx + 1,
        date: moment(date_of_journal).format("L"),
        referenceNo: reference_no,
        description: description,
        journalEntries: journal_entries.map((entry) => ({
          account: entry.account,
          accountCode: entry.account.code,
          accountName: entry.account.name,
          debit: entry.amount_debit,
          credit: entry.amount_credit
        }))
      }
    })

    return excelFormat
  }

  numberFormatter(value) {
    return value
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  render() {
    const printPdf = <ReactToPrint
      trigger={() => {
        return <IconButton color="primary"><PrintIcon /></IconButton>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />

    return (
      <>
        {
          this.state.load ? (
            <div>
              <JournalSearchForm
                item={this.state.searchParams}
                branches={this.state.branches}
                branch={this.state.branch}
                handleChange={this.handleInputChange}
                search={this.handleSubmit}
                clearSearch={this.clearSearch}
                handleAutoComplete={this.handleAutoComplete}
                handleKeyPress={this.handleKeyPress}
              />
              <div ref={el => (this.componentRef = el)} >
                <SimpleTable
                  handleClick={this.handleCreateorUpdateItem}
                  handlePageChange={this.handlePageChange}
                  items={this.state.journalRegisters}
                  model={this.state.model}
                  totalPages={this.state.totalPages}
                  currentPage={this.state.currentPage}
                  headers={this.state.tableHead}
                  icon={this.state.forPrint ? null : <AssignmentIcon fontSize="large" />}
                  urlPrint={this.state.urlPrint}
                  withPrint={true}
                  forPrint={this.state.forPrint}
                  printPdf={printPdf}
                  withXlsxDownload={true}
                  isSearchStartFromSet={this.state.isSearchStartFromSet}
                  handleXlsxDownloadClick={this.handleXlsxDownloadClick}
                />
                {this.state.journalRegisters.length > 0 ? (
                  <Table>
                    <TableRow className="fixWidth">
                      <TableCell className={styles.cellNone}></TableCell>
                      <TableCell className={styles.grandTotal}>Grand Total</TableCell>
                      <TableCell
                        className={styles.grandDebit}>{this.grandDebit().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                      <TableCell
                        className={styles.grandCredit}>{this.grandCredit().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                    </TableRow>
                  </Table>
                ) : null}
              </div>
              <AlertMessage
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )}
      </>
    )
  }
}

export default withRouter(JournalRegisters)

JournalRegisters.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
