import React from 'react'
import styles from './Branches.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button, Grid
} from '@material-ui/core'
import Switch from "@material-ui/core/Switch/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";

class BranchesForm extends React.Component {
  checkParams(params){
    var disabled = false
    if(this.props.isEdit){
      disabled = !this.props.allowedParams.includes(params)
    }
    return disabled
  }

  render() {
    
    return (
      <div>
        <form>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="name"
                fullWidth
                disabled={this.checkParams('name')}
                label="Name"
                onChange={this.props.onchange}
                value={this.props.item.name}
                id="branch-name"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="short_name"
                disabled={this.checkParams('short_name')}
                fullWidth
                label="Short Name"
                onChange={this.props.onchange}
                value={this.props.item.short_name}
                id="short_name"
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="address"
                disabled={this.checkParams('address')}
                fullWidth
                label="Address"
                onChange={this.props.onchange}
                value={this.props.item.address}
                id="address"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="telephone"
                fullWidth
                disabled={this.checkParams('telephone')}
                label="Telephone Number"
                onChange={this.props.onchange}
                value={this.props.item.telephone}
                id="telephone"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="fax_no"
                disabled={this.checkParams('fax_no')}
                fullWidth
                label="Fax Number"
                onChange={this.props.onchange}
                value={this.props.item.fax_no}
                id="fax_no"
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="email"
                fullWidth
                disabled={this.checkParams('email')}
                label="Email"
                onChange={this.props.onchange}
                value={this.props.item.email}
                id="email"
              />
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                type="number"
                size="small"
                name="rank"
                disabled={this.checkParams('rank')}
                fullWidth
                label="Rank"
                onChange={this.props.onchange}
                value={this.props.item.rank}
                id="rank"
              />
            </Grid>
            <Grid item xs={12} sm={3} lg={3}>
              <div style={{float: 'right'}}>
              <FormControlLabel
                control={<Switch
                  size="medium"
                  checked={this.props.item.is_active}
                  disabled={this.checkParams('is_active')}
                  onChange={this.props.handleSwitch}
                  name="is_active"/>}
                label="Active ?"
              />
              </div>
            </Grid>
          </Grid>
        </form>

        <div className={styles.actionButton}>
          <Button variant="outlined" disabled={!this.props.item.name} id="branch-submit" name="branch-submit" color="primary"
                  onClick={() => this.props.submit()}>Save</Button>
        </div>
      </div>
    )
  }
}

export default BranchesForm

BranchesForm.propTypes = {
  onchange: PropTypes.func,
  handleSwitch: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func,
  isEdit: PropTypes.bool,
  allowedParams: PropTypes.array
}
