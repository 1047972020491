import React from 'react'
import styles from './Payees.module.css'

import axios from "axios";
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import CheckVoucher from "../CheckVoucher/CheckVoucher";
import ProgressBar from '../../shared/ProgressBar/ProgressBar';
import AlertMessage from '../../shared/Notify/AlertMessage';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {
  Grid, Tooltip, Typography
} from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import AuditLog from '../../shared/AuditLog/AuditLog';
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class PayeeShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Payee',
      payee: {},
      checkVoucherPayee: true,
      checkVoucherPayees: [],
      load: false,
      value: 0
    }
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    var loadPayee = true
    if (this.props.location.state) {
      if (this.props.location.state.item) {
        loadPayee = false
        this.setState({ payee: this.props.location.state.item, load: true })
      }
    }
    this.loadPayee('/v1/payees/' + this.props.match.params.id, loadPayee)
  }

  loadPayee(url, loadItem) {
    if (loadItem) {
      axios({
        method: 'get',
        url: url,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ payee: resp.data, load: true })
      }).catch(error => {
        if (error.response.status === 422) {
          this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
        } else {
          this.setState({
            isOpen: true,
            message: error.response.status.toString() + " Unexpected Error Occurred",
            type: 'error'
          })
        }
      })
    }
    axios({
      method: 'get',
      url: '/v1/payees/' + this.props.match.params.id + '/check_vouchers',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({
        checkVoucherPayees: resp.data.sort(
          (a, b) => new Date(a.date_of_check).getTime() - new Date(b.date_of_check).getTime()
        ).reverse()
      })
    }).catch(error => {
      if (error.response.status === 422) {
        this.setState({ error_messages: 'Name ' + error.response.data.errors.name[0] })
      } else {
        this.setState({
          isOpen: true,
          message: error.response.status.toString() + " Unexpected Error Occurred",
          type: 'error'
        })
        this.setState({ openPopup: false })
      }
    })

  }

  handleChange(event, newValue) {
    this.setState({ value: newValue })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <div className={styles.showTitleHeader}>
                <div className={styles.titleBack}><h2>  {this.state.payee.name} </h2><div>
                  <Tooltip title="Back"><Link to={`/payees`}>< ArrowBackIosIcon fontSize="small" /></Link></Tooltip>
                </div></div>
                <hr />

                <Grid container spacing={1}>

                  <Grid item lg={4}>
                    <div className={styles.detailList}>
                      <div><b> TIN No. </b></div>
                      <div>:&nbsp; {this.state.payee.tin} </div>
                    </div>
                  </Grid>

                  <Grid item lg={4}>
                    <div className={styles.detailList}>
                      <div><b> Classification </b></div>
                      <div>:&nbsp; {this.state.payee.classification ? this.state.payee.classification.replace('_', ' ') : null} </div>
                    </div>
                  </Grid>

                  <Grid item lg={4}>

                    <div className={styles.detailList}>
                      <div><b> Business Style </b></div>
                      <div>:&nbsp; {this.state.payee.business_style} </div>
                    </div>
                  </Grid>


                </Grid>

                <div className={styles.detailList}>
                  <div><b> Address </b></div>
                  <div>:&nbsp; {this.state.payee.address} </div>
                </div>
              </div>
              <hr />
              <div>
                <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor="secondary" aria-label="simple tabs example">
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}><b>VOUCHERS</b></span></div>}{...a11yProps(0)} />
                  <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                    <span className={styles.tabLabel}><b>LOGS</b></span></div>}{...a11yProps(0)} />

                </Tabs>
                <TabPanel value={this.state.value} index={0} className={styles.tabListBody}>
                  <CheckVoucher checkVoucherPayees={this.state.checkVoucherPayees}
                    checkVoucherPayee={this.state.checkVoucherPayee} payeeId={this.state.payee.id} />
                </TabPanel>
                <TabPanel value={this.state.value} index={1} className={styles.tabListBody}>
                  <AuditLog gid={this.state.payee.gid} />
                </TabPanel>

                <AlertMessage
                  notify={this.state.notify}
                  handleCloseAlert={this.handleCloseAlert}
                  isOpen={this.state.isOpen}
                  type={this.state.type}
                  message={this.state.message}
                />
              </div>
            </div>
          ) : (
            <ProgressBar model={this.state.model} />
          )
        }
      </>
    )
  }
}

export default PayeeShow

PayeeShow.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.object
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
}
