// eslint-disable-next-line

import React from 'react'

import SimpleTable from '../../SimpleTable/SimpleTable'
import SimpleDeletePopUp from '../../SimpleDeletePopUp/SimpleDeletePopUp'

import axios from 'axios'
import { Redirect } from 'react-router-dom'
import AlertMessage from "../../Notify/AlertMessage";
import ProgressBar from "../../ProgressBar/ProgressBar";

class Permissions extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      model: 'Permission',
      permissions: [],
      users: [],
      roles: [],
      checkedUsers: [],
      employees: [],
      updateUsers: [],
      updateRoles: [],
      openPopup: false,
      openDeletePopup: false,
      redirectToAdd: false,
      redirectToAddOrEdit: false,
      permission: {},
      initialPermission: { id: '', name: '', description: '', user_ids: [], role_ids: [] },
      title: '',
      load: false,
      tableHead: ['Name', 'Employee name'],
      withShow: true,
      withEdit: true,
      maxWidth: 'sm',
      error_messages: '',
      isOpen: false,
      message: '',
      type: ''
    }
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.loadPermissions = this.loadPermissions.bind(this)
  }

  componentDidMount () {
    this.loadPermissions('/v1/permissions')
  }

  loadPermissions (url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      var newArray = []
      this.setState({ permissions: resp.data })
      resp.data.map((account) => {
          var item = account
          axios({
            method: "get",
            url: "/v1/permissions/" + account.id + '/users',
            headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
          }).then(resp => {
            item["employee_name"] = resp.data
            newArray.push(item)
            this.setState({permissions: newArray.sort(function (a, b) {
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              return 0;
            }), load: true})
          }).catch(error => console.log(error.response))
        },
      )
    }).catch(error => console.log(error.response))
  }



  handleDeleteItem () {
    const DeleteItemId = this.state.permission.id
    axios({
      method: 'delete',
      url: '/v1/permissions/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        permissions: this.state.permissions.filter(item => item.id !== DeleteItemId)
      })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
      this.setState({ openDeletePopup: false })
    }).catch(error => console.log(error.response))
  }

  handleClose () {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: ''})
  }

  handleCreateorUpdateItem (item, isAdd ) {
    if(isAdd) { 
      this.setState({redirectToAdd: true, redirectToAddOrEdit: isAdd})
    }
    else {
      this.setState({redirectToAdd: true, permission: item})
    }
  }


  handleOpenDeletePopup (item, model) {
    this.setState({ model: model })
    this.setState({ permission: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose () {
    this.setState({ openDeletePopup: false })
  }


  render () {
    if(this.state.redirectToAdd){
      return (<Redirect to={{
        pathname: this.state.redirectToAddOrEdit ? ('/permissions/new'): (`/permissions/${this.state.permission.id}`)
      }} />)
    }
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialPermission}
                items={this.state.permissions}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
              />
            
              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.permission}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>

          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default Permissions
