import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types'
import styles from './Sessions.module.css'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import logo from '../../images/lexLogo.png'
import CompanyBG from '../../images/background-new-flip.png'
import AlertMessage from '../Notify/AlertMessage'
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios'

import { setupAxios } from '../../../helpers/axios';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      message: '',
      type: '',
      user: {username: '', email: ''}
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this._handleKeyPress = this._handleKeyPress.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
  }

  handleChange(e) {
    e.preventDefault()
    this.setState({
      user: {...this.state.user,[e.target.name]: e.target.value}
    })
  }

  handleSubmit () {
    setupAxios(this.state.user.username)

    const item = this.state.user
    axios({
      method: 'post',
      url: '/v1/users/passwords',
      data: (item),
      
    }).then(() => {
      setTimeout(() => {
        location.href="/login"
      }, 1500, this.setState({
                  isOpen: true,
                  message: 'Verification email sent to your email',
                  type: 'success'
                }))
    }).catch(error => {
      if (error.response.status === 404) { 
        this.setState({isOpen: true,message: "Invalid Username and/or Email",type: 'error'})
      }
    })
  }

  validateForm() {
    return (
      this.state.user.username.length > 0 &&
      this.state.user.email.length > 0 
    );
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleCloseAlert (event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  render() {
    return (
      <>
        <Grid container component="main" className={styles.root}>
          <CssBaseline/>
          <Grid item xs={false} sm={4} md={7}>
            <img src={process.env.REACT_APP_BG_URL?process.env.REACT_APP_BG_URL:CompanyBG } alt="Company Background" className={styles.image}/>
          </Grid>
          <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
            <div className={styles.paper}>
              <div className={styles.headerName}> {process.env.REACT_APP_TITLE_COMPANY?process.env.REACT_APP_TITLE_COMPANY: "LEXITECH" } Enterprise System</div>
              <div>
                <img src={process.env.REACT_APP_LOGO_URL? process.env.REACT_APP_LOGO_URL:logo } alt="Company Logo" className={styles.avatar}/>
              </div>
              <Typography component="h1" variant="h5">
                Forget your Password
              </Typography>
              <form className={styles.form} noValidate>
                <Grid container>
                  <Grid item xs>

                  </Grid>
                  <Grid item className={styles.forgotIcons}>
                    <Tooltip title="Unlock your Account" arrow>
                    <a href="/unlock_account">
                      <IconButton variant="body2"
                      >
                        <LockOpenOutlinedIcon/>
                      </IconButton>
                    </a>
                    </Tooltip>
                    <Tooltip title="Login" arrow>

                    <a href="/login">
                      <IconButton variant="body2" >
                          <PersonOutlineOutlinedIcon />
                      </IconButton>
                    </a>
                    </Tooltip>
                  </Grid>
                </Grid>
                <TextField
                  variant="outlined"
                  margin="normal"
                  autoComplete="off"
                  className={styles.textField}
                  required
                  fullWidth
                  id="forgot_username"
                  label="Username"
                  onChange={this.handleChange}
                  value={this.state.user.username}
                  onKeyPress={this._handleKeyPress}
                  name="username"
                  autoFocus
                />
                  <TextField
                  variant="outlined"
                  margin="normal"
                  autoComplete="off"
                  className={styles.textField}
                  required
                  onChange={this.handleChange}
                  value={this.state.user.email}
                  onKeyPress={this._handleKeyPress}
                  fullWidth
                  id="forgot_email"
                  label="Email Address"
                  name="email"
                  autoFocus
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}
                  className={styles.submit}
                  disabled={!this.validateForm() || this.state.isOpen} 
                >
                  {this.state.isOpen? <CircularProgress size={20}/>:  'Send Reset Instructions'}
                </Button>

              </form>
            </div>
          </Grid>
          <AlertMessage
            notify={this.state.notify}
            handleCloseAlert={this.handleCloseAlert}
            isOpen={this.state.isOpen}
            type={this.state.type}
            message={this.state.message}
          />
        </Grid>
        
      </>
    )
  }
}

export default ForgotPassword

ForgotPassword.propTypes = {
  setToken: PropTypes.func,
  loadAttribute: PropTypes.func,
  forgot: PropTypes.bool,
  setLoad: PropTypes.func
}
