import React from 'react'
import styles from './CheckVoucher.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,
  MenuItem,
  Grid
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

class CheckVoucherSearch extends React.Component {
	render() {
  
		return (
			<div>
				<Grid container spacing={1}>
          <Grid item xs={12} lg={2}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="q"
              fullWidth={true}
              label="Full text"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.q}
              id="q"
            />
          </Grid>
					<Grid item xs={6} lg={2}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              type="date"
              variant="outlined"
              size="small"
              name="date_of_check_from"
              fullWidth={true}
              label="Start Date"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.date_of_check_from}
              id="payee-name"
              InputLabelProps={{
                shrink: true
              }}
            />
					</Grid>
					<Grid item xs={6} lg={2}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              type="date"
              variant="outlined"
              size="small"
              name="date_of_check_to"
              fullWidth={true}
              label="End Date"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.date_of_check_to}
              InputLabelProps={{
                shrink: true
              }}
            />
					</Grid>
					<Grid item xs={6} lg={2}>
						<TextField
							autoComplete="off"
							className={styles.textFields}
							variant="outlined"
							size="small"
							name="reference_no"
							fullWidth={true}
							label="Reference Number"
							onChange={this.props.handleChange}
							onKeyPress={this.props.handleKeyPress}
							value={this.props.item.reference_no}
							id="payee-tin"
						/>
					</Grid>
          <Grid item xs={6} lg={2}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="check_no"
              fullWidth={true}
              label="Check No"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.check_no}
              id="check_no"
            />
          </Grid>
          <Grid item xs={6} lg={2}>
          <Autocomplete
                name="expense_type"
                id="expense_type"
                className={styles.textFields}
                size="small"
                options={this.props.expenseTypes}
                getOptionLabel={(option) => option.name }
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocompleteSearch}
                fullWidth="true"
                value={this.props.item.expense_type || {}}
                renderInput={(params) => <TextField {...params} label="Expense Type" variant="outlined" />}
              />
          </Grid>
          <Grid item xs={6} lg={3}>
            <div className={styles.textFields}>
              <Autocomplete
                name="payee"
                id="payee"
                size="small"
                options={this.props.payees}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocompleteSearch}
                fullWidth="true"
                value={this.props.item.payee  || ''}
                renderInput={(params) => <TextField {...params} label="Payee" variant="outlined" />}
              />
            </div>
          </Grid>
          <Grid item xs={6} lg={2}>
            <div className={styles.textFields}>
              <Autocomplete
                name="bank"
                id="bank"
                size="small"
                options={this.props.banks}
                getOptionLabel={(option) => option.name }
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocompleteSearch}
                fullWidth="true"
                value={this.props.item.bank || ''}
                renderInput={(params) => <TextField {...params} label="Bank" variant="outlined" />}
              />
            </div>
          </Grid>
          <Grid item xs={6} lg={2}>
            <div className={styles.textFields}>
              <Autocomplete
                name="branch"
                id="branch"
                size="small"
                options={this.props.branches}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocompleteSearch}
                fullWidth="true"
                value={this.props.item.branch || {}}
                renderInput={(params) => <TextField {...params} label="Branch" variant="outlined" />}
              />
            </div>
          </Grid>
          <Grid item xs={6} lg={2}>
            <TextField
              autoComplete="off"
              select
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="status"
              fullWidth={true}
              label="Status"
              onChange={this.props.handleChange}
              value={this.props.item.status || ''}
              id="payee-classification"
              >
            <MenuItem value="" disabled ></MenuItem>
            <MenuItem value="Posted">Posted</MenuItem>
            <MenuItem value="Not Posted">Not Posted</MenuItem>
            <MenuItem value="Cancelled">Cancelled</MenuItem>
          </TextField>
          </Grid>
          <Grid item xs={6} lg={3}>
            <TextField
              autoComplete="off"
              className={styles.textFields}
              variant="outlined"
              size="small"
              name="remarks"
              fullWidth={true}
              label="Particulars"
              onChange={this.props.handleChange}
              onKeyPress={this.props.handleKeyPress}
              value={this.props.item.remarks}
            />
          </Grid>
				</Grid>
				<div className={styles.actionButton}>
					<Button variant="outlined" id="payee-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
          <Button variant="outlined" id="payee-search" color="primary" onClick={() => this.props.search()} >Search</Button>
				</div>

			</div>
		)
	}
}
export default CheckVoucherSearch

CheckVoucherSearch.propTypes = {
	handleChange: PropTypes.func,
  handleAutocompleteSearch: PropTypes.func,
	item: PropTypes.object,
  branches: PropTypes.array,
  banks: PropTypes.array,
  payees: PropTypes.array,
	search: PropTypes.func,
	clearSearch: PropTypes.func,
	handleKeyPress: PropTypes.func,
  expenseTypes: PropTypes.array
}
