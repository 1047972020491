import React from 'react'
import styles from "./Profile.module.css";
import Coverphoto from "../../images/coverPhoto.jpeg"
import PropTypes from "prop-types";
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import LinkedCameraOutlinedIcon from '@material-ui/icons/LinkedCameraOutlined';

import Tooltip from '@material-ui/core/Tooltip'
class Profile extends React.Component {

  render() {
    return (

      <div className={styles.profileBody}>
        <div>
          <div className={styles.coverPhoto}>
            <img src={Coverphoto} alt="Cover photo"/>

            <div className={styles.profilePic}>
              <div>
                <Avatar src={this.props.profile.avatar_url} className={styles.profileAvatar}>
                </Avatar>
                <div className={styles.editAvatarButton}>
                  <Tooltip title="Edit Profile picture" arrow>
                    <IconButton onClick={this.props.openEditAvatar} size="small" >
                      <LinkedCameraOutlinedIcon size="small"/>
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
                <span className={styles.pulse}></span>
              <div className={styles.profileName}>
                <h1>{this.props.profile.name}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.editProfile}>
          <IconButton onClick={this.props.openEditProfile} size="small"> Edit</IconButton>
        </div>

      </div>
    )
  }
}

export default Profile

Profile.propTypes = {
  profile: PropTypes.object,
  openEditProfile: PropTypes.func,
  openEditAvatar: PropTypes.func,
}
