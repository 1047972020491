import React from 'react'
import PropTypes from 'prop-types'
import axios from "axios"
import BillingForm from "./BillingForm";
import { Redirect } from 'react-router'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import moment from 'moment';

class BIllNewAndUpdate extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      model: 'Bills',
      bills: [],
      banks: [],
      branches: [],
      branch: {},
      bank: {},
      bill: {
        id: '',
        branch_id: '',
        expense_type_id: '',
        payee_id: '',
        reference_no: '',
        taxable: false,
        filed_at:  moment().format("yyyy-MM-DD"),
        due_at: '',
        amount: '',
        with_input_vat: false,
        remarks: '',
        project_name: ''
      },
      initialBillsContent: { account_code: '', account: {}, amount_debit: 0, amount_credit: 0 },
      accountPayable: {},
      inputTax: {},
      withHoldingTax: {},
      expenseTypes: [],
      expenseType:{},
      payees: [],
      accounts: [],
      billsContent: [],
      forEditBillsContent: [],
      fordeleteBillsContent: [],
      payeesAutoCompleteLoading: false,
      bankAutoCompleteLoading: false,
      branchAutoCompleteLoading: false,
      redirect: false,
      withInputTax: false,
      errorMessages: {},
      load: false,
      paymentAccounts: [{account_code: '',account: '',amount_credit:0,amount_debit: 0}],
      immediatePayment: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleAutocomplete = this.handleAutocomplete.bind(this)
    this.amountChange = this.amountChange.bind(this)
    this.handleSwitch = this.handleSwitch.bind(this)
    this.addBillsContent = this.addBillsContent.bind(this)
    this.handleBillsContentChange = this.handleBillsContentChange.bind(this)
    this.removeBillsContent = this.removeBillsContent.bind(this)
    this.handleContentAutocomplete = this.handleContentAutocomplete.bind(this)
    this.handleBillsContentAmount = this.handleBillsContentAmount.bind(this)
    this.handleInputTax=this.handleInputTax.bind(this)

    this.loadExpenseTypeAutoCompleteOptions=this.loadExpenseTypeAutoCompleteOptions.bind(this)
    this.loadPayeesAutoCompleteOptions=this.loadPayeesAutoCompleteOptions.bind(this)
    this.handleAutocompleteOnInputChange=this.handleAutocompleteOnInputChange.bind(this)
    this.loadBranchAutoCompleteOptions=this.loadBranchAutoCompleteOptions.bind(this)
    this.addBillsContent=this.addBillsContent.bind(this)
    this.populateContent=this.populateContent.bind(this)
    this.handleImmediatePayment=this.handleImmediatePayment.bind(this)
    this.handlePaymentAccount = this.handlePaymentAccount.bind(this)
    this.handlePaymentAmt = this.handlePaymentAmt.bind(this)
    this.addPaymentAccount = this.addPaymentAccount.bind(this)
    this.removePaymentAccount = this.removePaymentAccount.bind(this)
  }

  componentDidMount() {
    this.loadCheckVoucher()
  }

  loadCheckVoucher() {
    this.loadPayeesAutoCompleteOptions()
    this.loadAccountsCompleteOptions()
    this.loadExpenseTypeAutoCompleteOptions()
    this.loadBranchAutoCompleteOptions()
    if (this.props.match.params.bill_id !== undefined) {
      axios({
        method: 'get',
        url: '/v1/accounts/search',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')},
        params: {name: "Accounts Payable"}
      }).then(resp => {
        this.setState({
          accountPayable: {
            account_code: resp.data.accounts.length > 0 ? (resp.data.accounts[0].code) : '',
            account: resp.data.accounts.length > 0 ? resp.data.accounts[0] : '',
            amount_debit: 0,
            amount_credit: 0
          }
        })
      })
      axios({
        method: 'get',
        url: '/v1/bills/' + this.props.match.params.bill_id,
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({bill: resp.data, selectedPayee: resp.data.payee, expenseType: resp.data.expense_type, branch: resp.data.branch, load: true})
      }).catch(error => console.log(error.response))
      axios({
        method: 'get',
        url: '/v1/accounts/search?name=Input',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({inputTax: resp.data.accounts.length > 0 ? (resp.data.accounts[0]) : ({})})
      }).catch(error => console.log(error.response))
      axios({
        method: 'get',
        url: '/v1/accounts/search?name=Withholding tax - expanded',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({
          withHoldingTax: {
            account_code: resp.data.accounts.length > 0 ? (resp.data.accounts[0].code) : '',
            account: resp.data.accounts.length > 0 ? resp.data.accounts[0] : '',
            amount_debit: 0,
            amount_credit: 0
          }
        })
      }).catch(error => console.log(error.response))
      axios({
        method: 'get',
        url: '/v1/bills/' + this.props.match.params.bill_id + '/contents',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({billsContent: resp.data})
        const inputExist = resp.data.some(function(el) {
            return el.account.name === "Input VAT"
        })
        this.setState({withInputTax: inputExist,  bill: {...this.state.bill, with_input_vat: inputExist} })
      }).catch(error => console.log(error.response))
    }
    else {

      axios({
        method: 'get',
        url: '/v1/accounts/search',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')},
        params: {name: "Accounts Payable"}
      }).then(resp => {
        this.setState({
          accountPayable: {
            account_code: resp.data.accounts.length > 0 ? (resp.data.accounts[0].code) : '',
            account: resp.data.accounts.length > 0 ? resp.data.accounts[0] : '',
            amount_debit: 0,
            amount_credit: 0
          }
        })
      })
      axios({
        method: 'get',
        url: '/v1/accounts/search?name=Input',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({ inputTax: resp.data.accounts.length>0?(resp.data.accounts[0]):({}) })
      }).catch(error => console.log(error.response))
      axios({
        method: 'get',
        url: '/v1/accounts/search?name=Withholding tax - expanded',
        headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
      }).then(resp => {
        this.setState({
          withHoldingTax: {
            account_code: resp.data.accounts.length > 0 ? (resp.data.accounts[0].code) : '',
            account: resp.data.accounts.length > 0 ? resp.data.accounts[0] : '',
            amount_debit: 0,
            amount_credit: 0
          }
        })
      }).catch(error => console.log(error.response))
      axios({
        method: 'get',
        url: '/v1/employees/profiles',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        if(resp.data.branch_id){
          axios({
            method: 'get',
            url: '/v1/branches/'+resp.data.branch_id,
            headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
          }).then(resp => {
            this.setState({branch: resp.data, bill: {...this.state.bill, branch_id: resp.data.id}})
          }).catch(error => console.log(error.response))
        }
      }).catch(error => console.log(error.response))
      this.setState({load: true})
    }
  }

  loadPayeesAutoCompleteOptions(searchText = '') {
    axios({
      method: 'get',
      url: '/v1/payees/search',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: { name: searchText }
    }).then(resp => {
      this.setState({ payees: resp.data.payees, payeesAutoCompleteLoading: false })
    }).catch(error => console.log(error.response))
  }

  loadBranchAutoCompleteOptions(searchText = '') {
    axios({
      method: 'get',
      url: '/v1/branches',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: { name: searchText }
    }).then(resp => {
      this.setState({ branches: resp.data, bankAutoCompleteLoading: false })
    }).catch(error => console.log(error.response))
  }

  loadExpenseTypeAutoCompleteOptions() {
    axios({
      method: 'get',
      url: '/v1/check_voucher_expense_types',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ expenseTypes: resp.data})
    }).catch(error => console.log(error.response))
  }

  loadAccountsCompleteOptions(searchText = '') {
    axios({
      method: 'get',
      url: '/v1/accounts/search?per_page=1000',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')},
      params: { name: searchText }
    }).then(resp => {
      this.setState({ accounts: resp.data.accounts, payeesAutoCompleteLoading: false})
    }).catch(error => console.log(error.response))
  }

  handleInputChange(e) {
    this.setState({
      bill: {
        ...this.state.bill,
        [e.target.name]: e.target.value
      }
    })
  }
  padFix(n) {
    return ('00000000'+n.toString()).match(/\S{8}$/);
  }

  handleSubmit() {
    var paymentAccounts = []
    var cashVoucher = {
      taxable: false
    }
    var payable = {}
    var forDelete = {}
    var billsContent = []
    var fordeleteBillsContent = this.state.fordeleteBillsContent
    if (this.state.billsContent.length > 0) {
      this.state.billsContent.map((content) => {
        if(content.account.name.includes('Accounts Payable')){
          payable = {
            account_code: content.account.code,
            account_id: content.account.id,
            amount_credit: content.amount_debit,
            amount_debit: content.amount_credit
          }
        }
        var item = {
          id: content.id,
          account_code: content.account.code,
          account_id: content.account.id,
          amount_credit: content.amount_credit,
          amount_debit: content.amount_debit
        }
        billsContent.push(item)
      })
    }
    if (this.state.paymentAccounts.length > 0) {
      this.state.paymentAccounts.map((content) => {
        var item = {
          id: content.id,
          account_code: content.account.code,
          account_id: content.account.id,
          amount_credit: content.amount_credit,
          amount_debit: content.amount_debit
        }
        paymentAccounts.push(item)
      })
    }
    const item = this.state.bill

    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/bills/'
      item["check_voucher_contents_attributes"] = billsContent
    }
    else {
      if (fordeleteBillsContent.length > 0) {
        fordeleteBillsContent.map((content) => {
          forDelete = {
            id: content.id,
            account_code: content.account.code,
            account_id: content.account.id,
            _destroy: true,
            amount_credit: '0.0',
            amount_debit: '0.0'
          }
          billsContent.push(forDelete)
        })
      }
      method = 'put'
      url = '/v1/bills/' + item.id
      item["check_voucher_contents_attributes"] = billsContent
    }
    item["payee_id"] = this.state.bill.payee_id
    item["expense_type_id"] = this.state.expenseType.id
    item["branch_id"] = this.state.bill.branch_id
    item["reference_no"]=this.state.bill.reference_no
    item["taxable"]=this.state.bill.taxable
    item["with_input_vat"]=this.state.withInputTax
    item["due_at"]=this.state.bill.due_at
    item["amount"]=this.state.bill.amount
    item['remarks']=this.state.bill.remarks

    cashVoucher['payee_id']=this.state.bill.payee_id
    cashVoucher["expense_type_id"] = this.state.expenseType.id
    cashVoucher["branch_id"] = this.state.bill.branch_id
    cashVoucher['remarks']=this.state.bill.remarks
    cashVoucher['prefix']=localStorage.getItem('cashvoucher_prefix') ? (localStorage.getItem('cashvoucher_prefix')) : 'CV'

    if(this.state.immediatePayment){
      axios.get('/v1/cash_vouchers/generate_ref_no', {
        params: { branch_id: this.state.branch.id},
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        if(resp.data.error===false){
          cashVoucher['reference_no'] = this.padFix(resp.data.reference_no)[0]
        }

      })
    }
    cashVoucher["filed_at"]=this.state.bill.filed_at

    cashVoucher["amount"]=this.state.bill.amount

    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      if(this.state.immediatePayment){
        cashVoucher['bill_ids']=[newData.id]
        cashVoucher["check_voucher_contents_attributes"] = [...paymentAccounts,payable]
        axios({
          method: 'post',
          url: '/v1/cash_vouchers',
          data: (cashVoucher),
          headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
        }).then(() => {
          this.setState({ bill: newData })
            this.setState({ redirect: true })

        })
      }else{
        this.setState({ bill: newData })
        this.setState({ redirect: true })
      }
    }).catch(error => {
      if (error.response.status === 422) {
        var errors = {}
        Object.keys(error.response.data.errors[0].detail.errors).map((field) => {
          errors[field] = error.response.data.errors[0].detail.errors[field][0]
        })
        this.setState({errorMessages: errors})
      }
    })

  }

  handleSwitch(event) {
    var index = this.state.billsContent.indexOf(this.state.billsContent.filter(item=>item.account_code===this.state.accountPayable.account.code)[0])
    const value = event.target.checked
    var inputTaxIndex = 0
    if(this.state.inputTax.code){
      inputTaxIndex = this.state.billsContent ? 
                        this.state.billsContent.indexOf(this.state.billsContent.filter(item=>item.account_code===this.state.inputTax.code)[0]) 
                        : -1
   }
    if (value === true) {
      const updatedCVContents = this.state.billsContent
      let amt = 0
      let item = this.state.withHoldingTax

      if(inputTaxIndex!==-1) {
        item['amount_credit']=  parseFloat(this.state.bill.amount/1.12 * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
        amt = parseFloat(this.state.bill.amount-(this.state.bill.amount/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
        updatedCVContents[index]["amount_credit"]= parseFloat(this.state.bill.amount-(this.state.bill.amount/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
      } else {
        item['amount_credit']=  parseFloat(this.state.bill.amount * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
        amt = parseFloat(this.state.bill.amount-(this.state.bill.amount * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
        updatedCVContents[index]["amount_credit"]= parseFloat(this.state.bill.amount-(this.state.bill.amount * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
      }

      this.setState({billsContent: [...updatedCVContents, item]})
      this.setState({bill: {...this.state.bill,
          amount:amt,
          taxable: value
        }})
    } else {
      var amtToAdd = 0
      var items = this.state.billsContent
      var inputAmt = 0
      if (this.state.billsContent.length > 0) {
        this.state.billsContent.map((voucher) => {
          if (voucher.account_code === this.state.withHoldingTax.account_code) {
            amtToAdd = voucher.amount_credit
            if (voucher.id !== undefined) {
              this.setState({ fordeleteBillsContent: [...this.state.fordeleteBillsContent, voucher] })
            }
          }
        })
      }
      items[index]["amount_credit"]=parseFloat(parseFloat(this.state.bill.amount) + parseFloat(amtToAdd)).toFixed(2)
      inputAmt = parseFloat(parseFloat(this.state.bill.amount) + parseFloat(amtToAdd)).toFixed(2)
      if(inputTaxIndex!==-1){
        items[inputTaxIndex]["amount_debit"]= parseFloat((inputAmt / 1.12 * .12) * 100 / 100).toFixed(2)
      }
      this.setState({ forEditBillsContent: [...this.state.forEditBillsContent.filter(item => item.id ===items[index].id), items[index]] })
      this.setState({
        bill: {...this.state.bill,
          amount: parseFloat(this.state.bill.amount) + parseFloat(amtToAdd),
          taxable: value
        }
      })
      this.setState({billsContent: items.filter(item => item.account_code !== this.state.withHoldingTax.account_code)})
    }
  }

  addBillsContent() {
    this.setState({ billsContent: [...this.state.billsContent, { ...this.state.initialBillsContent }] })
  }

  handleBillsContentChange(e) {
    var value = null

    const updatedVoucher = [...this.state.billsContent]
    if (e.target.dataset.fieldType === "amount_debit" || e.target.dataset.fieldType === "amount_credit") {
      value = parseFloat(e.target.value).toFixed(2)
    } else {
      value = e.target.value
    }
    updatedVoucher[e.target.dataset.id][e.target.dataset.fieldType] = value
    if (updatedVoucher[e.target.dataset.id]["id"] !== undefined) {
      this.setState({ forEditBillsContent: [...this.state.forEditBillsContent.filter(item => item.id !== updatedVoucher[e.target.dataset.id].id), updatedVoucher[e.target.dataset.id]] })
    }
    this.setState({ billsContent: updatedVoucher })
  }

  handleBillsContentAmount(event,idx,params){
    var value = 0
    if(event.floatValue!==undefined){
      value = event.floatValue

    }
    const updatedVoucher = [...this.state.billsContent]
    updatedVoucher[idx][params] = value
    if (updatedVoucher[idx]["id"] !== undefined) {
      this.setState({ forEditBillsContent: [...this.state.forEditBillsContent.filter(item => item.id !== updatedVoucher[idx].id), updatedVoucher[idx]] })
    }
    this.setState({ billsContent: updatedVoucher })
  }

  handleInputTax(event){
    const value = event.target.checked
    this.setState({withInputTax: value})
    let initialAmount = 0
    this.state.billsContent.forEach((content) => {
      initialAmount = parseFloat(initialAmount) + parseFloat(content.amount_credit)
    })

    if(!value){
      if (this.state.billsContent.length > 0) {
        this.state.billsContent.map((voucher) => {
          if (voucher.account_code === this.state.inputTax.code) {
            if (voucher.id !== undefined) {
              this.setState({ fordeleteBillsContent: [...this.state.fordeleteBillsContent, voucher] })
            }
          }
        })
      }

      if (this.state.bill.taxable) {
        let withHoldingTax = this.state.withHoldingTax
        let bank = this.state.accountPayable
        this.setState({
          billsContent: [
            ...this.state.billsContent.filter((content) => content.account_code !== bank.account_code && content.account_code !== withHoldingTax.account_code && content.account_code !== this.state.inputTax.code),
            {
              account_code: bank.account_code,
              account: bank.account,
              amount_debit: this.state.billsContent.find((content) => content.account_code === bank.account_code)?.amount_debit,
              amount_credit: parseFloat(initialAmount-(initialAmount * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
            },
            {
              ...withHoldingTax,
              amount_credit: parseFloat(initialAmount * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
            }
          ]
        })
        this.setState({
          bill: {
            ...this.state.bill,
            amount: parseFloat(initialAmount-(initialAmount * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
          }
        })
      } else {
        this.setState({
          billsContent: this.state.billsContent.filter(item => item.account_code !== this.state.inputTax.code)
        })
      }

    }else {
      let initialAmount = 0
      this.state.billsContent.forEach((content) => {
        initialAmount = parseFloat(initialAmount) + parseFloat(content.amount_credit)
      })

      if (this.state.bill.taxable) {
        let withHoldingTax = this.state.withHoldingTax
        let bank = this.state.accountPayable

        this.setState({
          billsContent: [
            {
              account_code: this.state.inputTax.code,
              account: this.state.inputTax,
              amount_debit: parseFloat(((parseFloat(initialAmount) / 1.12 * .12)  * 100) / 100).toFixed(2),
              amount_credit: 0
            },
            ...this.state.billsContent.filter((content) => content.account_code !== bank.account_code && content.account_code !== withHoldingTax.account_code),
            {
              account_code: bank.account_code,
              account: bank.account,
              amount_debit: this.state.billsContent.find((content) => content.account_code === bank.account_code)?.amount_debit,
              amount_credit: parseFloat(initialAmount-(initialAmount/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
            },
            {
              ...withHoldingTax,
              amount_credit: parseFloat(initialAmount/1.12 * parseFloat(this.state.expenseType.multiplier)).toFixed(2)
            }
          ]
        })
        this.setState({
          bill: {
            ...this.state.bill,
            amount: parseFloat(initialAmount-(initialAmount/1.12 * parseFloat(this.state.expenseType.multiplier))).toFixed(2)
          }
        })

      } else {
        this.setState({
          billsContent: [{
            account_code: this.state.inputTax.code,
            account: this.state.inputTax,
            amount_debit: parseFloat(((parseFloat(this.state.bill.amount) / 1.12 * .12) * 100) / 100).toFixed(2),
            amount_credit: 0
          }, ...this.state.billsContent]
        })
      }
    }
  }

  handleAutocomplete(event, value) {
    if (event.target.id.includes("payee")) {
      this.setState({ selectedPayee: value || {}, bill: { ...this.state.bill, payee_id: ![null, undefined].includes(value) ? (value.id) : '' } })
    } else if (event.target.id.includes("expenseType")) {
      this.setState({ expenseType: value})
      var index = this.state.billsContent.indexOf(this.state.billsContent.filter(item=>item.account_code===this.state.accountPayable.account.code)[0])
      if(this.state.bill.taxable){
        if(parseFloat(value.multiplier)>0){
          const updatedCVContents = this.state.billsContent.filter(item => item.account_code !== this.state.withHoldingTax.account_code)
          updatedCVContents[index]["amount_credit"]= parseFloat(this.state.bill.amount-(this.state.bill.amount/1.12 * parseFloat(value.multiplier))).toFixed(2)
          var item=this.state.withHoldingTax
          item['amount_credit']=  parseFloat(this.state.bill.amount/1.12 * parseFloat(value.multiplier)).toFixed(2)
          this.setState({billsContent: [...updatedCVContents, item]})
        }else{
          var items = this.state.billsContent
          items[index]["amount_credit"]=parseFloat(this.state.bill.amount)
          this.setState({billsContent: items.filter(item => item.account_code !== this.state.withHoldingTax.account_code)})
          this.setState({ bill:{...this.state.bill, taxable: false}
          })
        }
      }
    }else if (event.target.id.includes("bank")) {
      this.setState({ bank: value || {}, bill: { ...this.state.bill, bank_id: ![null, undefined].includes(value) ? (value.id) : '' } })
    }
    else if (event.target.id.includes("branch")) {
      this.setState({ branch: value || {}, bill: { ...this.state.bill, branch_id: ![null, undefined].includes(value) ? (value.id) : '' } })
    }
  }

  handleAutocompleteOnInputChange(event, value, reason) {
    if (reason === 'input') {
      if (event.target.id.includes("payee")) {
        this.setState({ payeesAutoCompleteLoading: true })
        this.loadPayeesAutoCompleteOptions(value || '')
      }
      else if (event.target.id.includes("bank")) {
        this.setState({ bankAutoCompleteLoading: true })
        this.loadBanksAutoCompleteOptions(value || '')
      }
      else if (event.target.id.includes("branch")) {
        this.setState({ branchAutoCompleteLoading: true })
        this.loadBranchAutoCompleteOptions(value || '')
      }
    }
  }

  removeBillsContent(idx, content) {
    var index =this.state.billsContent.indexOf(this.state.billsContent.filter(item=>item.account_code===this.state.accountPayable.account.code)[0])
    const updatedVouchers = [...this.state.billsContent]
    if(updatedVouchers[idx].account_code===this.state.withHoldingTax.account_code){

      this.setState({bill: {...this.state.bill,taxable:false}})
      updatedVouchers[index]['amount_credit']=parseFloat(this.state.bill.amount) + parseFloat(updatedVouchers[idx].amount_credit)
      this.setState({bill: {...this.state.bill,amount:parseFloat(this.state.bill.amount) + parseFloat(updatedVouchers[idx].amount_credit)} })
    }
    if(updatedVouchers[idx].account_code===this.state.inputTax.code){
      this.setState({withInputTax: false})
    }

    updatedVouchers.splice(idx, 1)
    if (content.id !== undefined) {
      var item = this.state.billsContent[idx]
      this.setState({ fordeleteBillsContent: [...this.state.fordeleteBillsContent, item] })
    }
    this.setState({ billsContent: updatedVouchers })
  }

  handleContentAutocomplete(event, values) {
    var updatedVoucher = [...this.state.billsContent]
    var editVouchers = [...this.state.forEditBillsContent]
    if (values !== null) {

      updatedVoucher[event]["account"] = values
      updatedVoucher[event]["account_code"] = values.code

      if (updatedVoucher[event]["id"] !== undefined) {
        this.setState({
          forEditBillsContent: this.state.forEditBillsContent.filter(item => item.id !== item.id)
        })
        this.setState({ forEditBillsContent: [...editVouchers, updatedVoucher[event]] })
      } else {
        this.setState({ billsContent: updatedVoucher })
      }
    } else {
      updatedVoucher = [...this.state.billsContent]
      updatedVoucher[event]["account"] = {}
      updatedVoucher[event]["account_code"] = ''
      this.setState({ billsContent: updatedVoucher })
    }

  }

  populateContent(params, value, tax) {
    var contents = []
    var inputTax = tax
   if(this.props.match.params.bill_id === undefined) {
    if (this.state.billsContent.length <= 2 ) {
      if (params.toLowerCase() === 'amount') {
        if (value !== 0 && this.state.withInputTax) {
          contents = [
            {
              account_code: inputTax.code,
              account: inputTax,
              amount_debit: parseFloat(((value / 1.12 * .12) * 100) / 100).toFixed(2),
              amount_credit: 0
            },
            {
              account_code: this.state.accountPayable.account_code,
              account: this.state.accountPayable.account,
              amount_debit: 0,
              amount_credit: value
            },
          ]
        }else {
          if(value !== 0) {
            contents = [
              {
                account_code: this.state.accountPayable.account_code,
                account: this.state.accountPayable.account,
                amount_debit: 0,
                amount_credit: value
              },
            ]
          }
        }
      }
      this.setState({ billsContent: contents })
    }
    }else {
      var arrayInputTax = {}
      this.state.billsContent.map((pair) => {
        if(pair.account_code === this.state.inputTax.code) {
          arrayInputTax = pair
        }
      })
      var index = this.state.billsContent.indexOf(this.state.billsContent.filter(item=>item.account_code===this.state.accountPayable.account_code)[0])
      var inputTaxIndex = this.state.billsContent.indexOf(this.state.billsContent.filter(item=>item.account_code===arrayInputTax.account_code)[0])
      contents = this.state.billsContent
      if (params.toLowerCase() === 'amount') {
        contents[index]["amount_credit"] = value
        if (this.state.withInputTax) {
          contents[inputTaxIndex]["amount_debit"] = parseFloat(((value / 1.12 * .12) * 100) / 100).toFixed(2)
        }
      }
      this.setState({ billsContent: contents })
    }
  }

  amountChange(value) {
    var index = this.state.billsContent.indexOf(this.state.billsContent.filter(item=>item.account_code===this.state.accountPayable.account.code)[0])
    this.setState({
      bill: {
        ...this.state.bill,
        amount: value,
        taxable: false,
      }
    })
    this.populateContent('amount', value, this.state.inputTax ? (this.state.inputTax) : null)
    if(this.state.bill.taxable){
      this.removeBillsContent(index,this.state.billsContent.filter(item=>item.account_code===this.state.withHoldingTax.account_code)[0])
    }
  }
  handleImmediatePayment(e){
    this.setState({immediatePayment: e.target.checked})
  }

  handlePaymentAccount(event,values){
    var updatedVoucher = [...this.state.paymentAccounts]

    if (values !== null) {

      updatedVoucher[event]["account"] = values
      updatedVoucher[event]["account_code"] = values.code


    } else {
      updatedVoucher[event]["account"] = {}
      updatedVoucher[event]["account_code"] = ''

    }
    this.setState({ paymentAccounts: updatedVoucher })
  }
  handlePaymentAmt(event,idx,params){
    var value = 0
    if(event.floatValue!==undefined){
      value = event.floatValue
    }
    const updatedVoucher = [...this.state.paymentAccounts]
    updatedVoucher[idx][params] = value

    this.setState({ paymentAccounts: updatedVoucher })
  }

  addPaymentAccount(){
    this.setState({paymentAccounts: [...this.state.paymentAccounts, {account_code: '',account: '',amount_credit:0, amount_debit:0}]})
  }

  removePaymentAccount(idx){
    const updatedVouchers = this.state.paymentAccounts
    updatedVouchers.splice(idx, 1)
    this.setState({ paymentAccounts: updatedVouchers })
  }


  render() {
    const { redirect } = this.state
    if (redirect)
      return (<Redirect to={{
        pathname: '/bills/' + this.state.bill.id
      }} />)
    return (

      this.state.load ?
        (
          <BillingForm
            handleSubmit={this.handleSubmit}
            handleAutocomplete={this.handleAutocomplete}
            handleAutocompleteOnInputChange={this.handleAutocompleteOnInputChange}
            addCheckVoucherContent={this.addBillsContent}
            handleInputChange={this.handleInputChange}
            handleContentAutocomplete={this.handleContentAutocomplete}
            handleCheckVoucherContentChange={this.handleBillsContentChange}
            removeCheckVoucherContent={this.removeBillsContent}
            handleCheckVoucherContentAmount={this.handleBillsContentAmount}
            amountChange={this.amountChange}
            handleInputTax={this.handleInputTax}
            handleSwitch={this.handleSwitch}
            checkVoucherContent={this.state.billsContent}
            accounts={this.state.accounts}
            error={this.state.errorMessages}
            item={this.state.bill}
            branches={this.state.branches}
            branch={this.state.branch}
            branchAutoCompleteLoading={this.state.branchAutoCompleteLoading}
            bankAutoCompleteLoading={this.state.bankAutoCompleteLoading}
            payeesAutoCompleteLoading={this.state.payeesAutoCompleteLoading}
            withInputTax={this.state.withInputTax}
            expenseTypes={this.state.expenseTypes}
            expenseType={this.state.expenseType}
            payees={this.state.payees}
            accountPayable={this.state.accountPayable}
            selectedPayee={this.state.selectedPayee}
            paymentAccounts={this.state.paymentAccounts}
            handlePaymentAccount={this.handlePaymentAccount}
            handlePaymentAmt={this.handlePaymentAmt}
            immediatePayment={this.state.immediatePayment}
            handleImmediatePayment={this.handleImmediatePayment}
            addPaymentAccount={this.addPaymentAccount}
            removePaymentAccount={this.removePaymentAccount}
          />
      ) :  <ProgressBar model={this.state.model}/>
    )
  }
}

export default BIllNewAndUpdate

BIllNewAndUpdate.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      bill_id: PropTypes.string
    })
  }),
  location: PropTypes.object
}
