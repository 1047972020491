// eslint-disable-next-line


import React from 'react'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import SimplePopUp from '../../shared/SimplePopUp/SimplePopUp'
import SimpleDeletePopUp from '../../shared/SimpleDeletePopUp/SimpleDeletePopUp'
import ExpenseTypeForm from './ExpenseTypeForm'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'

import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import SimpleDialogForm from '../../shared/SimplePopUp/SimpleDialogForm'
class ExpenseTypes extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      model: 'Expense Type',
      expenseTypes: [],
      openPopup: false,
      openDeletePopup: false,
      expenseType: {},
      initialExpenseType: { id: '', name: '',address:'',classification: 'vatable',tin:'',business_style: '' },
      title: '',
      load: false,
      tableHead: ['Name','description','multiplier'],
      withShow: false,
      withEdit: true,
      maxWidth: 'sm',
      errorMessages: {},
      notify: {  },
      isOpen: false,
      message: '',
      type: '',
      showpopup: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleShowPopUp = this.handleShowPopUp.bind(this)
  }

  componentDidMount () {
    this.loadexpenseTypes('/v1/check_voucher_expense_types')
  }

  loadexpenseTypes (url) {
    axios({
      method: 'get',
      url: url,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ expenseTypes: resp.data })
      this.setState({ load: true })
    }).catch(error => {
      this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Occurred",type: 'error'})
    })
  }

  handleSubmit () {
    const item = this.state.expenseType
    var method = ''
    var url = ''
    if (item.id === '') {
      method = 'post'
      url = '/v1/check_voucher_expense_types/'
    } else {
      method = 'put'
      url = '/v1/check_voucher_expense_types/' + item.id
    }
    axios({
      method: method,
      url: url,
      data: (item),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {

      const newData = resp.data
      this.setState({
        expenseTypes: this.state.expenseTypes.filter(expenseType => expenseType.id !== item.id)
      })
      this.setState({ expenseTypes: [...this.state.expenseTypes, newData] })
      this.setState({ openPopup: false })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    }).catch(error => {
      if (error.response.status === 422) {
        var errors = {}
        Object.keys(error.response.data.errors[0].detail.errors).map((field)=>{
          errors[field] = error.response.data.errors[0].detail.errors[field][0]
         })
         this.setState({errorMessages: errors})
      } else {
        this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Occurred",type: 'error'})
        this.setState({ openPopup: false })
      }
    })
  }

  handleDeleteItem () {
    const DeleteItemId = this.state.expenseType.id
    axios({
      method: 'delete',
      url: '/v1/check_voucher_expense_types/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({
        expenseTypes: this.state.expenseTypes.filter(item => item.id !== DeleteItemId)
      })
      this.setState({ openDeletePopup: false })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
    }).catch(error => {
        this.setState({isOpen: true,message: error.response.status.toString() + " Unexpected Error Occurred",type: 'error'})
        this.setState({openPopup: false})
    })
  }

  handleClose () {
    this.setState({ openPopup: false,openDeletePopup: false ,errorMessages: {}, showpopup: false})
  }

  handleCreateorUpdateItem (item, isAdd, model) {
    this.setState({errorMessages: {}})
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true,expenseType: item,title: textTitle  })
  }

  handleInputChange (e) {
    this.setState({
      expenseType: {
        ...this.state.expenseType,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup (item, model) {
    this.setState({ model: model,expenseType: item,openDeletePopup: true })
  }

  handleDeleteClose () {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert (event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleShowPopUp(item){
    this.setState({ expenseType: item,showpopup:true})
  }

  render () {
    return (
      <>
        {
          this.state.load ? (
            <div>
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialExpenseType}
                items={this.state.expenseTypes}
                model={this.state.model}
                onOpenDeletePopup={this.handleOpenDeletePopup}
                headers={this.state.tableHead}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                icon={<AccountBalanceWalletIcon fontSize="large"/>}
                withShowPopUp={true}
                handleShowPopUp = {this.handleShowPopUp}
              />

              <SimplePopUp
                openPopup={this.state.openPopup}
                title={this.state.title}
                handleClose={this.handleClose}
                maxWidth={this.state.maxWidth}
              >
                <ExpenseTypeForm 
                  error={this.state.errorMessages}
                  item={this.state.expenseType}
                  submit={this.handleSubmit}
                  onchange={this.handleInputChange} />
              </SimplePopUp>
              
              <SimplePopUp
                openPopup={this.state.showpopup}
                title={`Expense Type - ${this.state.expenseType.id}`}
                handleClose={this.handleClose}
                maxWidth="md"
              >
                {this.state.expenseType? <SimpleDialogForm item={this.state.expenseType} fields={['name','multiplier','description']}/>:null}
              </SimplePopUp>

              <SimpleDeletePopUp
                openDeletePopup={this.state.openDeletePopup}
                item={this.state.expenseType}
                delete={this.handleDeleteItem}
                handleDeleteClose={this.handleClose}
                model={this.state.model}
              />
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default ExpenseTypes
