import React from 'react'
import styles from './CashVoucher.module.css'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import PropTypes from 'prop-types'

import {
  TextField,
  Button, TableContainer, Grid,
} from '@material-ui/core'
import converter from 'number-to-words'
import {
  Table, TableHead, TableCell, TableRow, IconButton, InputAdornment, TableBody, Tooltip
} from '@material-ui/core'

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import CashVoucherContentForm from "./CashVoucherContentForm";
import Autocomplete from '@material-ui/lab/Autocomplete'
import BillsForm from '../CheckVoucher/BillsForm'
import RefreshIcon from '@material-ui/icons/Refresh';

class CashVoucherForm extends React.Component {
  getTotalDebit() {
		var totalDebit = 0
		if (this.props.cashVoucherContent.length > 0) {
			totalDebit = this.props.cashVoucherContent.reduce((a, b) => parseFloat(parseFloat(a + parseFloat(b.amount_debit)).toFixed(2)), 0)
		}
		return totalDebit
	}

	getTotalCredit() {
		var totalCredit = 0
		if (this.props.cashVoucherContent.length > 0) {
			totalCredit = this.props.cashVoucherContent.reduce((a, b) => parseFloat(parseFloat(a + parseFloat(b.amount_credit)).toFixed(2)), 0)
		}
		return totalCredit
	}
	getBalance() {
		var balance = 0
		if (this.props.cashVoucherContent.length > 0) {
			balance = parseFloat(this.getTotalCredit() - this.getTotalDebit()).toFixed(2)
		}
		return balance
	}


  checkInputTax(){
    var resp = true
    if(this.props.withInputTax){
      if(this.props.cashVoucherContent.length>2){
        resp = false
      }
    }else{
      if(this.props.cashVoucherContent.length>1){
        resp = false
      }
    }
    return resp
  }


  getTotalBills(){
    var amt = 0
    if(this.props.selectedBills.length>0){
      this.props.selectedBills.map((bill) => {
        amt = amt+ parseFloat(bill.amount)
      })
    }

    return amt
  }

  render() {
    return (
      <div>
        <form>

          <div className={styles.group2Textfield}>
            <div className={styles.autoCompleteField}>
              <Autocomplete
                value={this.props.branch}
                id="branch"
                name="branch"
                fullWidth
                size="small"
                options={(this.props.branches).sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                })}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocomplete}
                renderInput={(params) => (
                  <TextField {...params} name="branch" variant="outlined" label="Branch" />
                )}
              />
            </div>
            <TextField
              autoComplete="off"
              variant="outlined"
              className={styles.textFields}
              size="small"
              name="reference_no"
              fullWidth
              label="Voucher Number"
              onChange={this.props.handleInputChange}
              value={this.props.cashVoucher.reference_no}
              InputProps={{
                startAdornment: <InputAdornment position='start'><div>{this.props.cashVoucher.prefix}</div></InputAdornment>,
                endAdornment:
                  <InputAdornment position="end" >{this.props.cashVoucher.reference_no ? <Tooltip title="Get Reference No"><IconButton id="refresh_ref_no" name="refresh_ref_no" onClick={this.props.getReferenceNo}><RefreshIcon /></IconButton></Tooltip> : ''}</InputAdornment>,
              }}

            />

          </div>
          <div className={styles.group2Textfield}>
            <div className={styles.autoCompleteField}>
              <Autocomplete
                value={this.props.payee}
                id="payee"
                name="payee"
                fullWidth
                size="small"
                options={(this.props.payees).sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                })}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocomplete}
                onInputChange={this.props.handleAutocompleteSearch}
                renderInput={(params) => (
                  <TextField {...params} name="payee" variant="outlined" label="Payee" />
                )}
              />
            </div>

            <TextField
              autoComplete="off"
              variant="outlined"
              type="date"
              className={styles.textFields}
              size="small"
              name="filed_at"
              fullWidth
              label="Date Filed"
              onChange={this.props.handleInputChange}
              value={this.props.cashVoucher.filed_at}
              InputLabelProps={{
                shrink: true
              }}
            />

          </div>
          <div className={styles.group2Textfield}>


          </div>
          <div className={styles.group2Textfield}>
            <div className={styles.amountBody}>
              <CurrencyTextField
                label="Amount"
                value={this.props.cashVoucher.amount}
                currencySymbol=""
                fullWidth
                size="small"
                name="amount"
                outputFormat="number"
                variant="outlined"
                className={styles.textFields}
                decimalCharacter="."
                digitGroupSeparator=","
                onChange={(event, value) => this.props.amountChange(value)}
              />
            </div>
            <div className={styles.autoCompleteField}>
              <Autocomplete
                value={this.props.expenseType}
                id="expenseType"
                name="expenseType"
                fullWidth
                size="small"
                options={(this.props.expenseTypes).sort(function (a, b) {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                  return 0;
                })}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option.name === value.name}
                onChange={this.props.handleAutocomplete}
                renderInput={(params) => (
                  <TextField {...params} name="expenseType" variant="outlined" label="Expense Type" />
                )}
              />
            </div>



          </div>

          <div className={styles.detailListCustom}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
              <div className={styles.amountInWordForm}>
              <div><b> &nbsp;&nbsp;  Amount in Words </b></div>
              <div><b>
                <i>

                  {this.props.cashVoucher.amount > 0 ?
                    (
                      <div className={styles.amtWordBody}>
                        {`:`}&nbsp;&nbsp;
                        {`${converter.toWords(this.props.cashVoucher.amount.toString().split('.')[0])}`}
                        {/*+*/}
                        {this.props.cashVoucher.amount.toString().split('.').length === 2 && this.props.cashVoucher.amount.toString().split('.')[1].toString !== null ?
                          (
                            <span>
                              <span className={styles.wordAnd}> and </span>
                              {this.props.cashVoucher.amount.toString().split('.')[1]}
                              {(this.props.cashVoucher.amount.toString().split('.')[1].length === 1 ? ('0') : ('')).toString()}
                              /100 Pesos Only
                            </span>
                          ) : <span><span className={styles.wordAnd}> and </span> 00/100 Pesos Only</span>
                        }
                      </div>
                    )
                    : null}
                </i>
              </b>
              </div>
              </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className={styles.group2TextfieldForms}>
                  <div>
                    <FormControlLabel
                      control={<Switch
                        size="medium"
                        disabled={this.props.expenseType === undefined || this.props.expenseType.multiplier === undefined
                          || parseFloat(this.props.expenseType.multiplier) <= 0 || this.props.isBillTaxExpanded }
                        checked={this.props.cashVoucher.taxable}
                        onChange={this.props.handleSwitch}
                        name="taxable" />}
                      label="Tax Expandable"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={<Switch
                        size="medium"
                        disabled={this.props.isBillWithInputTax || this.props.isBillTaxExpanded}
                        checked={this.props.withInputTax}
                        onChange={this.props.handleInputTax}
                        name="withInputTax" />}
                      label="With Input VAT "
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>




          <TextField
            required
            autoComplete="off"
            variant="outlined"
            className={styles.textFields}
            size="small"
            name="remarks"
            fullWidth
            label="Particulars"
            onChange={this.props.handleInputChange}
            value={this.props.cashVoucher.remarks}
          />
          {
            this.props.fromBills?null:
            <div className={styles.actionButton}>
              <div>
                <Button 
                  color="primary" 
                  variant="outlined" 
                  disabled={this.props.payee ? Object.keys(this.props.payee).length === 0 ? true : false : true} 
                  onClick={this.props.openSelectBills} 
                >
                  <b>Select Bills</b>
                </Button>
              </div>
            </div>
          }

        </form>
{
          this.props.selectedBills.length > 0 ? <>

            <div className={styles.headerContent}>
              <div> Bill(s)</div>

            </div>
            <TableContainer style={{maxHeight: '575px', position: 'relative'}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={styles.refNo}>
                    <b>Reference No.</b>
                  </TableCell>
                  <TableCell className={styles.small}>
                    <b>Due Date</b>
                  </TableCell>
                  <TableCell className={styles.particulars}>
                    <b>Particulars</b>
                  </TableCell>
                  <TableCell className={styles.small}>
                    <b>Taxable</b>
                  </TableCell>
                  <TableCell className={styles.small}>
                    <b>Input VAT</b>
                  </TableCell>
                  <TableCell align="right" className={styles.amtheader}>
                    <b>Amount</b>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody  name="bills_content">
                {this.props.selectedBills.map((val, idx) => (
                  <BillsForm
                    key={`bills_content-${idx}`}
                    idx={idx}
                    selectedBills={this.props.selectedBills}
                    removeBill={this.props.removeBill}
                  />
                ))}
                <TableRow>
                <TableCell className={styles.refNo} />
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right" colSpan={2}><b>Total Amount :</b></TableCell>
                  <TableCell align="right" className={styles.totalcellAmount}><b>&#8369; {this.getTotalBills().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</b></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </TableContainer>
          </>
            : null
        }
        {this.props.cashVoucher.amount > 0 ? (
          <>
            <div className={styles.headerContent}>
              <div> Cash Voucher Content</div>
              <div> Balance: {this.getBalance()}</div>

              <IconButton
                variant="addAddress"
                name="add_cash_voucher_content"
                onClick={this.props.addCashVoucherContent}>
                <AddCircleOutlineIcon fontSize="medium" />
              </IconButton>
            </div>
            <TableContainer style={{maxHeight: '575px', position: 'relative'}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <b>Account Code</b>
                  </TableCell>
                  <TableCell>
                    <b>Account Name</b>
                  </TableCell>
                  <TableCell>

                  </TableCell>
                  <TableCell align="right">
                    <b>Debit</b>
                  </TableCell>
                  <TableCell align="right">
                    <b>Credit</b>
                  </TableCell>
                  <TableCell>

                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody className={styles.tableBody} name="check_content">
                {this.props.cashVoucherContent.map((val, idx) => (
                  <CashVoucherContentForm
                    key={`check_content-${idx}`}
                    idx={idx}
                    name={`account_content_${idx}`}
                    handleCashVoucherContentChange={this.props.handleCashVoucherContentChange}
                    removeCashVoucherContent={this.props.removeCashVoucherContent}
                    accounts={this.props.accounts}
                    handleAutocomplete={this.props.handleContentAutocomplete}
                    cashVoucherContent={this.props.cashVoucherContent}
                    handleCashVoucherContentAmount={this.props.handleCashVoucherContentAmount}
                    contentBank={this.props.contentBank}
                  />

                ))}

                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right"><b>Total :</b></TableCell>
                  <TableCell align="right">&#8369; {this.getTotalDebit().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                  <TableCell align="right">&#8369; {this.getTotalCredit().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            </TableContainer>
          </>
        ) : null}
        <div className={styles.actionButton}>
          <Button variant="outlined" id="back" name="back"
            onClick={() => this.props.backToIndex()}>Back</Button>
          <Button variant="outlined" id="voucher-submit" name="voucher-submit" color="primary" disabled={this.getTotalCredit() !== this.getTotalDebit()
            || this.props.branch === null
            || this.props.branch.id === undefined
            || this.props.payee === null
            || this.props.payee.id === undefined
            || this.props.cashVoucher.remarks === ""
            || !this.props.cashVoucherContent.length > 0
          }
            onClick={() => this.props.handleSubmit()}>Save</Button>
        </div>
      </div>
    )
  }
}
export default CashVoucherForm

CashVoucherForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleAutocomplete: PropTypes.func,
  handleInputChange: PropTypes.func,
  cashVoucher: PropTypes.object,
  cashVoucherContent: PropTypes.array,
  payees: PropTypes.array,
  banks: PropTypes.array,
  expenseTypes: PropTypes.array,
  handleCashVoucherContentChange: PropTypes.func,
  removeCashVoucherContent: PropTypes.func,
  accounts: PropTypes.array,
  branches: PropTypes.array,
  expenseType: PropTypes.object,
  addCashVoucherContent: PropTypes.func,
  handleSwitch: PropTypes.func,
  error: PropTypes.object,
  amountChange: PropTypes.func,
  branch: PropTypes.object,
  payee: PropTypes.object,
  bank: PropTypes.object,
  handleContentAutocomplete: PropTypes.func,
  handleCashVoucherContentAmount: PropTypes.func,
  backToIndex: PropTypes.func,
  handleAutocompleteSearch: PropTypes.func,
  withInputTax: PropTypes.bool,
  handleInputTax: PropTypes.func,
  contentBank: PropTypes.object,
  selectedBills: PropTypes.array,
  openSelectBills: PropTypes.func,
  setAmt: PropTypes.func,
  fromBills: PropTypes.bool,
  getReferenceNo: PropTypes.func,
  removeBill: PropTypes.func,
  isBillWithInputTax: PropTypes.bool,
  isBillTaxExpanded:PropTypes.bool
}
