// eslint-disable-next-line

import React from 'react'
import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import { withRouter, Redirect } from 'react-router-dom'
import axios from 'axios'
import AlertMessage from "../../shared/Notify/AlertMessage";
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import BookIcon from '@material-ui/icons/Book'
import PropTypes from "prop-types";
import CheckVoucherSearch from '../CheckVoucher/CheckVoucherSearch'
import moment from 'moment';
import * as Excel from 'exceljs'
import { saveAs } from 'file-saver'
export const Component = withRouter(() => {

})
class CheckVoucher extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      model: 'Check Voucher',
      checkVouchers: [],
      banks: [],
      payees: [],
      branches: [],
      openPopup: false,
      openDeletePopup: false,
      checkVoucher: {},
      redirectToAdd: false,
      initialCheckVoucher: { id: '',
        date_of_check: '',
        prefix: '',
        check_no: '',
        reference_no: '',
        bank_id: '',
        branch_id: '',
        payee_id: '',
        journal_id: '',
        post_status: '',
      },
      searchCheckVoucher: {},
      title: '',
      load: false,
      tableHead: ['date_of_check', 'prefix', 'check_no', 'bank.name', 'payee.name', 'remarks', 'amount', 'post_status','status' ],
      tableHeadForPayeeShow: ['date_of_check', 'prefix', 'check_no', 'bank.name','remarks', 'amount', 'post_status','status' ],
      withShow: false,
      withPagination: true,
      totalPages: 1,
      currentPage: 1,
      maxWidth: 'sm',
      error_messages: '',
      isOpen: false,
      message: '',
      type: '',
      totalResults: 0,
      withResultsLabel: false,
      expenseTypes: [],
      perPage: '20',
      searchUrl: '/v1/check_vouchers/search',
      isSearchStartFromSet: false
    }
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleCreateorUpdateItem = this.handleCreateorUpdateItem.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.handleAutocompleteSearch = this.handleAutocompleteSearch.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handlePerPageChange=this.handlePerPageChange.bind(this)
    this.transformDataToExcelFormat = this.transformDataToExcelFormat.bind(this)
    this.exportToXlsx = this.exportToXlsx.bind(this)
    this.handleXlsxDownloadClick = this.handleXlsxDownloadClick.bind(this)
  }

  componentDidMount () {
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    var perPage = ''
    const params = new URLSearchParams(this.props.location.search)
    params.forEach(function(value, key) {
      if (key !== 'page'&& key !== 'branch_id'&&key !== 'payee_id'&&key !== 'bank_id') {
        searchParams[key] = value.replace(/\*/g,'')
      }
      if(key === 'per_page'){perPage=value}
    })
    if(params.get('branch_id')){
      axios({
        method: 'get',
        url: '/v1/branches/'+params.get('branch_id'),
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        searchParams.branch = resp.data
      })
    }
    if(params.get('payee_id')){
      axios({
        method: 'get',
        url: '/v1/payees/'+params.get('payee_id'),
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        searchParams.payee = resp.data
      })
    }
    if(params.get('bank_id')){
      axios({
        method: 'get',
        url: '/v1/banks/'+params.get('bank_id'),
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        searchParams.bank = resp.data
      })
    }
    if(params.get('expense_type_id')){
      axios({
        method: 'get',
        url: '/v1/check_voucher_expense_types/'+params.get('expense_type_id'),
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        searchParams.expense_type = resp.data
      })
    }
    if(perPage){this.setState({perPage: perPage})}
    this.setState({ searchCheckVoucher: searchParams })
    this.loadCheckVoucher(searchUrl, true)
  }

  loadCheckVoucher (url, firstLoad) {
    axios({
      method: 'get',
      url: url.includes('?') ? url+'&sort_by[date_of_check]=desc': url+'?sort_by[date_of_check]=desc',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ checkVouchers: resp.data.check_vouchers,totalPages: resp.data.meta.total_pages,currentPage: resp.data.meta.current_page })
      this.setState({ withResultsLabel: true,totalResults: resp.data.meta.total_count })
      this.setState({ load: true }) 
    }).catch(error => {
      this.setState({
        isOpen: true,
        message: error.response.status.toString() + " Unexpected Error Problem Occurred",
        type: 'error'
      })
    })
    if(firstLoad){    
    axios({
      method: 'get',
      url: '/v1/banks/search?per_page=1000',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ banks: resp.data.banks })
    })
    axios({
      method: 'get',
      url: '/v1/payees/search?per_page=10000',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ payees: resp.data.payees })
    })
    axios({
      method: 'get',
      url: '/v1/branches',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ branches: resp.data })
    })
    axios({
      method: 'get',
      url: '/v1/check_voucher_expense_types',
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({ expenseTypes: resp.data })
    }).catch(error => {
      this.setState({
        isOpen: true,
        message: error.response.status.toString() + " Unexpected Error Problem Occurred",
        type: 'error'
      })
    })
    }
  }

  handleDeleteItem () {
    const DeleteItemId = this.state.checkVoucher.id
    axios({
      method: 'delete',
      url: '/v1/check_vouchers/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
    }).then(() => {
      this.setState({
        checkVouchers: this.state.checkVouchers.filter(item => item.id !== DeleteItemId)
      })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
      this.setState({ openDeletePopup: false })
    }).catch(error => {
      this.setState({
        isOpen: true,
        message: error.response.status.toString() + " Unexpected Error Problem Occurred",
        type: 'error'
      })
    })
  }

  handleClose () {
    this.setState({ openPopup: false })
    this.setState({ openDeletePopup: false })
    this.setState({ error_messages: ''})
  }

  handleCreateorUpdateItem (item, isAdd, model) {
    var textTitle = ''
    if (isAdd) {
      textTitle = 'Create ' + model
      this.setState({redirectToAdd: true})
    } else {
      textTitle = 'Edit ' + model
    }
    this.setState({ openPopup: true })
    this.setState({ checkVoucher: item })
    this.setState({ title: textTitle })
  }

  handleInputChange (e) {
    this.setState({
      checkVoucher: {
        ...this.state.checkVoucher,
        [e.target.name]: e.target.value
      }
    })
  }

  handleOpenDeletePopup (item, model) {
    this.setState({ model: model })
    this.setState({ checkVoucher: item })
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose () {
    this.setState({ openDeletePopup: false })
  }

  handleCloseAlert (event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      isOpen: false
    })
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadCheckVoucher([this.state.searchUrl, searchParams].join("?"),false)
  }

  handleSearchInputChange(e) {
    this.setState({
      searchCheckVoucher: {
        ...this.state.searchCheckVoucher,
        [e.target.name]: e.target.value
      }
    })
  }


  handleAutocompleteSearch(event, values) {
    if (event.target.id.includes("bank")) {
      this.setState({searchCheckVoucher:{...this.state.searchCheckVoucher,bank: values}})
    } else if (event.target.id.includes("branch")) {
      this.setState({searchCheckVoucher:{...this.state.searchCheckVoucher,branch: values}})
    } else if (event.target.id.includes("payee")) {
      this.setState({searchCheckVoucher:{...this.state.searchCheckVoucher,payee: values}})
    }else if (event.target.id.includes("expense_type")) {
      this.setState({searchCheckVoucher:{...this.state.searchCheckVoucher,expense_type: values}})
    }
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadCheckVoucher([this.state.searchUrl, searchParams].join("?"), false)
    this.setState({
      isSearchStartFromSet: 
        this.state.searchCheckVoucher.date_of_check_from &&
        this.state.searchCheckVoucher.date_of_check_to ? true : false,
      searchParams
    })
  }

  clearSearch() {
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", 'per_page='+this.state.perPage)
    history.push({ search: 'per_page='+this.state.perPage })
    this.loadCheckVoucher(this.state.searchUrl,false)
    this.setState({
      isSearchStartFromSet: false
    })
    this.setState({ searchCheckVoucher: { q: '', date_of_check_from: '', date_of_check_to: '', payee_id: "", bank_id: '', branch: {}, status: '', remarks: '', reference_no: '', check_no: ''} })
  }

  handlePerPageChange(e){
    var searchParams = this.getSearchParams()
    searchParams.map((value, index) => {
      if(value.includes("per_page")) {
        searchParams.splice(index, 1)
      }
    })
    searchParams.push(["per_page", e.target.value].join("="))
    searchParams = searchParams.join("&")
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ perPage: e.target.value })
    this.loadCheckVoucher([this.state.searchUrl, searchParams].join("?"),false)
    
  }
  getSearchParams() {
    var searchParams = []
    Object.entries(this.state.searchCheckVoucher).map(([key, value]) => {
      if (key!=='per_page'&&key!=='branch'&&key!=='payee'&&key!=='bank'&&key!=='expense_type' &&value != '' && value != undefined && value != null) {
        searchParams.push([key,  key==='reference_no'?value: value ].join("="))
      }
    })
    if(this.state.searchCheckVoucher.branch){
      if(this.state.searchCheckVoucher.branch.id){
        searchParams.push('branch_id='+this.state.searchCheckVoucher.branch.id.toString())    
      }
    }
    if(this.state.searchCheckVoucher.payee){
      if(this.state.searchCheckVoucher.payee.id){
        searchParams.push('payee_id='+this.state.searchCheckVoucher.payee.id.toString())    
      }
    }
    if(this.state.searchCheckVoucher.bank){
      if(this.state.searchCheckVoucher.bank.id){
        searchParams.push('bank_id='+this.state.searchCheckVoucher.bank.id.toString())    
      }
    }
    if(this.state.searchCheckVoucher.expense_type){
      if(this.state.searchCheckVoucher.expense_type.id){
        searchParams.push('expense_type_id='+this.state.searchCheckVoucher.expense_type.id.toString())    
      }
    }
    searchParams.push('per_page='+this.state.perPage)
    return searchParams
  }

  transformDataToExcelFormat(data) {
    const checkVouchers = data.check_vouchers

    const rows = checkVouchers.map((item) => {
      const { 
        date_of_check, 
        prefix, 
        reference_no, 
        check_no, 
        bank, 
        payee, 
        remarks, 
        amount 
      } = item

      return {
        dateOfCheck: moment(date_of_check).format("MMMM DD YYYY"),
        voucherNo: `${prefix}${reference_no}`,
        checkNo: check_no,
        bank: bank.name,
        payee: payee.name,
        particulars: remarks,
        amount: Number(amount).toFixed(2)
      }
    })

    return rows
  }

  exportToXlsx(dataToPrint) {
    const workbook = new Excel.Workbook()
    const worksheet = workbook.addWorksheet('Check Vouchers')
    worksheet.getCell('A1').value = 'Check Vouchers'
    worksheet.getCell('A1').font = {
      size: 16,
      bold: true
    }
    worksheet.getCell('A1').alignment = { horizontal: 'center' }
    worksheet.getCell('A1').border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    }
    worksheet.getRow(2).values = [
      'Date of Check',
      'Voucher No.',
      'Check No',
      'Bank',
      'Payee',
      'Particulars',
      'Amount'
    ]
    worksheet.columns = [
      {
        key: 'dateOfCheck',
        width: 20
      },
      {
        key: 'voucherNo',
        width: 20
      },
      {
        key: 'checkNo',
        width: 20
      },
      {
        key: 'bank',
        width: 25
      },
      {
        key: 'payee',
        width: 25
      },
      {
        key: 'particulars',
        width: 30
      },
      {
        key: 'amount',
        width: 20
      },
    ]
    dataToPrint
    .sort((a, b) => {
      if (Number(a.checkNo) < Number(b.checkNo)) return -1
      return 1
    })
    .forEach((data) => worksheet.addRow(data))
    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      row.eachCell((cell) => {
        if (rowNumber === 2) {
          cell.font = { size: 14, bold: true }
          cell.alignment = { horizontal: 'center' }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          }
        }
        if(rowNumber > 2) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          }
          cell.alignment = {
            vertical: 'middle',
            wrapText: true
          }
        }
      })
    })
    workbook.xlsx
    .writeBuffer()
    .then((res) => {
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const blob = new Blob([res], {
        type: fileType
      })
      saveAs(blob, `check_vouchers.xlsx`)
      this.setState({
        isOpen: true,
        message: 'File saved!',
        type: 'success'
      })
    })
    .catch(() => {
      this.setState({
        isOpen: true,
        message: 'Error occured while saving data..',
        type: 'error'
      })
    })
  }

  async handleXlsxDownloadClick() {
    if (this.state.isSearchStartFromSet) {
      const url = `${this.state.searchUrl}?${this.state.searchParams}&per_page=${this.state.totalResults}`
      const checkVoucherPromise = await axios({
        method: 'get',
        url: url.includes('?') ? url+'&sort_by[date_of_check]=desc': url+'?sort_by[date_of_check]=desc',
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      })
      if(checkVoucherPromise.status === 200) {
        const dataToPrint = this.transformDataToExcelFormat(checkVoucherPromise.data)
        this.exportToXlsx(dataToPrint)
      }
    }
  }

  render () {
    if(this.state.redirectToAdd){
      return (<Redirect to={{
        pathname: this.props.checkVoucherPayee? ('/payees/' + this.props.payeeId + '/check_vouchers/new'):('/check_voucher/new')
      }} />)
    }
    return (
      <>
        {
          this.state.load ? (
            <div>
              {this.props.checkVoucherPayee !== true ? (
              <CheckVoucherSearch
                item={this.state.searchCheckVoucher}
                clearSearch={this.clearSearch}
                banks={this.state.banks}
                branches={this.state.branches}
                payees={this.state.payees}
                handleChange={this.handleSearchInputChange}
                handleAutocompleteSearch={this.handleAutocompleteSearch}
                handleKeyPress={this.handleKeyPress}
                search={this.handleSearch}
                expenseTypes={this.state.expenseTypes}
              />
              ): null }
              <SimpleTable
                handleClick={this.handleCreateorUpdateItem}
                initialItem={this.state.initialCheckVoucher}
                items={this.props.checkVoucherPayee ? (this.props.checkVoucherPayees):(this.state.checkVouchers)}
                checkVoucherPayee={this.props.checkVoucherPayee}
                model={this.state.model}
                headers={this.props.checkVoucherPayee ? (this.state.tableHeadForPayeeShow):(this.state.tableHead)}
                withShow={this.state.withShow}
                withEdit={this.state.withEdit}
                totalPages={this.state.totalPages}
                withPagination={this.props.checkVoucherPayee ? null:(this.state.withPagination)}
                currentPage={this.state.currentPage}
                item={this.state.checkVoucher}
                handlePageChange={this.handlePageChange}
                icon={<BookIcon fontSize="large"/>}
                payeeId={this.props.payeeId}
                withResultsLabel={this.props.checkVoucherPayee ? null:(this.state.withPagination)}
                totalResults={this.state.totalResults}
                perPage={this.state.perPage}
                handlePerPageChange={this.handlePerPageChange}
                withXlsxDownload={true}
                handleXlsxDownloadClick={this.handleXlsxDownloadClick}
                isSearchStartFromSet={this.state.isSearchStartFromSet}
              />

              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>

          ) : (
            this.props.checkVoucherPayee !== true  ? (
                <ProgressBar model={this.state.model}/>
              ):null
          )}
      </>
    )
  }
}

export default withRouter(CheckVoucher)

CheckVoucher.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  checkVoucherPayees: PropTypes.array,
  checkVoucherPayee: PropTypes.bool,
  payeeId: PropTypes.number
}
