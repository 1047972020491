import React from 'react'
import styles from './Journal.module.css'
import SimpleDeletePopUp from '../../SimpleDeletePopUp/SimpleDeletePopUp';
import PropTypes from 'prop-types'
import axios from "axios";
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link, Redirect } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import JournalEntriesDetailTab from './JournalEntriesDetailTab';
import AttachmentDetailTab from "./AttachmentDetailTab";
import SimplePopUp from "../../SimplePopUp/SimplePopUp";
import AttachmentForm from "../../../pages/CheckVoucher/Attachments/AttachmentForm";
import { Grid, Tooltip, Button } from "@material-ui/core";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined'
import PrintIcon from '@material-ui/icons/Print'
import ReactToPrint from "react-to-print";
import CompanyHeader from '../../CompanyHeader/CompanyHeader'
import AuditLog from './../../AuditLog/AuditLog'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

class JournalShow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Journal',
      journal: {},
      load: false,
      journalEntries: [],
      attachments: [],
      forPrint: false,
      openDeletePopup: false,
      openAttachmentPopup: false,
      redirect: false,
      value: 0,
      activeJournalPopup: false,
      cancelJournalPopup: false,
      reverseJournalPopUp: false,
      attachment: {}
    }
    this.handleDeleteItem = this.handleDeleteItem.bind(this)
    this.handleOpenDeletePopup = this.handleOpenDeletePopup.bind(this)
    this.handleDeleteClose = this.handleDeleteClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleAttactmentInputChange = this.handleAttactmentInputChange.bind(this)
    this.openAttachment = this.openAttachment.bind(this)
    this.handleAttacthmentSubmit = this.handleAttacthmentSubmit.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.activeJournal = this.activeJournal.bind(this)
    this.cancelJournal = this.cancelJournal.bind(this)
    this.cancelJournalSubmit = this.cancelJournalSubmit.bind(this)
    this.activeJournalSubmit = this.activeJournalSubmit.bind(this)
    this.reverseJournal = this.reverseJournal.bind(this)
    this.handleReverseJournal = this.handleReverseJournal.bind(this)
    this.closeReverseJournal = this.closeReverseJournal.bind(this)
    this.changePrintLayout = this.changePrintLayout.bind(this)
  }

  componentDidMount() {
    var skip = false
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.item) {
        skip = true
      }
    }
    if (this.props.match !== undefined) {
      this.loadJournal('/v1/journals/' + this.props.match.params.id, skip)
    }
  }

  loadJournal(url, skip) {
    if (skip) {
      this.setState({ journal: this.props.location.state.item, load: true })
    } else {
      axios({
        method: 'get',
        url: url,
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ journal: resp.data, load: true })
      })
    }

    axios({
      method: 'get',
      url: url + '/journal_entries',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ journalEntries: resp.data })
    }).catch(error => console.log(error.response))
    axios({
      method: 'get',
      url: url + '/attachments',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      this.setState({ attachments: resp.data })

    }).catch(error => console.log(error.response))

  }

  handleDeleteItem() {
    const DeleteItemId = this.state.journal.id
    axios({
      method: 'delete',
      url: '/v1/journals/' + DeleteItemId,
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {
      this.setState({ redirect: true })
      this.setState({
        isOpen: true,
        message: 'Deleted Successfully',
        type: 'error'
      })
      this.setState({ openDeletePopup: false })
    }).catch(error => console.log(error.response))
  }

  handleOpenDeletePopup() {
    this.setState({ openDeletePopup: true })
  }

  handleDeleteClose() {
    this.setState({ openDeletePopup: false })
  }

  handleClose() {
    this.setState({ openAttachmentPopup: false })
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue })
  }

  openAttachment() {
    this.setState({ attachment: { title: '', file: '', description: '' } })
    this.setState({ openAttachmentPopup: true })
  }

  handleAttactmentInputChange(e) {
    this.setState({
      attachment: {
        ...this.state.attachment,
        [e.target.name]: e.target.value
      }
    })
  }

  handleAttacthmentSubmit() {
    const id = this.props.match.params.id
    const item = this.state.attachment
    const file = item.file
    const title = item.file.name
    const description = item.description
    const formdata = new FormData()
    formdata.append('file', file)
    formdata.append('title', title)
    formdata.append('description', description)
    axios({
      method: 'post',
      url: '/v1/journals/' + id + '/attachments',
      data: (formdata),
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      const newData = resp.data
      this.setState({
        attachments: this.state.attachments.filter(attach => attach.id !== item.id)
      })
      this.setState({ attachments: [...this.state.attachments, newData] })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
      this.setState({ openAttachmentPopup: false })
    }).catch(error => console.log(error.response))
  }

  onFileChange(e) {
    e.stopPropagation()
    e.preventDefault()
    this.setState({
      attachment: {
        ...this.state.attachment,
        file: e.target.files[0],
        title: e.target.files[0].name
      }
    })
  }

  activeJournal() {
    this.setState({ activeJournalPopup: true })
  }

  cancelJournal() {
    this.setState({ cancelJournalPopup: true })
  }

  activeJournalSubmit() {
    const id = this.state.journal.id
    axios({
      method: 'put',
      url: '/v1/journals/' + id + '/post',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then((resp) => {
      this.setState({ activeJournalPopup: false })
      this.setState({ journal: resp.data })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })

    })
  }

  reverseJournal() {
    const id = this.state.journal.id
    axios({
      method: 'post',
      url: '/v1/journals/' + id + '/reverse_entries',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(() => {

      axios({
        method: 'get',
        url: '/v1/journals/' + this.props.match.params.id + '/journal_entries',
        headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
      }).then(resp => {
        this.setState({ journalEntries: resp.data, isOpen: true, message: 'Submitted Successfully', type: 'success', reverseJournalPopUp: false })
      }).catch(error => console.log(error.response))

    })
  }

  cancelJournalSubmit() {
    const id = this.state.journal.id
    axios({
      method: 'put',
      url: '/v1/journals/' + id + '/unpost',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then((resp) => {
      this.setState({ cancelJournalPopup: false })
      this.setState({ journal: resp.data })
      this.setState({
        isOpen: true,
        message: 'Submitted Successfully',
        type: 'success'
      })
    })
  }

  handleReverseJournal() {
    this.setState({ reverseJournalPopUp: true })
  }

  closeReverseJournal() {
    this.setState({ reverseJournalPopUp: false })
  }

  changePrintLayout() {
    return new Promise((resolve) => {
      this.setState({ forPrint: !this.state.forPrint }, () => resolve());
    });
  }

  render() {
    if (this.state.redirect) {
      return (<Redirect to={{
        pathname: '/journals'
      }} />)
    }
    const printPdf = <ReactToPrint
      trigger={() => {
        return <IconButton color="primary"><PrintIcon /></IconButton>
      }}
      onBeforeGetContent={this.changePrintLayout}
      onAfterPrint={this.changePrintLayout}
      content={() => this.componentRef}
    />
    return (

      this.state.load ? (
        <div ref={el => (this.componentRef = el)} className={this.state.forPrint ? styles.tableBodyHeight : null} >
          <CompanyHeader forPrint={this.state.forPrint} />
          <div className={styles.showTitleHeader}>
            <div><h2>Journal Information</h2></div>
            <div className={this.state.forPrint ? styles.noDisplay : null}>
              <div>
                <Tooltip title="Back">
                  <IconButton
                    color="primary"
                  >
                    <Link to={{
                      pathname: `/journals`,
                    }}>
                      <ArrowBackIosOutlinedIcon />
                    </Link>
                  </IconButton>
                </Tooltip>
                {this.state.journal.can_edit ? <Tooltip title="Edit">
                  <IconButton
                    name="edit_journal"
                    color="primary"
                  >
                    <Link to={{
                      pathname: `${this.state.journal.id}/edit`,
                    }}>
                      <EditIcon />
                    </Link>
                  </IconButton>
                </Tooltip> : null}
                {this.state.journal.can_delete ? <Tooltip title="Delete">
                  <IconButton
                    color="secondary"
                    name="delete_journal"
                    onClick={this.handleOpenDeletePopup}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip> : null}
              </div>
            </div>
          </div>
          <hr />
          <div className={styles.journalDetailsBody}>
            <Grid container >
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <div className={styles.detailList}>
                  <div><b> Reference No. </b></div>
                  <div>:&nbsp;{this.state.journal.branch_name ? 'JV' + this.state.journal.branch_name.charAt(0) : null}{this.state.journal.reference_no} </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <div className={styles.detailList}>
                  <div><b> Post Status </b></div>
                  <div>:&nbsp; {this.state.journal.is_posted ? ("Posted") : ("Not Posted")} </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <div className={styles.detailList}>
                  <div><b> Date of Journal </b></div>
                  <div>:&nbsp; {new Date(this.state.journal.date_of_journal).toLocaleDateString('en-Us', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  })} </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <div className={styles.detailList}>
                  <div><b> Branch </b></div>
                  <div>:&nbsp; {this.state.journal.branch_name} </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={8}>
                <div className={styles.detailList}>
                  <div><b> Description </b></div>
                  <div>:&nbsp; {this.state.journal.description} </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <hr />
          <div>
            <div>
              <Tabs value={this.state.value} onChange={this.handleChange} indicatorColor="secondary"
                className={this.state.forPrint ? styles.noDisplay : styles.tabBody} aria-label="simple tabs example">
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  <span className={styles.tabLabel}>JOURNAL ENTRIES</span></div>}{...a11yProps(0)} />
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  <span className={styles.tabLabel}>ATTACHMENT</span></div>}{...a11yProps(1)} />
                <Tab className={styles.tabList} label={<div className={styles.tabListTitle}>
                  <span className={styles.tabLabel}>AUDIT LOGS</span></div>}{...a11yProps(2)} />
              </Tabs>
              <TabPanel value={this.state.value} index={0} className={styles.tabListBody}>
                <JournalEntriesDetailTab
                  journal={this.state.journal}
                  journalEntries={this.state.journalEntries}
                  activeJournal={this.activeJournal}
                  cancelJournal={this.cancelJournal}
                  handleReverseJournal={this.handleReverseJournal}
                  forPrint={this.state.forPrint}
                  printPdf={printPdf}
                />
              </TabPanel>
              <TabPanel value={this.state.value} index={1} className={styles.tabListBody}>
                <AttachmentDetailTab
                  openAttachment={this.openAttachment}
                  attachments={this.state.attachments}
                />
              </TabPanel>
              <TabPanel value={this.state.value} index={2} className={styles.tabListBody}>
                <AuditLog gid={this.state.journal.gid} />
              </TabPanel>
            </div>
          </div>
          <SimplePopUp
            openPopup={this.state.openAttachmentPopup}
            title="Upload Attachments"
            items={this.state.attachments}
            handleClose={this.handleClose}
            maxWidth={this.state.maxWidth}
          >
            <AttachmentForm
              attachment={this.state.attachment}
              error={this.state.error_messages}
              onFileChange={this.onFileChange}
              submit={this.handleAttacthmentSubmit} onchange={this.handleAttactmentInputChange} />
          </SimplePopUp>
          <SimpleDeletePopUp
            openDeletePopup={this.state.openDeletePopup}
            item={this.state.journal}
            delete={this.handleDeleteItem}
            handleDeleteClose={this.handleDeleteClose}
            model="Journal"
          />
          <SimplePopUp
            openPopup={this.state.journal.is_posted === false ? this.state.activeJournalPopup : this.state.cancelJournalPopup}
            title="Confirmation"
            handleClose={this.handleClose}
            maxWidth="sm"
          >
            {this.state.journal.is_posted === false ? (
              <div>
                <span>
                  <b>Would you like to post this Journal?</b>
                </span>
                <div
                  align="right"
                  className={styles.actionButton}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={this.activeJournalSubmit}
                  >Save</Button></div>
              </div>
            ) :
              <div>
                <span>
                  <b>Would you like to unpost this Journal?</b>
                </span>
                <div align="right" className={styles.actionButton}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={this.cancelJournalSubmit}
                  >Save</Button>
                </div>
              </div>
            }
          </SimplePopUp>
          <SimplePopUp
            openPopup={this.state.reverseJournalPopUp}
            title="Confirmation"
            handleClose={this.closeReverseJournal}
            maxWidth="sm"
          >
            <div>
              <span>
                <b>Would you like to reverse this Journal&apos;s entries?</b>
              </span>
              <div align="right" className={styles.actionButton}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={this.reverseJournal}
                >Yes</Button>
              </div>
            </div>
          </SimplePopUp>
        </div>
      ) : (null)

    )
  }

}

export default JournalShow

JournalShow.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  }),
  location: PropTypes.object
}
