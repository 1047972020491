import React from 'react'
import PropTypes from 'prop-types'
import styles from './TrialBalances.module.css'
import {
	Table, TableHead, TableBody, TableCell, TableRow, IconButton
} from '@material-ui/core'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CompanyHeader from '../../CompanyHeader/CompanyHeader'

class TrialBalanceTable extends React.Component {
	getTotalDebit() {
		var total = 0
		if (this.props.items.length > 0) {
			this.props.items.map((content) => {
				total = parseFloat(total) + parseFloat(content["debit"])
			})
		}
		total = parseFloat(total).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
		return total
	}
	getTotalCredit() {
		var total = 0
		if (this.props.items.length > 0) {
			this.props.items.map((content) => {
				total = parseFloat(total) + parseFloat(content["credit"])
			})
		}
		total = parseFloat(total).toLocaleString(navigator.language, { minimumFractionDigits: 2 })
		return total
	}


	render() {
		return (
			<div className={this.props.forPrint? styles.tableStyles:null}>
				<CompanyHeader forPrint={this.props.forPrint}/>
				<div className={styles.checkDetailsHeader}>
					<div>
						<h2 className={styles.detailList}>
							<SignalCellularAltIcon size="large" className={this.props.forPrint? styles.noDisplay:null}/>Trial Balance
						</h2>
					</div>
					<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
						<div>
						{this.props.items.length > 0 ? (
								<Tooltip title="Print Trial Balance" arrow>
									<div className={this.props.forPrint? styles.noDisplay:null}>
										{this.props.printPdf}
									</div>
								</Tooltip>
							) : null}
						</div>
						{
							this.props.withXlsxDownload && this.props.items.length
							&& !this.props.forPrint ?
							<Tooltip 
								title="Download to .xlsx">
								<IconButton
									color="primary" 
									aria-label='download'
									style={{ pointerEvents: 'auto' }}
									onClick={this.props.handleXlsxDownloadClick}
								>
									<CloudDownloadIcon fontSize='small'/>
								</IconButton>
							</Tooltip> :
							null
						}
					</div>
				</div>
				<Table size="small" >
					<TableHead>
						<TableRow>
							<TableCell>
								<b> Account Code</b>
							</TableCell>
							<TableCell>
								<b>Account Title</b>
							</TableCell>
							<TableCell>
								<b>Debit</b>
							</TableCell>
							<TableCell>
								<b>Credit</b>
							</TableCell>


						</TableRow>
					</TableHead>

					<TableBody>
						{this.props.items.sort(function (a, b) {
							if (a.code.toLowerCase() < b.code.toLowerCase()) return -1;
							if (a.code.toLowerCase() > b.code.toLowerCase()) return 1;
							return 0;
						})
							.map((item) => (
								item.amount_balance !== 0 &&
								item.debit !== 0 &&
								<TableRow key={item.id}>
									<TableCell>
										{item.code}
									</TableCell>
									<TableCell>
										{item.name}
									</TableCell>
									<TableCell align="right">
										{parseFloat(item.debit).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
									</TableCell>
									<TableCell align="right">
										{parseFloat(item.credit).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
									</TableCell>
								</TableRow>
							))}
						{this.props.items.sort(function (a, b) {
							if (a.code.toLowerCase() < b.code.toLowerCase()) return -1;
							if (a.code.toLowerCase() > b.code.toLowerCase()) return 1;
							return 0;
						})
							.map((item) => (
								item.amount_balance !== 0 &&
								item.debit === 0 &&
								<TableRow key={item.id}>
									<TableCell>
										{item.code}
									</TableCell>
									<TableCell>
										{item.name}
									</TableCell>
									<TableCell align="right">
										{parseFloat(item.debit).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
									</TableCell>
									<TableCell align="right">
										{parseFloat(item.credit).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}
									</TableCell>
								</TableRow>
							))}

					</TableBody>
				</Table>
				{
					this.props.items.length > 0 ? (
						<Table>
							<TableRow className="fixWidth">
								<TableCell className={styles.cellNone}></TableCell>
								<TableCell className={styles.grandTotal}>Grand Total</TableCell>
								<TableCell className={styles.grandDebit}>{this.getTotalDebit().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
								<TableCell className={styles.grandCredit}>{this.getTotalCredit().toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</TableCell>
							</TableRow>
						</Table>
					) : null
				}

			</div>
		)
	}
}

export default TrialBalanceTable

TrialBalanceTable.propTypes = {
	classes: PropTypes.object,
	items: PropTypes.array,
	urlPrint: PropTypes.object,
	printPdf: PropTypes.object,
	forPrint: PropTypes.bool,
	withXlsxDownload: PropTypes.bool,
	handleXlsxDownloadClick: PropTypes.func
}
