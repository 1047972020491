import React from 'react'
import styles from './Journal.module.css'

import PropTypes from 'prop-types'

import {
    TextField,
    Button,
    MenuItem,
    Grid
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

class JournalSearchForm extends React.Component {
    render() {
        return (
            <div>
                <Grid container spacing={1}>
                    <Grid item xs={6} lg={3}>
                        <TextField
                            type="date"
                            autoComplete="off"
                            className={styles.textFields}
                            variant="outlined"
                            size="small"
                            name="starts_on_from"
                            fullWidth={true}
                            label="Start Date"
                            onChange={this.props.handleChange}
                            onKeyPress={this.props.handleKeyPress}
                            value={this.props.item.starts_on_from}
                            id="journal-starts_on_from"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} lg={3}>
                        <TextField
                            type="date"
                            autoComplete="off"
                            className={styles.textFields}
                            variant="outlined"
                            size="small"
                            name="starts_on_to"
                            fullWidth={true}
                            label="End Date"
                            onChange={this.props.handleChange}
                            onKeyPress={this.props.handleKeyPress}
                            value={this.props.item.starts_on_to}
                            id="journal-starts_on_to"
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <TextField
                            autoComplete="off"
                            className={styles.textFields}
                            variant="outlined"
                            size="small"
                            name="reference_no"
                            fullWidth={true}
                            label="Reference No."
                            onChange={this.props.handleChange}
                            value={this.props.item.reference_no}
                            onKeyPress={this.props.handleKeyPress}
                            id="journal-reference_no"
                        />
                    </Grid>
                    <Grid item xs={8} lg={2}>
                        <Autocomplete
                            value={this.props.item.branch || {}}
                            id="branch"
                            name="branch"
                            className={styles.autoCompleteField}
                            fullWidth
                            size="small"
                            options={(this.props.branches).sort(function (a, b) {
                                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                                return 0;
                            })}
                            getOptionLabel={(option) => option.name || ''}
                            getOptionSelected={(option, value) => option.name === value.name}
                            onChange={this.props.handleAutocompleteSearch}
                            renderInput={(params) => (
                                <TextField {...params} name="branch" variant="outlined" label="Branch Name" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4} lg={2}>
                        <TextField
                            autoComplete="off"
                            select
                            className={styles.textFields}
                            variant="outlined"
                            size="small"
                            name="is_posted"
                            fullWidth={true}
                            label="Status"
                            onChange={this.props.handleChange}
                            value={this.props.item.is_posted || ''}
                            id="journal-classification"
                        >
                            <MenuItem value ="">All</MenuItem>
                            <MenuItem value="true">Posted</MenuItem>
                            <MenuItem value="false">Not Posted</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <div className={styles.actionButton}>
                    <Button variant="outlined" id="journal-search" color="primary" onClick={() => this.props.search()} >Search</Button>
                    <Button variant="outlined" id="journal-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
                </div>

            </div>
        )
    }
}
export default JournalSearchForm

JournalSearchForm.propTypes = {
    handleChange: PropTypes.func,
    item: PropTypes.object,
    branches: PropTypes.array,
    search: PropTypes.func,
    clearSearch: PropTypes.func,
    handleKeyPress: PropTypes.func,
    handleAutocompleteSearch: PropTypes.func
}
