// eslint-disable-next-line
import React from 'react'

import PropTypes from 'prop-types'

import SimpleTable from '../../shared/SimpleTable/SimpleTable'
import DisbursementRegisterSearchForm from './DisbursementRegisterSearchForm'
import PaymentIcon from '@material-ui/icons/Payment'
import axios from 'axios'
import {withRouter} from 'react-router-dom'
import AlertMessage from "../../shared/Notify/AlertMessage";

export const Component = withRouter(() => {
})
import styles from './DisbursementRegister.module.css'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import ProgressBar from "../../shared/ProgressBar/ProgressBar";
import moment from 'moment'
import PrintIcon from '@material-ui/icons/Print'
import ReactToPrint from "react-to-print";
import { IconButton } from '@material-ui/core';
class DisbursementRegister extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      model: 'Disbursement Register',
      disbursementRegisters: [],
      branches: [],
      disbursementRegister: {},
      title: '',
      accountId: '',
      load: false,
      tableHead: ['date_of_journal', 'reference_no', 'description', 'journal_entries'],
      maxWidth: 'sm',
      error_messages: '',
      notify: {},
      isOpen: false,
      message: '',
      type: '',
      branchFirstLetter: '',
      searchDisbursementRegister: {},
      searchJournals: [],
      journalEntries: [],
      withPrint: true,
      urlPrint: '',
      totalPages: 1,
      currentPage: 1,
      searchUrl: '/v1/journals/search',
      forPrint: false

    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleDisbursementSearch = this.handleDisbursementSearch.bind(this)
    this.changePrintLayout = this.changePrintLayout.bind(this)
  }
  componentDidMount() {
    if(!this.props.location.search){
      this.setState({load: true})
    }
    var searchUrl = this.state.searchUrl + this.props.location.search
    var searchParams = {}
    
    const params = new URLSearchParams(this.props.location.search)
    params.forEach(function(value, key) {
      if (key !== 'page'&& key !== 'branch_id') {
        searchParams[key] = value.replace(/\*/g,'')
      }
      
    })
    if(params.get('branch_id')){
      axios({
        method: 'get',
        url: '/v1/branches/'+params.get('branch_id'),
        headers: { 'X-API-ACCESS-TOKEN':  localStorage.getItem('api_key')}
      }).then(resp => {
        searchParams.branch = resp.data
      })
    }
    this.setState({ searchDisbursementRegister: searchParams })
    this.loadDisbursementRegisters(searchUrl, true)
  
  }

  loadDisbursementRegisters(url) {
    axios({
      method: 'get',
      url: url.includes('?') ? url+'&sort_by[name]=asc': url+'?sort_by[name]=asc',
      headers: { 'X-API-ACCESS-TOKEN': localStorage.getItem('api_key') }
    }).then(resp => {
      if (this.props.location.search !== '') {
        var newArray = []
        resp.data.journals.map((account) => {
            var item = account
            axios({
              method: "get",
              url: "/v1/journals/" + account.id + '/journal_entries',
              headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
            }).then(resp => {
              item["journal_entries"] = resp.data
              resp.data.map((accs)=>{
                if(accs.account===null && accs.account_id){
                  axios({
                    method: "get",
                    url: "/v1/accounts/" + accs.account_id,
                    headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
                  }).then(resp=> { accs["account"]=resp.data})
                }
              })
              newArray.push(item)
              this.setState({journalEntries: resp.data})
              this.setState({searchJournals: newArray})
              this.setState({load: true})
            }).catch((error) => {
              
              if(error.response)
              {
                this.setState({isOpen: true,message: " Unexpected Error Occurred",type: 'error'})
            }
            })
          }
        )
        this.setState({ totalPages: resp.data.meta.total_pages, currentPage: resp.data.meta.current_page })
        this.setState({ totalResults: resp.data.meta.total_count, urlPrint: resp.data.meta})
        this.setState({ withResultsLabel: true})
        this.setState({ load: true })
      } else {
        this.setState({searchJournals: []})
      }
     
    }).catch((error) => {
      if(error.response)
      {this.setState({isOpen: true,message: " Unexpected Error Occurred",type: 'error'})}
    })
    axios({
      method: 'get',
      url: '/v1/branches',
      headers: {'X-API-ACCESS-TOKEN': localStorage.getItem('api_key')}
    }).then(resp => {
      this.setState({branches: resp.data})
      
    }).catch((error) => {
      if(error.response)
      {this.setState({isOpen: true,message: " Unexpected Error Occurred",type: 'error'})}
    })


  }

  getSearchParams() {
    var searchParams = []
    Object.entries(this.state.searchDisbursementRegister).map(([key, value]) => {
      if (key!=='per_page' &&value != '' && value != undefined && value != null) {
        searchParams.push([ key,  key!=='per_page' &&!key.includes('from') &&!key.includes('to') && key!== 'page' ?
          key === 'branch_id' ? 
            value.id : 
            value
          : value ].join("="))
      }
    })
    return searchParams
  }



  handleSearch() {
    const { history } = this.props
    var searchParams = this.getSearchParams().join("&")
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.loadDisbursementRegisters([this.state.searchUrl, searchParams].join("?"))
  }

  handleSearchInputChange(e) {
    var endOfMonth = ''
    if(e.target.name==="starts_on_from"){
      endOfMonth = moment(e.target.value).endOf('month').format('YYYY-MM-DD')
      this.setState({
        searchDisbursementRegister: {
          ...this.state.searchDisbursementRegister,
          [e.target.name]: e.target.value,
          starts_on_to: endOfMonth
        }
      })
    }else{
      this.setState({
        searchDisbursementRegister: {
          ...this.state.searchDisbursementRegister,
          [e.target.name]: e.target.value
        }
      })
    }
  }

  handleInputChange(e) {
    this.setState({
      disbursementRegister: {
        ...this.state.disbursementRegister,
        [e.target.name]: e.target.value
      }
    })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({isOpen: false})
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSearch()
    }
  }

  clearSearch() {
    const {history} = this.props
    history.push({search: ''})
    this.loadDisbursementRegisters('/v1/journals/search')
    this.setState({searchDisbursementRegister: {starts_on_from: '', branch_id: {}, is_posted: '', starts_on_to: ''}})
    this.setState({branches: []})
    this.setState({branchName: {}})
    this.setState({urlPrint: ''})
  }

  handlePageChange(event, value) {
    var searchParams = this.getSearchParams()
    searchParams.push(["page", value].join("="))
    searchParams = searchParams.join("&")
    const { history } = this.props
    const params = new URLSearchParams()
    params.append("/search", searchParams)
    history.push({ search: searchParams })
    this.setState({ currentPage: value })
    this.loadDisbursementRegisters([this.state.searchUrl, searchParams].join("?"))
  }

  handleDisbursementSearch(event, values) {
    if (values !== null) {
      this.setState({
        searchDisbursementRegister:
          {
            ...this.state.searchDisbursementRegister,
            branch_id: values
          }
      })
    }
  }

  grandDebit() {
    var totalDebit = 0
    if (this.state.searchJournals.length > 0) {
      this.state.searchJournals.map((content) => {
        content.journal_entries.map((debit) => {
          totalDebit = parseFloat(totalDebit) + parseFloat(debit.amount_debit)
        })
      })
    }
    return totalDebit
  }

  grandCredit() {
    var totalCredit = 0
    if (this.state.searchJournals.length > 0) {
      this.state.searchJournals.map((content) => {
        content.journal_entries.map((debit) => {
          totalCredit = parseFloat(totalCredit) + parseFloat(debit.amount_credit)
        })
      })
    }
    return totalCredit
  }
  changePrintLayout() {
    return new Promise((resolve) => {
        this.setState({ forPrint: !this.state.forPrint }, () => resolve());
      });     
  }

  render() {
    const printPdf = <ReactToPrint
    trigger={() => {
        return <IconButton color="primary"><PrintIcon /></IconButton>
    }}
    onBeforeGetContent={this.changePrintLayout}
    onAfterPrint={this.changePrintLayout}
    content={() => this.componentRef}
    />
    return (
      <>
        {
          this.state.load ? (
            <div>
              <DisbursementRegisterSearchForm
                clearSearch={this.clearSearch}
                item={this.state.searchDisbursementRegister}
                branches={this.state.branches}
                search={this.handleSearch}
                handleChange={this.handleSearchInputChange}
                handleDisbursementSearch={this.handleDisbursementSearch}
                handleKeyPress={this.handleKeyPress}
              />
              <SimpleTable
                items={this.state.searchJournals}
                journalEntries={this.state.journalEntries}
                withPrint={this.state.withPrint}
                model={this.state.model}
                headers={this.state.tableHead}
                icon={<PaymentIcon fontSize="large"/>}
                currentPage={this.state.currentPage}
                totalPages={this.state.totalPages}
                urlPrint={this.state.urlPrint}
                handlePageChange={this.handlePageChange}
                withPagination={this.state.withPagination}
                printPdf={printPdf}
                ref={el => (this.componentRef = el)}
              />
              {this.state.searchJournals.length > 0 ? (
                <Table>
                  <TableRow className="fixWidth">
                    <TableCell className={styles.cellNone}></TableCell>
                    <TableCell className={styles.grandTotal}>Grand Total</TableCell>
                    <TableCell
                      className={styles.grandDebit}>{this.grandDebit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</TableCell>
                    <TableCell
                      className={styles.grandCredit}>{this.grandCredit().toLocaleString(navigator.language, {minimumFractionDigits: 2})}</TableCell>
                  </TableRow>
                </Table>
              ) : null}
              <AlertMessage
                notify={this.state.notify}
                handleCloseAlert={this.handleCloseAlert}
                isOpen={this.state.isOpen}
                type={this.state.type}
                message={this.state.message}
              />
            </div>
          ) : (
            <ProgressBar model={this.state.model}/>
          )}
      </>
    )
  }
}

export default withRouter(DisbursementRegister)

DisbursementRegister.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}
