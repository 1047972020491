import React from 'react'
import styles from './DisbursementRegister.module.css'

import PropTypes from 'prop-types'

import {
	TextField,
	Button,
	MenuItem,
	Grid
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

class DisbursementRegisterSearchForm extends React.Component {
	render() {
		return (
			<div>
				<Grid container spacing={1}>
					<Grid item xs={6} lg={3}>
						<TextField
							autoComplete="off"
							className={styles.textFields}
							type="date"
							variant="outlined"
							size="small"
							name="starts_on_from"
							fullWidth={true}
							label="Start Date"
							onChange={this.props.handleChange}
							onKeyPress={this.props.handleKeyPress}
							value={this.props.item.starts_on_from}
							InputLabelProps={{
								shrink: true
							}}
						/>
					</Grid>
					<Grid item xs={6} lg={3}>
						<TextField
							autoComplete="off"
							className={styles.textFields}
							variant="outlined"
							type="date"
							size="small"
							name="starts_on_to"
							fullWidth={true}
							label="End Date"
							onChange={this.props.handleChange}
							onKeyPress={this.props.handleKeyPress}
							value={this.props.item.starts_on_to}
							InputLabelProps={{
								shrink: true
							}}
						/>
					</Grid>
					<Grid item xs={6} lg={3}>
						<Autocomplete
							name="branch_id"
							size="small"
							options={this.props.branches}
							getOptionLabel={(option) => option.name}
							getOptionSelected={(option, value) => option.name === value.name}
							onChange={this.props.handleDisbursementSearch}
							fullWidth="true"
							value={this.props.item.branch_id}
							renderInput={(params) => <TextField className={styles.textFields} {...params} name="branch_id" label="Branch Name" variant="outlined" />}
						/>
					</Grid>
					<Grid item xs={6} lg={3}>
						<TextField
							autoComplete="off"
							select
							className={styles.textFields}
							variant="outlined"
							size="small"
							name="is_posted"
							fullWidth={true}
							label="Status"
							onChange={this.props.handleChange}
							value={this.props.item.is_posted || ''}
						>
							<MenuItem value ="">All</MenuItem>
							<MenuItem value="true">Posted</MenuItem>
							<MenuItem value="false">Not Posted</MenuItem>
						</TextField>
					</Grid>
				</Grid>
				<div className={styles.actionButton}>
					<Button variant="outlined" name="search" disabled={!this.props.item.starts_on_from || !this.props.item.starts_on_to} id="disbursement-search" color="primary" onClick={() => this.props.search()} >Search</Button>
					<Button variant="outlined" name="clear" id="disbursement-clearSearch" color="secondary" onClick={() => this.props.clearSearch()} >Clear</Button>
				</div>

			</div>
		)
	}
}
export default DisbursementRegisterSearchForm

DisbursementRegisterSearchForm.propTypes = {
	handleChange: PropTypes.func,
	item: PropTypes.object,
	search: PropTypes.func,
	handleDisbursementSearch: PropTypes.func,
	clearSearch: PropTypes.func,
	branches: PropTypes.array,
	handleKeyPress: PropTypes.func
}
