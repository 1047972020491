import React from 'react'
import styles from './ExpenseTypes.module.css'

import PropTypes from 'prop-types'

import {
  TextField,
  Button,Grid
} from '@material-ui/core'

class ExpenseTypeForm extends React.Component {
  render () {
    return (
      <div>
        <form>
           <Grid container spacing={1}>
            <Grid item xs={12}>
              <div>
                <TextField
                  autoComplete="off"
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="name"
                  fullWidth="true"
                  label="Name"
                  onChange={this.props.onchange}
                  value={this.props.item.name}
                  id="expenseType-name"
                />
              </div>
              <div className={styles.errorMessage}>{this.props.error.name?'Name ' +this.props.error.name:null}</div>
            </Grid>
            <Grid item xs={12}>
              <div>
                <TextField
                  autoComplete="off"
                  className={styles.textFields}
                  variant="outlined"
                  size="small"
                  name="description"
                  fullWidth="true"
                  label="Description"
                  onChange={this.props.onchange}
                  value={this.props.item.description}
                  id="expenseType-description"
                />
              </div>
              <div className={styles.errorMessage}>{this.props.error.description?'Description ' +this.props.error.description:null}</div>
            </Grid>
              <TextField
                autoComplete="off"
                className={styles.textFields}
                variant="outlined"
                size="small"
                name="multiplier"
                fullWidth="true"
                label="Multiplier"
                onChange={this.props.onchange}
                value={this.props.item.multiplier}
                id="expenseType-multiplier"
              />
          <div className={styles.errorMessage}>{this.props.error.multiplier?'Multiplier ' +this.props.error.multiplier:null}</div>
          </Grid>
        </form>
        <div className={styles.actionButton}>
          <Button variant="outlined" disabled={!this.props.item.name} id="expenseType-submit" color="primary" onClick={() => this.props.submit()} >Save</Button>
        </div>
      </div>
    )
  }
}
export default ExpenseTypeForm

ExpenseTypeForm.propTypes = {
  onchange: PropTypes.func,
  item: PropTypes.object,
  submit: PropTypes.func,
  error: PropTypes.object
}
