import Cookies from 'js-cookie';

export function setCookies(name, value) {
    Cookies.set(name, value, { domain: process.env.REACT_APP_DOMAIN, expires: 0.5, secure: true });
}

export function removeCookies(name) {
    Cookies.remove(name);
    Cookies.remove(name, { domain: process.env.REACT_APP_DOMAIN });
}

export function removeAllCookies() {
    removeCookies('token')
    removeCookies('cashvoucher_prefix')
    removeCookies('checkvoucher_prefix')
    removeCookies('current_user')
}
