import React from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import styles from './Sessions.module.css'
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import axios from 'axios'
import AlertMessage from '../Notify/AlertMessage'
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from '../../images/lexLogo.png'
import CompanyBG from '../../images/background-new-flip.png'

class UnlockInstruction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unlock: {username: '', email: ''},
    }
    this._handleKeyPress = this._handleKeyPress.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCloseAlert = this.handleCloseAlert.bind(this)
  }

  componentDidMount () {
    const query = new URLSearchParams(location.search);
    if(query.get('unlock_token')) {
      axios({
        method: 'get',
        url: '/v1/users/unlocks?unlock_token=' + query.get('unlock_token'),
        
      }).then(() => {
        setTimeout(() => {
          location.href="/login"
        }, 1000, this.setState({
                    isOpen: true,
                    message: 'Successfully account unlock',
                    type: 'success'
                  }))
      }).catch(() => {
        setTimeout(() => {
          location.href="/login"
        }, 1000, this.setState({
                    isOpen: true,
                    message: 'Account is already unlock',
                    type: 'error'
                  }))
      })
    }
  }

  handleChange(e) {
    e.preventDefault()
    this.setState({
      unlock: {...this.state.unlock,[e.target.name]: e.target.value}
    })
  }

  validateForm() {
    return (
      this.state.unlock.username.length > 0 &&
      this.state.unlock.email.length > 0 
    );
  }

  _handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit () { 
    const item = this.state.unlock
    axios({
      method: 'post',
      url: '/v1/users/unlocks',
      data: (item),
      
    }).then(() => {
      setTimeout(() => {
        location.href="/login"
      }, 1500, this.setState({
                  isOpen: true,
                  message: 'Verification email sent to your email',
                  type: 'success'
                }))
    }).catch(error => {
      if (error.response.status === 404) { 
        this.setState({isOpen: true,message: "Invalid Username and/or Password",type: 'error'})
      }
    })
  }

  handleCloseAlert(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      isOpen: false
    })
  }

  render() {
    return (
      <>
      <Grid container component="main" className={styles.root}>
        <CssBaseline/>
        <Grid item xs={false} sm={4} md={7}>
          <img src={process.env.REACT_APP_BG_URL?process.env.REACT_APP_BG_URL:CompanyBG } alt="" className={styles.image}/>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={styles.paper}>
              <div className={styles.headerName}> {process.env.REACT_APP_TITLE_COMPANY?process.env.REACT_APP_TITLE_COMPANY: "LEXITECH" } Enterprise System</div>
            <div>
              <img src={process.env.REACT_APP_LOGO_URL? process.env.REACT_APP_LOGO_URL:logo }  alt="Company Background" className={styles.avatar}/>
            </div>
            <Typography component="h1" variant="h5">
              Unlock your Account
            </Typography>
            <form className={styles.form} noValidate>
              <Grid container>
                <Grid item xs>
                </Grid>
                <Grid item className={styles.forgotIcons}>
                  <Tooltip title="Forgot password" arrow>
                  <a href="/forgot_password">
                    <IconButton variant="body2">
                      <HelpOutlineOutlinedIcon />
                    </IconButton>
                    </a>
                  </Tooltip>
                  <Tooltip title="Login" arrow>
                  <a href="/login">
                    <IconButton variant="body2">
                      <PersonOutlineOutlinedIcon />
                    </IconButton>
                    </a>
                  </Tooltip>
                </Grid>
              </Grid>
              <TextField
                  variant="outlined"
                  margin="normal"
                  autoComplete="off"
                  className={styles.textField}
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  onChange={this.handleChange}
                  value={this.state.unlock.username}
                  onKeyPress={this._handleKeyPress}
                  name="username"
                  autoFocus
                />
                  <TextField
                  variant="outlined"
                  margin="normal"
                  autoComplete="off"
                  className={styles.textField}
                  required
                  onChange={this.handleChange}
                  value={this.state.unlock.email}
                  onKeyPress={this._handleKeyPress}
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoFocus
                />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
                className={styles.submit}
                disabled={!this.validateForm() || this.state.isOpen} 
              >
                {this.state.isOpen? <CircularProgress size={20}/>:  'Send unlock instructions'}
              </Button>
            </form>
          </div>
        </Grid>
        <AlertMessage
            notify={this.state.notify}
            handleCloseAlert={this.handleCloseAlert}
            isOpen={this.state.isOpen}
            type={this.state.type}
            message={this.state.message}
          />
      </Grid>
      </>
    )
  }
}

export default UnlockInstruction
